import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ICategoryList } from 'src/app/model/categoryList';
import { environment } from 'src/environments/environment';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss']
})
export class TechnologyComponent implements OnInit {
  @Output() selectLang  = new EventEmitter<number>();
  _selected!: ICategoryList;
  iconLink = environment.iconUrl;
  // list:{ title: string, value: string }[] = [
  //   { title: "Programming", value: 'programming' },
  //   { title: 'DS & Algorithms', value: 'DSAndalgorithms' },
  //   { title: 'AI & Machine Learning', value: 'AIAndMachineLearning' },
  //   { title: 'Software Testing', value: 'softwareTesting' },
  //   { title: 'Digital Marketing', value: 'digitalMarketing' },
  //   { title: 'Cloud Computing', value: 'cloudComputing' },
  //   { title: 'UI Technologies', value: 'UITechnologies' },
  //   { title: 'Databases', value: 'databases' },
  //   { title: 'projectManagement', value: 'Project Management' }
  // ]
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
  }

  get langList(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }

  get selectedCategory(): ICategoryList {
    return this._selected || (this.homeService.listWithLanguage && this.homeService.listWithLanguage[0]);
  }

  mouseEnter(category: ICategoryList) {
    this._selected = category;
  }

  selectTechno(lang: number) {
    this.selectLang.emit(lang)
  }
  cancel() {
    // this.activeTechology.emit("");
  }
}
