<div class="technology" *ngIf="technologyFlag">
    <app-technology (selectLang)="selectLang($event)"></app-technology>
</div>
<div class="keywords" *ngIf="keywordFlag">
    <app-keywords (activeDetails)="activeDetails($event)" (activeTechology)="activeTechology($event)"></app-keywords>
</div>
<div class="keywords" *ngIf="detailsFlag">
    <app-details (activeCompleted)="activeCompleted($event)" (activeKeywords)="activeKeyWord()"></app-details>
</div>
<div class="keywords" *ngIf="completedFlag">
    <app-completed [completedData]="completedData" (closePopup)="closePopup()"></app-completed>
</div>