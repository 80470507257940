import { Details, IDetails } from "./common";

export interface IBatch {
    batchId: number;
    courseId: number;
    batchType: string;
    status: string;
    startDate: string;
    remDays: number;
}
export class Batch implements IBatch {
    batchId: number;
    courseId: number;
    batchType: string;
    status: string;
    startDate: string;
    remDays: number;

    constructor(props: IBatch) {
        this.batchId = props.batchId;
        this.courseId = props.courseId;
        this.batchType =props.batchType;
        this.status = props.status;
        this.startDate = props.startDate;
        this.remDays = props.remDays;
    }
}
export interface ICertificationCourse {
    courseId: number;
    categoryId: number;
    langId: number;
    title: string;
    courseName: string;
    duration: number;
    rating: number;
    ratingCount: number;
    learners: number;
    bgColor: string
    icon: string;
    batch: IBatch;
    get route(): string;
}

export class CertificationCourse implements ICertificationCourse {
    courseId: number;
    categoryId: number;
    langId: number;
    title: string;
    courseName: string;
    rating: number;
    ratingCount: number;
    learners: number;
    bgColor: string
    icon: string;
    batch: IBatch;
    duration: number;

    constructor(props: ICertificationCourse) {
        this.courseId = props.courseId;
        this.categoryId = props.categoryId;
        this.langId = props.langId;
        this.title = props.title;
        this.courseName = props.courseName;
        this.rating = props.rating;
        this.ratingCount = props.ratingCount;
        this.learners = props.learners;
        this.bgColor = props.bgColor;
        this.icon = props.icon;
        this.duration = props.duration;
        this.batch = props.batch && new Batch(props.batch);
    }
    get route(): string {
      if (!this.title) return '';
      return this.title.replace(/-/g, ' ');
    }
}

export interface ICertificationCourseDetails extends IDetails {
    courseId: number;
    categoryId: number;
    langId: number;
    title: string;
    tag: string;
    summary: string;
    bgColor: string;
    videos: string;
    headerBgColor: string;
    get videoList(): any[];
}
export class CertificationCourseDetails extends Details implements ICertificationCourseDetails {
    courseId: number;
    categoryId: number;
    langId: number;
    title: string;
    tag: string;
    summary: string;
    bgColor: string;
    videos: string;
    headerBgColor: string;

    constructor(props: ICertificationCourseDetails) {
        super(props);
        this.categoryId = props.categoryId;
        this.courseId = props.courseId;
        this.langId = props.langId;
        this.title = props.title;
        this.tag = props.tag;
        this.summary = props.summary;
        this.bgColor = props.bgColor;
        this.videos = props.videos;
        this.headerBgColor = props.headerBgColor;
    }
    get videoList(): any[] {
      return this.videos ? JSON.parse(this.videos) : [];
    }
}

export interface IBatchTable {
    batchId: number;
    courseId: number;
    batchType: string;
    duration: number;
    flag: string;
    startTime: string;
    endTime: string;
    status: string;
    startDate: string;
    remDays: number;
}
export class BatchTable {
    batchId: number;
    courseId: number;
    batchType: string;
    duration: number;
    flag: string;
    startTime: string;
    endTime: string;
    status: string;
    startDate: string;
    remDays: number;

    constructor(props: IBatchTable) {
        this.batchId = props.batchId;
        this.courseId = props.courseId;
        this.batchType = props.batchType;
        this.duration = props.duration;
        this.flag = props.flag;
        this.startDate = props.startDate;
        this.startTime = props.startTime;
        this.endTime = props.endTime;
        this.status = props.status;
        this.remDays = props.remDays;
    }
}
