import { TrainingService } from "src/app/training/services/training.service";

export interface ILink {
  pageId: number;
  urlTitle: string;
  title: string;
  get route(): string;
  currentIndex: number;
}

export class Link implements ILink {
  pageId: number;
  urlTitle: string;
  title: string;
  currentIndex: number;
  constructor({ pageId, urlTitle, title }: ILink, i: number) {
    this.pageId = pageId;
    this.urlTitle = urlTitle;
    this.title = title;
    this.currentIndex = i;
  }

  get route(): string {
    return this.urlTitle && this.urlTitle.replace(/ /g, '-');
  }
}

export interface ITopic {
  topicId: number;
  topicName: string;
  links: ILink[];
  flagTopic: boolean;
  currentIndex: number;
  get hasLinks(): boolean;
  get isCollapsed(): boolean;
  hide: () => void;
  show: (flag?: boolean, courseName?: string, moduleName?:string) => void;
  showWithoutFetch: () => void;
  hideShow: () => void;
  getLinks: () => void;
}
export class Topic implements ITopic {
  topicId: number;
  topicName: string;
  links: ILink[];
  flagTopic: boolean;
  service!: TrainingService;
  currentIndex: number;

  constructor(t: ITopic, service: TrainingService, i: number) {
    this.topicId = t.topicId;
    this.topicName = t.topicName;
    this.links = (t.links && t.links.map((l: ILink, i: number) => new Link(l, i))) || [];
    this.flagTopic = false;
    this.service = service;
    this.currentIndex = i;
  }
  hideShow(): void {
    this.flagTopic = !this.flagTopic;
    !this.hasLinks && this.service.fetchTreeLink(this);
  }
  hide(): void {
    this.flagTopic = false;
  }
  showWithoutFetch() {
    this.flagTopic = true;
  }
  show(flag?: boolean, courseName?: string, moduleName?: string): void {
    this.flagTopic = true;
    !this.hasLinks && this.service.fetchTreeLink(this, flag, courseName, moduleName);
  }
  get hasLinks(): boolean {
    return this.links.length !== 0;
  }
  get isCollapsed(): boolean {
    return this.flagTopic;
  }

  getLinks() {
    !this.hasLinks && this.service.fetchTreeLink(this)
  }

  addLinks(links: ILink[]) {
    this.links = links;
  }
}

export interface ITree {
  techId: number;
  techName: string;
  topics: ITopic[];
  flagTopic: boolean;
  isExpandAll: boolean;
  currentIndex: number;
  get isCollapsed(): boolean;
  get hasTopics(): boolean;
  hideShow: () => void;
  setExpandAll: () => void;
  hide: () => void;
  show: () => void;
}
export class Tree implements ITree {
  techId: number;
  techName: string;
  topics: ITopic[];
  flagTopic: boolean;
  isExpandAll: boolean;
  service!: TrainingService;
  currentIndex: number;
  constructor(props: any, service: TrainingService, i: number) {
    this.techId = props.Id;
    this.techName = props.techName;
    this.topics = props.topics.map((t: any, i: number) => new Topic(t, service, i));
    this.flagTopic = false;
    this.isExpandAll = false;
    this.service = service;
    this.currentIndex = i;
  }

  get hasTopics(): boolean {
    return this.topics.length !== 0;
  }

  get isCollapsed(): boolean {
    return this.flagTopic;
  }

  hideShow(): void {
    this.flagTopic = !this.flagTopic;
  }

  hide(): void {
    this.flagTopic = false;
  }
  show(): void {
    this.flagTopic = true;
  }

  setExpandAll() {
    this.isExpandAll = !this.isExpandAll;
    this.flagTopic = this.isExpandAll;
    this.topics.forEach((v: ITopic) => {
      v.show()
    })
  }
}


export interface ITreeQuestion {
  langId: number;
  techName: string;
  tuCount: number;
  mcqCount: number;
  iqCount: number;
  csCount: number;
  weight: number;
  icon: string,
  topics: ITopicQuestion[];
  Id: number;
  flagTopic: boolean;
  isExpandAll: boolean;
  get isCollapsed(): boolean;
  get hasTopics(): boolean;
  hideShow: () => void;
  setExpandAll: () => void;
  hide: () => void;
  show: () => void;
}

export interface ITopicQuestion {
  topicId: number;
  techId: number;
  topicName: string;
  pqCount: number;
  iqCount: number;
  csCount: number;
  crf: number;
  fsf: number;
  spf: number;
  weight: number;
  flagTopic: boolean;
  get isCollapsed(): boolean;
  hide: () => void;
  show: () => void;
  hideShow: () => void;
  get route(): string;

}
export class TopicQuestion implements ITopicQuestion {
  topicId: number;
  techId: number;
  topicName: string;
  pqCount: number;
  iqCount: number;
  csCount: number;
  crf: number;
  fsf: number;
  spf: number;
  weight: number;
  flagTopic: boolean;

  constructor(props: ITopicQuestion) {
    this.topicId = props.topicId;
    this.techId = props.techId;
    this.topicName = props.topicName;
    this.pqCount = props.pqCount;
    this.iqCount = props.iqCount;
    this.csCount = props.csCount;
    this.crf = props.crf;
    this.fsf = props.fsf;
    this.spf = props.spf;
    this.weight = props.weight;
    this.flagTopic = false;
  }
  hideShow(): void {
    this.flagTopic = !this.flagTopic;
  }
  hide(): void {
    this.flagTopic = false;
  }
  show(): void {
    this.flagTopic = true;
  }
  get isCollapsed(): boolean {
    return this.flagTopic;
  }
  get route(): string {
    if (!this.topicName) return "";
    return this.topicName.toLowerCase().replace(/ /g, '-');
  }
}
export class TreeQuestion implements ITreeQuestion {
  langId: number;
  techName: string;
  tuCount: number;
  mcqCount: number;
  iqCount: number;
  csCount: number;
  weight: number;
  icon: string;
  topics: ITopicQuestion[];
  Id: number;
  flagTopic: boolean;
  isExpandAll: boolean;

  constructor(props: ITreeQuestion) {
    this.Id = props.Id;
    this.techName = props.techName;
    this.langId = props.langId;
    this.tuCount = props.tuCount;
    this.mcqCount = props.mcqCount;
    this.iqCount = props.iqCount;
    this.csCount = props.csCount;
    this.weight = props.weight;
    this.icon = props.icon;
    this.topics = props.topics.map(t => new TopicQuestion(t));
    this.flagTopic = false;
    this.isExpandAll = false;
  }
  get isCollapsed(): boolean {
    return this.flagTopic;
  }
  get hasTopics(): boolean {
    return this.topics.length !== 0;
  }
  hideShow(): void {
    this.flagTopic = !this.flagTopic;
  }
  setExpandAll(): void {
    this.isExpandAll = !this.isExpandAll;
  }
  hide(): void {
    this.flagTopic = false;
  }
  show(): void {
    this.flagTopic = true;
  }
}
