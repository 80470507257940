<ng-container *ngIf="question">
    <div class="pb-5 overflow-hidden" [id]="'mcq_'+question?.mcqId">
        <div class="d-flex align-items-start justify-content-between mx-md-2 mx-lg-2 mb-1">
            <div class="question-no">
                <p class="text-center mb-0">{{index}}</p>
            </div>
            <div class="question-title">
                <p class="mb-0" [innerHTML]="question?.title"></p>
            </div>
            <div class="question-share">
                <div class="d-flex align-items-center justify-content-end">
                    <span class="material-icons-outlined px-1" role="button" (click)="bookmark(question)">{{question?.bookmark == 1 ? 'favorite' : 'favorite_border'}}</span>
                    <!-- <span class="material-icons-outlined px-2 c-pointer" [cdkCopyToClipboard]="question.title">content_copy</span> -->
                    <span role="button" shareButtonsPopup [include]="['facebook','twitter','linkedin','messenger','whatsapp','sms','email','print','copy']"
                    [show]="5" [url]="currentLink + '#mcq_' + question?.mcqId"
                    [autoSetMeta]="false" class="share-button material-icons-outlined pl-1">share</span>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between mx-0 mx-md-2 mx-lg-2">
            <div class="question-like">
                <div class="d-flex flex-column align-items-center">
                    <p class="mb-4 text-white py-1 px-2 py-md-1 px-md-2 boxE" data-bs-toggle="tooltip" data-bs-placement="right" title="Easy" *ngIf="question?.complexity === 'L1'">E</p>
                    <p class="mb-4 text-white py-1 px-2 py-md-1 px-md-2 boxM" data-bs-toggle="tooltip" data-bs-placement="right" title="Medium" *ngIf="question?.complexity === 'L2'">M</p>
                    <p class="mb-4 text-white py-1 px-2 py-md-1 px-md-2 boxC" data-bs-toggle="tooltip" data-bs-placement="right" title="Complex" *ngIf="question?.complexity === 'L3'">C</p>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <span role="button" data-bs-toggle="tooltip" data-bs-placement="top" [title]="voteMessage" [ngClass]="{'text-primary material-icons': voted}" class="material-icons-outlined px-2" (click)="vote(1)">thumb_up</span>
                    <p class="text-like m-0 pb-2">{{question.likes ==0? 0:question.likes | shortNumber}}</p>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <span class="material-icons-outlined px-2">visibility</span>
                    <p class="text-like m-0 pb-2">{{question.views == 0? 0:question.views | shortNumber}}</p>
                </div>
                <div class="text-center" *ngIf="question?.video">
                    <span role="button"><fa-icon [icon]="youtube" *ngIf="question?.video" data-bs-toggle="modal" style="font-size: 26px;" class="ycolor" (click)="handleVideo(question)" [attr.data-bs-target]="loginFlag ? '#youtubeVideo' : '#loginModal'"></fa-icon></span>
                </div>
            </div>
            <div class="question-body">
                <div  style="height: fit-content;" *ngIf="question?.question">
                    <!-- <span class="material-icons-outlined px-2 c-pointer" [cdkCopyToClipboard]="question.title">content_copy</span> -->
                    <app-code-viewer [options]="editorOptions" [code]="question.question"></app-code-viewer>
                </div>
                <div class="paragraph" *ngFor="let option of question.options; let i=index">
                    <div class="d-flex py-2">
                        <div class="m-0 pr-2 txt-opt">{{option.optionSeq}}</div>
                        <div [innerHtml]="option?.optionValue"></div>
                    </div>
                </div>
                <div class="d-flex align-items-center pt-4 fs-14">
                    <a class="pr-4 link ans-report" data-bs-toggle="modal" [attr.data-bs-target]="loginFlag ? getAccess(question) ? '' : '#errorpage' : '#loginModal'" (click)="handleAnswer(question)">Answer</a>
                    <a class="px-4 link ans-report" data-bs-toggle="modal" [attr.data-bs-target]="loginFlag ? '#supportModal' : '#loginModal'">Support</a>
                    <a class="px-4 link ans-report" data-bs-toggle="modal" [attr.data-bs-target]="loginFlag ? '' : '#loginModal'" (click)="closeError(question)">Report</a>
                    <!-- <a class="px-4 link c-pointer" *ngIf="question?.video" (click)="handleVideo(question)" data-bs-toggle="modal" [attr.data-bs-target]="loginFlag ? '#youtubeVideo' : '#loginModal'">Video</a> -->
                </div>
                <div class="anwer-value" *ngIf="question.answerFlag">
                    <div class="arrow-answer"></div>
                    <ng-container *ngIf="question.answer;else load">Answer:&nbsp;&nbsp;
                        <ng-container *ngFor="let ans of question?.answer?.answer">{{ans.optionSeq}}&nbsp;&nbsp;
                        </ng-container>
                        <p class="text-ans float-end">QuesId :{{question.mcqId}}</p>
                        <p class="mb-1 mt-3" *ngIf="question?.answer?.description">Explanation</p>
                        <p class="mb-0 text-ans" [innerHTML]="question?.answer?.description"></p>
                    </ng-container>
                    <ng-template #load>
                        <fa-icon [icon]="spinner" *ngIf="!errFlag"></fa-icon>
                        <ng-container *ngIf="errFlag">
                            <p>Access Denied!</p>
                        </ng-container>
                    </ng-template>
                </div>
                <div class="anwer-value" *ngIf="question.suggestFlag">
                    <div class="arrow-suggest"></div>
                    <ng-template #load>
                        <fa-icon [icon]="spinner"></fa-icon>
                    </ng-template>
                </div>
                <div class="anwer-value" *ngIf="question.reportFlag">
                    <div class="arrow-report"></div>
                    <div>
                        <textarea class="form-control" rows="3" [(ngModel)]="errorComment"></textarea>
                        <div class="gap-2 d-flex flex-column flex-sm-row justify-content-between align-items-center mt-2">
                            <p class="text-danger m-0 w-100" *ngIf="reportMessage !== ''">{{reportMessage}}</p>
                            <div class="gap-2 d-flex justify-content-end align-items-center mt-2 w-100">
                                <button class="btn btn-light me-md-2" type="button" (click)="closeReport()">Close</button>
                                <button class="btn btn-primary" type="button" (click)="submitErrorComment($event)">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="question === null">
    <p>No Question Found!</p>
</ng-container>

<div class="modal fade" id="supportModal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header header-support">
                <div class="d-flex align-items-center">
                    <img style="width: 50px" src="https://cdn-icons-png.flaticon.com/512/2043/2043175.png" alt="">
                    <p class="modal-title m-0 ms-2" id="exampleModalLabel">Support Desk</p>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="notValid;else notSupport">
                    <div *ngFor="let support of callSupprot" class="d-flex mb-4">
                        <fa-icon [icon]="support.icon" class="me-3"></fa-icon>
                        <div>
                            <p class="m-0 mb-2">{{support.title}}</p>
                            <p class="m-0 paragraph pe-4 fw-light" style="color: #d2d2d2;">{{support.subTitle}}</p>
                        </div>
                        <div>
                            <button class="btn btn-success" (click)="checkSupport(support)" style="width: 90px;">{{support.button}}</button>
                            <span *ngIf="support.isActive">Not Active</span>
                        </div>
                    </div>
                </ng-container>
                <ng-template #notSupport>
                    <div class="m-3">
                        <p>Dear User,</p>
                        <p>You are not eligible our 24X7 experts service Please subscribe premium membership and enjoy learning with us
                        </p>
                        <img style="width: 100%; padding: 0px 120px;" src="assets/pay_online.svg" alt="" srcset="">
                        <div class="text-center mt-4">
                            <button routerLink="/subscription-plan" data-bs-dismiss="modal" class="choose-plan me-2">Free Subscription</button>
                            <button routerLink="/subscription-plan" data-bs-dismiss="modal" class="choose-plan ">Paid Subscription</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="youtubeVideo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="d-flex justify-content-end py-2">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" #radioVideo (change)="onChangeVideo(radioVideo.checked)" id="flexSwitchCheckDefault">
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="videoWrapper youtube-video" *ngIf="question?.video">
                <ng-container *ngIf="question?.video; else load">
                    <div class="videoWrapper" *ngIf="question?.video;else notVideo">
                        <iframe [src]="videoFlag ? (question?.video.english | video) : (question?.video.hindi | video)" allowfullscreen frameborder="0"></iframe>
                    </div>
                </ng-container>
                <ng-template #notVideo>Video not available!</ng-template>
                <ng-template #load><fa-icon [icon]="spinner"></fa-icon></ng-template>
                <!-- <iframe allowfullscreen [src]="question.videoFlag ? (question?.video.english | video) : (question?.video.hindi | video)" frameborder="0"></iframe> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="loginModal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-0">
                <button type="button" hidden class="btn-close" data-bs-dismiss="modal" #closePopup></button>
            </div>
            <div class="modal-body p-0">
                <app-login-popup (close)="closePopup.click()"></app-login-popup>
            </div>
        </div>
    </div>
</div>