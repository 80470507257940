import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent implements OnInit {
  leftArrow = faChevronLeft;
  rightArrow = faChevronRight;
  page = 0;
  size = 10;

  @Input() sizeText: string = "Questions";
  @Input() bottom: boolean;
  @Input() pagination: any;

  @Output() changePage = new EventEmitter();

  constructor(private route: ActivatedRoute) {
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(q => {
      this.page = (q?.page - 1) || 0;
      this.size = q?.size || 10;
    })
  }

  triggerChange() {
    this.changePage.emit({ page: this.page, size: this.size });
  }
  triggerChangeSize(no: number) {
    this.size = no;
    this.triggerChange();
  }
  triggerChangeIndex(index: number) {
    this.page = index;
    this.triggerChange();
  }
  triggerNext() {
    this.page = this.page + 1;
    this.triggerChange();
  }
  triggerPrev() {
    this.page = this.page - 1;
    this.triggerChange();
  }

  counter(i: number) {
    return new Array(i);
  }

}
