import { Pipe, PipeTransform } from '@angular/core';
import hljs from 'highlight.js';
//import hljslb from 'highlightjs-line-numbers.js';

@Pipe({
  name: 'highlightCodeLang'
})
export class HighlightLangCodePipe implements PipeTransform {

  transform(htmlMarkup: string, langugeCode: string): string {
    let codeBlockHighlighted: string;
    //hljslb.initLineNumbersOnLoad
    codeBlockHighlighted = hljs.highlight(htmlMarkup, {language: langugeCode, ignoreIllegals: true}).value;
    return '<pre><code class="hljs">' + codeBlockHighlighted + '</pre></code>';

    // const preCodeRegex = /<pre><code(?: class="language-(.*)")?>([\s\S]*?)<\/code><\/pre>/g;

    // return htmlMarkup.replace(preCodeRegex, function (_match, languageName, codeBlock) {
    //   let codeBlockHighlighted: string;

    //   if (!languageName) {
    //     codeBlockHighlighted = hljs.highlight('java', codeBlock, true).value;
    //   } else {
    //     codeBlockHighlighted = hljs.highlight(languageName, codeBlock, true).value;
    //   }

      
    // });
  }
}