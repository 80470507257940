import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHeight]'
})
export class HeightDirective {
  constructor(el: ElementRef) {
    console.dir(el.nativeElement);
    if (el.nativeElement) {
      const parent: HTMLDivElement = el.nativeElement;
      const height = parent.firstChild ? parent.firstChild : 0;
    }
  }
}
