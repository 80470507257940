import { IUser, User } from "./user";

export interface IReview {
    reviewId: number;
    courseId: string;
    courseType: string;
    rating: number;
    review: string;
    user: IUser;
    postedDate: string;
}

export class Review {
    reviewId: number;
    courseId: string;
    courseType: string;
    rating: number;
    review: string;
    user: IUser;
    postedDate: string;

    constructor(props: IReview) {
        this.reviewId = props.reviewId;
        this.courseId = props.courseId;
        this.courseType = props.courseType;
        this.rating = props.rating;
        this.review = props.review;
        this.user = props.user && new User(props.user);
        this.postedDate = props.postedDate;
    }
}