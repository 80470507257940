export interface IUser {
    userId: number;
    name: string;
    designation: string;
    company: string;
    linkedIn: string;
    image: string;
}

export class User {
    userId: number;
    name: string;
    designation: string;
    company: string;
    linkedIn: string;
    image: string;

    constructor({ userId, name, designation, company, linkedIn, image }: IUser) {
        this.userId = userId;
        this.name = name;
        this.designation = designation;
        this.company = company;
        this.linkedIn = linkedIn;
        this.image = image;
    }
}
