<div id="sliderCourse-container" [ngClass]="{showSlider: show}">
    <div class="heading d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <span class="material-icons-sharp">grid_view</span>
            <p class="mx-2 mb-0 all-course-text">All Course</p>
        </div>
        <mat-icon class="close-icon" (click)="toggleButton()">close</mat-icon>
    </div>
    <mat-nav-list class="side-navnar">
        <ng-container *ngFor="let menu of list; index as i;">
            <ng-container [ngTemplateOutlet]="childMenu" [ngTemplateOutletContext]="{menu:menu}">
            </ng-container>
        </ng-container>
    </mat-nav-list>

    <ng-template #parentMenu let-menu="menu">
        <mat-list-item [routerLink]="menu.routerLink" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:
        true}">
            <mat-icon>{{menu.icon}}</mat-icon> {{menu.name}}
        </mat-list-item>
    </ng-template>

    <ng-template #childMenu let-menu="menu">
        <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header (click)="setSelectedCourseMenu(menu)">
                {{menu.name}}
            </mat-expansion-panel-header>
            <mat-nav-list>
                <!-- <mat-list-item> -->
                    <div *ngIf="selectedCourseMenu && selectedCourseMenu.crcourse && selectedCourseMenu.crcourse.length !== 0">
                        <h6 class="mt-2 mb-2 px-3">Master Course</h6>
                        <div class="d-flex certification-course-menu flex-column">
                            <a href="#" *ngFor="let courseItem of selectedCourseMenu.crcourse">{{courseItem.route}}</a>
                        </div>
                    </div>
                    <div *ngIf="selectedCourseMenu && selectedCourseMenu.fscourse && selectedCourseMenu.fscourse.length !== 0">
                        <h6 class="mt-3 mb-2 px-3">FullStack Program</h6>
                        <div class="full-stack-menu">
                            <div class="d-flex flex-column" *ngFor="let fullstackItem of selectedCourseMenu.fscourse">
                                <div class="d-flex align-items-center">
                                    <img [src]="iconURL + fullstackItem.icon + '.svg'" alt="">
                                    <p>{{fullstackItem.courseName}}</p>
                                </div>
                                <ul>
                                    <li>{{fullstackItem.noc}} Course | {{fullstackItem.duration}} Hours
                                        <!-- <div class="line-li"></div> -->
                                    </li>
                                    <li>{{fullstackItem.learners}}k Satisfied Learners
                                        <!-- <div class="line-li"></div> -->
                                    </li>
                                    <li>Job Guaranteed Program</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedCourseMenu && selectedCourseMenu.spcourse && selectedCourseMenu.spcourse.length !== 0">
                        <h6 class="mt-3 mb-2 px-3">Self Placed Course</h6>
                        <div class="self-placed-course">
                            <div class="spcourse-card">
                                <div class="d-flex mx-3" *ngFor="let selfPaced of selectedCourseMenu.spcourse">
                                    <img [src]="iconURL + selfPaced.icon + '.svg'" alt="">
                                    <div class="d-flex flex-column justify-content-between">
                                        <p class="elipsis-para elipsis-2" [title]="selfPaced.courseName">{{selfPaced.courseName}}</p>
                                        <p><span>{{selfPaced.videoCount}} Lectures</span><span>{{selfPaced.duration}} Hours</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </mat-list-item> -->
            </mat-nav-list>
        </mat-expansion-panel>
    </ng-template>
</div>