import { utf8Encode } from '@angular/compiler/src/util';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowAltCircleLeft,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/Api/common.service';
import { MainService } from 'src/app/Api/mainService';
import { ISignUp } from 'src/app/model/auth/singup';
import { countryCodes } from 'src/app/model/country_codes';
import { AuthService } from 'src/app/user/auth.service';
import { ConfirmedValidator } from 'src/app/user/sign-up/validators';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

// import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
declare var $: any;

@Component({
  selector: 'app-signup-popup',
  templateUrl: './signup-popup.component.html',
  styleUrls: ['./signup-popup.component.scss'],
})
export class SignupPopupComponent implements OnInit {
  // separateDialCode = false;
  // SearchCountryField = SearchCountryField;
  // CountryISO = CountryISO;
  // PhoneNumberFormat = PhoneNumberFormat;
  // preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  numberObj: string;

  userIcon = faUser;
  closeIcon = faTimes;
  otpResentMessage: string = '';
  googleIcon = faGoogle;
  facebookIcon = faFacebook;
  otpFlag: boolean = false;
  educationFlag: boolean = false;
  signupFormGroup: FormGroup = this.fb.group({});
  otpFormGroup: FormGroup = this.fb.group({});
  userId!: number;
  arrowLeft = faArrowAltCircleLeft;
  spinner = faSpinner;
  spinnerFlag = false;
  countryCodes = countryCodes;
  otp_input: any;
  numberRegEx = /\-?\d*\.?\d{1,2}/;
  disableConfirmPassword: boolean = true;
  signUpErrorMessage: string = '';
  @Output() close = new EventEmitter();
  @Output() openLoginPopUp = new EventEmitter();
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private authService: AuthService,
    private commomApi: MainService
  ) {
    this.commonService.setCategoryDropdown(true);
    this.signupFormGroup = this.fb.group({
      name: ['', Validators.required],
      countryCode: ['+91', Validators.required],
      mobile: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email ]],
      // mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            '^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$'
          ),
        ],
      ],
      profession: ['', Validators.required],
    });
    this.otpFormGroup = this.fb.group(
      {
        // otp: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]],
        c_password: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('password', 'c_password'),
      }
    );
    // this.otpFormGroup?.get('c_password')?.disable();
    // this.otpFormGroup?.get('password')?.disable();

    this.otpFormGroup?.get('password')?.valueChanges.subscribe((val) => {
      if (!this.f?.password?.valid) {
        this.otpFormGroup?.get('c_password')?.disable();
      }
      if (this.f?.password?.valid) {
        this.otpFormGroup?.get('c_password')?.enable();
      }
    });
  }

  typeOf(item: any) {
    return typeof item;
  }
  closePopup() {
    this.close.emit();
  }

  loginPopUp() {
    this.openLoginPopUp.emit();
  }
  get f() {
    return this.otpFormGroup.controls;
  }
  ngOnInit(): void {
    console.log(this.countryCodes);
  }

  keypressAlphabats(event: any) {
    var inputValue = event.keyCode;
    // allow letters and whitespaces only.
    if (
      !(inputValue >= 65 && inputValue <= 120) &&
      !(inputValue == 8 || inputValue == 46) &&
      inputValue != 32 &&
      inputValue != 0
    ) {
      event.preventDefault();
    }
  }
  keypressNumber(event: any) {
    var inputValue = event.keyCode;
    // allow letters and whitespaces only.
    if (
      !(inputValue >= 48 && inputValue <= 57) &&
      !(inputValue == 8 || inputValue == 46) &&
      inputValue != 32 &&
      inputValue != 0
    ) {
      event.preventDefault();
    }
  }
  onNumberChange($event: any) {
    if ($event) {
      console.log($event);
      this.signupFormGroup.patchValue({
        countryCode: $event.countryCode,
        mobile: String($event.number).split(' ').join('').replace('+', ''),
      });
    } else {
      this.signupFormGroup.patchValue({
        countryCode: '+91',
        mobile: '',
      });
    }
  }
  async createAccount() {
    if (this.signupFormGroup.invalid) return;
    this.signUpErrorMessage = '';
    this.spinnerFlag = true;
    // if (this.signupFormGroup.value && this.signupFormGroup.value.isoCode === 'IN') {
    //   this.signupFormGroup.value.mobile = '91' + this.signupFormGroup.value.mobile;
    // } else if (this.signupFormGroup.value && this.signupFormGroup.value.isoCode === 'USA') {
    //   this.signupFormGroup.value.mobile = '1' + this.signupFormGroup.value.mobile;
    // }
    try {
      let data = await this.commomApi.post('/user/continue-signup', {
        email: this.signupFormGroup.value.email,
        mobile: this.signupFormGroup.value.mobile,
      });
      if (data.data) {
        this.authService.signupSuccessMsg = data.message;
        this.otpFlag = true;
        // this.educationFlag = false;
        this.spinnerFlag = false;
      }
    } catch (error: any) {
      this.signUpErrorMessage = error.data;
      this.spinnerFlag = false;
    }
    // return
    // this.authService.signUp(this.signupFormGroup.value as ISignUp)
    // .then((res: any) => {
    //   this.userId = res.data;
    //   this.authService.signupErrorMsg = '';
    //   this.authService.signupSuccessMsg = res.message;
    //   this.authService.signupErrorFlag = true;
    //   this.otpFlag = true;
    //   this.spinnerFlag = false;
    // }).catch((err: any) => {
    //   this.spinnerFlag = false;
    //   console.log(err);
    // })
  }
  signupUser() {
    if (this.signupFormGroup.invalid) return;
    this.spinnerFlag = true;
    if (
      this.signupFormGroup.value &&
      this.signupFormGroup.value.isoCode === 'IN'
    ) {
      this.signupFormGroup.value.mobile =
        '91' + this.signupFormGroup.value.mobile;
    } else if (
      this.signupFormGroup.value &&
      this.signupFormGroup.value.isoCode === 'USA'
    ) {
      this.signupFormGroup.value.mobile =
        '1' + this.signupFormGroup.value.mobile;
    }
    this.signupFormGroup.value.password = this.otpFormGroup.value.password;
    this.authService
      .signUp(this.signupFormGroup.value as ISignUp)
      .then((res: any) => {
        this.userId = res.data;
        this.educationFlag = true;
        this.spinnerFlag = false;
      })
      .catch((err: any) => {
        this.spinnerFlag = false;
        console.log(err);
      });
  }
  skipOTP(): void {
    this.educationFlag = true;
  }
  message: any;
  btnDisable: boolean = false;
  loadingFlag: boolean = false;
  verifyAccount(): void {
    this.loadingFlag = true;
    // if(this.otpFormGroup.value.otp.length>3)
    const mobile = this.signupFormGroup.value.mobile;
    const otp = this.otpFormGroup.value.otp;
    if (otp.length === 0) {
      return;
    }
    this.authService
      .varifyOTP(mobile, otp)
      .then((res: any) => {
        if (res.status) {
          this.btnDisable = false;
          this.otpFormGroup?.get('password')?.enable();
          // this.authService.signupErrorMsg = '';
          this.message = res.message;
          // this.authService.signupErrorFlag = true;
          // this.educationFlag = true;
        } else {
          this.btnDisable = true;
          this.message = res.message;
        }
        this.loadingFlag = false;
      })
      .catch((err: any) => {
        console.log(err);
        this.btnDisable = true;
        this.loadingFlag = false;
        // this.authService.signupErrorMsg = 'invalid OTP';
        // this.authService.signupSuccessMsg = "";
        // this.authService.signupErrorFlag = true;
      });
  }
  onPress(e: any) {
    if (this.otp_input.length == 4) {
      this.verifyAccount();
    }
  }

  back() {
    this.educationFlag = false;
    this.otpFlag = false;
  }

  async resend() {
    this.otpResentMessage = 'Resent ...';
    try {
      let body = {
        mobile: this.signupFormGroup.value.mobile,
        email: this.signupFormGroup.value.email,
      };
      let res = await this.commomApi.post(`/user/resend-otp`, body);
      this.otp_input = '';
      this.message = '';
    } catch (error) {}
    this.otpResentMessage = '';
  }

  isValid(controlerName: string): boolean {
    return (
      this.signupFormGroup.controls[controlerName].invalid &&
      this.signupFormGroup.controls[controlerName].touched
    );
  }

  get signupErrorFlag(): boolean {
    return this.authService.signupErrorFlag;
  }
  get signupErrorMsg(): string {
    return this.authService.signupErrorMsg;
  }
  get signupSuccessMsg(): string {
    return this.authService.signupSuccessMsg;
  }

  hideSignupMessage(): void {
    this.authService.changeSingupFlag();
  }
}
