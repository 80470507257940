export interface IModule {
  moduleId: number;
  moduleName: string;
  route: string;
  count: number;
  icon: string;
  descp: string;
}
export class Module implements IModule {
  moduleId: number;
  moduleName: string;
  route: string;
  count: number;
  icon: string;
  descp: string;

  constructor({ moduleId, moduleName, route, count, icon, descp }: IModule) {
    this.moduleId = moduleId;
    this.moduleName = moduleName;
    this.route = route;
    this.count = count;
    this.icon = icon;
    this.descp = descp;
  }
}
