import { Component, Input, OnInit, Output, Renderer2,EventEmitter } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryList, ICategoryList } from 'src/app/model/categoryList';
import { HomeService } from '../../services/home.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICourseMenu } from 'src/app/model/training/common';


@Component({
  selector: 'app-course-slider',
  templateUrl: './course-slider.component.html',
  styleUrls: ['./course-slider.component.scss']
})
export class CourseSliderComponent implements OnInit {
  faBars = faBars;
  iconURL = environment.iconUrl;
  _selectedCourseMenu!: ICourseMenu;
  
  constructor(private homeService: HomeService) { 
  }

  @Input() show: boolean;
  @Output() toggleVisibility = new EventEmitter;

  ngOnInit() {
    this.homeService.fetchTrainingMenu();
  }

  toggleButton() {
    this.show = !this.show
    this.toggleVisibility.emit(this.show)
  }
 
  get list(): ICourseMenu[] {
    return this.homeService.courseMenuList;
  }

  get courseMenuList(): ICourseMenu[] {
    return this.homeService.courseMenuList;
  }

  get selectedCourseMenu(): ICourseMenu {
    return this._selectedCourseMenu || (this.courseMenuList && this.courseMenuList[0]) || [];
  }

  setSelectedCourseMenu(course: ICourseMenu) {
    this._selectedCourseMenu = course;
  }

}
