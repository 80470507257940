<!-- <div id="side-nav" (click)="stopPropagation($event)">
    <nav>
        <ul>
            <ng-container *ngFor="let item of list; let i = index" >
            <li >
                <a (click)="toggleDrawer(item.name)" [class.active]="sideDrawer === item.name">
                    <p class="link-icon" >{{item.name}}</p>
                </a>
                <div  [class.show]="sideDrawer === seletctedItem" (click)="stopPropagation($event)">
                    <p class="sidenav-title">{{seletctedItem}}</p>
                    <nav>
                        <ul>
                            <ng-container *ngFor="let tech of item.languages; let i = index">
                                <li  (click)="closeDrawer()"><a [routerLink]="['/']" routerLinkActive="active">
                                    {{tech.name}}
                                    <span class="link-icon" ><img [src]="tech.icon ? iconURL + tech.icon+'.svg' : iconURL + 'java.svg'" alt="">
                                    </span>                                 </a>
                                
                            
                            </li>
                            </ng-container>
                
                         </ul>
                    </nav>
                </div>
            </li>
        </ng-container>
        </ul>

    </nav>
</div> -->

<div id="slider-container" [ngClass]="{showSlider: show}">
    <div class="heading d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <span class="material-icons-sharp">grid_view</span>
            <p class="mx-2 mb-0 paragraph">Categories</p>
        </div>
        <mat-icon class="close-icon" (click)="toggleButton()">close</mat-icon>
    </div>
    <mat-nav-list class="side-navnar">
        <ng-container *ngFor="let menu of list; index as i;">
            <ng-container [ngTemplateOutlet]="menu.languages ?  childMenu : parentMenu" [ngTemplateOutletContext]="{menu:menu}">
            </ng-container>
        </ng-container>
    </mat-nav-list>

    <ng-template #parentMenu let-menu="menu">
        <mat-list-item [routerLink]="menu.routerLink" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:
        true}">
            <mat-icon>{{menu.icon}}</mat-icon> {{menu.name}}
        </mat-list-item>
    </ng-template>
    <ng-template #childMenu let-menu="menu">
        <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>{{menu.name}}</mat-expansion-panel-header>
            <mat-nav-list>
                <mat-list-item (click)="toggleButton()" [routerLink]="'/'+submenu.route" *ngFor="let submenu of menu.languages" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="link-icon"><img [src]="submenu.icon ? iconURL + submenu.icon+'.svg' : iconURL + 'java.svg'" alt="">
                    </span> {{submenu.name}}
                </mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel>
    </ng-template>
</div>