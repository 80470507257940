<div class="wx-nav-home-bar">
    <div class="container-fluid d-flex align-items-center justify-content-between">
        <div class="d-flex overflow-auto small-scroller align-items-center">
            <button type="button" class="btn wx-nav-btn" [routerLink]="['/'+routeName]" routerLinkActive="active-nav" [routerLinkActiveOptions]="{ exact: true }">
                Home
            </button>
            <button *ngFor="let m of modules" type="button" class="btn wx-nav-btn" [routerLink]="['/'+routeName+'/'+m.route]" routerLinkActive="active-nav">
              {{m.moduleName}}
            </button>
        </div>
    </div>
</div>