import { Component, Input, OnInit } from '@angular/core';
import { MainService } from 'src/app/Api/mainService';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
})
export class BlogCardComponent implements OnInit {
  blogUrl = environment.blogUrl;
  @Input() blog: any;
  blogThumbsUrl = environment.blogThumbsUrl;
  tradingList: any;
  constructor(private mainService: MainService) {}

  ngOnInit() {
    //  await  this.getTrandingBlog();
  }

  // async getTrandingBlog(){

  //   try{
  //     let data = await this.mainService.get('/blogs/trending-blogs')
  //     this.tradingList =data.data
  //     console.log(this.tradingList);

  //   }catch(error){

  //   }
  // }
}
