import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  getCounter(endDate: Date){

    let data = {days: 0, hours: 0, mins: 0, secs: 0, isShow: true};
    let current = new Date().getTime();
    let next = new Date(endDate).getTime();
    
    let delta = Math.abs(next - current) / 1000;
    
    data.days = Math.floor(delta / 86400);
    delta -= data.days * 86400;
    data.hours = Math.floor(delta / 3600) % 24;
    delta -= data.hours * 3600;

    // calculate (and subtract) whole minutes
    data.mins = Math.floor(delta / 60) % 60;
    delta -= data.mins * 60;

    if(data.days == 0 && data.hours == 0 && data.mins == 0 && data.secs == 0){
      data.isShow = false;
    }

    // what's left is seconds
    data.secs = Math.floor(delta % 60);
    return data;

  }
}
