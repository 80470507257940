export interface IInstructor {
    userId: number;
    name: string;
    linkedIn: string;
    // image: string;
}
export class Instructor {
    userId: number;
    name: string;
    linkedIn: string;
    // image: string;
    constructor({ userId, name, linkedIn}: IInstructor) {
      this.userId = userId;
      this.name = name;
      this.linkedIn = linkedIn;
      // this.image = image;
    }
}
export interface IEmployee {
  empId: string;
  userId: number;
  designation: string;
  role: string;
  status: string;
  jobType: string;
  tagLine: string;
  profile: string;
  profilePic: string;
  sequence:number;
  instructor: IInstructor;
}
export class Employee implements IEmployee {
  empId: string;
  userId: number;
  designation: string;
  role: string;
  status: string;
  jobType: string;
  tagLine: string;
  profile: string;
  profilePic: string;
  sequence:number;
  instructor: IInstructor;

  constructor(props: IEmployee) {
    this.empId = props.empId;
    this.userId = props.userId;
    this.designation = props.designation;
    this.role = props.role;
    this.status = props.status;
    this.jobType = props.jobType;
    this.tagLine = props.tagLine;
    this.profile = props.profile;
    this.profilePic = props.profilePic;
    this.sequence = props.sequence;
    this.instructor = new Instructor(props.instructor);
  }
}
