<nav class="navbar navbar-light bg-white navbar-expand-md navbar-expand-lg p-0">
    <div class="container-fluid flex-md-column flex-lg-column align-items-start mt-0 p-0">
        <div class="header-tree d-flex align-items-center justify-content-between w-100 py-2 px-2">
            <div class="d-flex align-items-center">
                <button class="navbar-toggler border-0 p-0" data-bs-toggle="offcanvas" data-bs-target="#leftSideMenu" type="button" aria-controls="leftSideMenu">
                    <span data-bs-toggle="offcanvas" data-bs-target="#leftSideMenu" class="navbar-toggler-icon"></span>
                </button>
                <img [src]="navLogo" style="width: 25px; height: 25px;" alt="">
                <p class="m-0 mx-2">{{navTitle}}</p>
            </div>
            <button class="navbar-toggler border-0 p-0" data-bs-toggle="offcanvas" data-bs-target="#rightSideMenu" type="button" aria-controls="rightSideMenu">
                <span data-bs-toggle="offcanvas" data-bs-target="#rightSideMenu"  class="material-icons-outlined">alt_route</span>
            </button>
        </div>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="leftSideMenu" aria-labelledby="leftSideMenuLabel">
            <div class="offcanvas-header header-tree">
                <div class="d-flex align-items-center">
                    <img [src]="navLogo" style="width: 25px; min-height: 25px;" alt="">
                    <p class="m-0 mx-2">{{navTitle}}</p>
                </div>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body tree">
                <div class="tree-panel w-100" style="margin-top: 10px;">
                    <div *ngFor="let node of nodes" class="position-relative nodes">
                        <div (click)="node.hideShow()" class="d-flex align-items-center position-relative py-1 parent-node">
                            <ng-container *ngIf="node.hasTopics">
                                <fa-icon class="icons position-absolute" [icon]="node.isCollapsed ? minusSquare : plusSquare"></fa-icon>
                            </ng-container>
                            <p class="rootName m-0 mx-4" [class.fw-bold]="node.isCollapsed">{{node.techName}}</p>
                        </div>
                        <span class="material-icons-outlined section-expand" (click)="node.setExpandAll()">{{node.isExpandAll ? 'unfold_less' : 'unfold_more'}}</span>
                        <ng-container *ngIf="node.hasTopics && node.isCollapsed">
                            <div *ngFor="let topic of node.topics" class="child-node">
                                <div class="d-flex align-items-center" [routerLink]="[routerId+'/'+node.techName.toLowerCase().replace(' ','-')+'/'+topic.route]" routerLinkActive="router-link-active">
                                    <!-- <span class="material-icons full-icon">circle</span>-->
                                    <p class="rootName m-0">
                                        <a [id]="topic.topicId">{{topic.topicName}}</a>
                                    </p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-end d-lg-none d-md-none" tabindex="-1" id="rightSideMenu" aria-labelledby="rightSideMenuLabel">
            <div class="offcanvas-header header-tree px-1">
                <div class="d-flex align-items-center">
                    <p class="m-0 mx-2">Switch</p>
                </div>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <app-lang-box [offCanvas]="true" ></app-lang-box>
            </div>
        </div>
    </div>
</nav>