import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MainService } from 'src/app/Api/mainService';

@Component({
  selector: 'app-quiz-card',
  templateUrl: './quiz-card.component.html',
  styleUrls: ['./quiz-card.component.scss']
})
export class QuizCardComponent implements OnInit {

  quizs:any;

  page=0;
  size=3;

  constructor( private mainService:MainService,
    private cookies: CookieService,
    private router: Router) { }

  async ngOnInit() {
   await this.getQuizCard();

  }
  async getQuizCard(){
    try{
   
      let data = await this.mainService.get('/quiz/quiz-cards?name=java&size='+this.size+'&page='+this.page+'')
      this.quizs = data.data
    }catch(error){
    }
  }
  nextPage(){
    this.page=this.page+1
    this.getQuizCard()
  }
  previousPage(){
    this.page=this.page-1
    this.getQuizCard() 
  }
  routeToQuizPage(quiz: any) {
    console.log(quiz);
    let Future = 10 * 365 * 24 * 60 * 60;
    // localStorage.setItem('quizId', quiz.quizId);
    this.cookies.set('quizId', quiz.quizId, Future, '/');
    if (quiz.moduleType == 'MM' && quiz.submitType == 'MS') {
      this.router.navigate(['/quiz/multi-module-quiz']);
    } else if (quiz.moduleType == 'MM') {
      this.router.navigate(['/quiz/module-quiz']);
    } else if (quiz.moduleType == 'NM') {
      this.router.navigate(['/quiz/simple-quiz']);
    }
    else {
      alert("No module type found")
    }
  }
}
