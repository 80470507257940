import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../Api/api.service';
import { ISignUp, IUserInfo } from '../model/auth/singup';
import { CookieService } from 'ngx-cookie-service';
@Injectable()
export class AuthService {
  signupErrorMsg = '';
  signupErrorFlag = false;
  signupSuccessMsg = '';

  constructor(
    private apiService: ApiService,
    private cookies: CookieService,
    private router: Router
  ) {
    // this.cookies.deleteAll();
  }

  signUp(values: ISignUp) {
    return this.apiService
      .signUp(values)
      .toPromise()
      .then((res: any) => res)
      .catch((err: any) => {
        this.signupErrorMsg = err.error.message;
        this.signupErrorFlag = true;
        console.error(err);
      });
  }

  async logout() {
    let Future = 10 * 365 * 24 * 60 * 60;

    // localStorage.setItem('user', "");
    // localStorage.setItem('token', "");
    // localStorage.clear();

    this.cookies.set('user', '', Future, '/');
    this.cookies.set('token', '', Future, '/');
    // this.cookies.set.clear();

    setTimeout(() => {
      location.reload();
    }, 100);
  }

  get currentUser(): any {
    const user = this.cookies.get('user');
    if (!user) return null;
    return JSON.parse(user);
  }

  get currentUserFlag(): boolean {
    const token = this.cookies.get('token') || '';
    return token.length !== 0;
  }

  changeSingupFlag(): void {
    this.signupErrorFlag = false;
    this.signupErrorMsg = '';
    this.signupSuccessMsg = '';
  }

  get getSignupErrorFlag(): boolean {
    return this.signupErrorFlag;
  }

  get getSignupErrorMsg(): string {
    return this.signupErrorMsg;
  }
  get getSignupSuccessMsg(): string {
    return this.signupSuccessMsg;
  }
  updateUserInfo(value: IUserInfo) {
    return this.apiService.updateUserInfo(value).toPromise();
  }

  resendOTP(mobileoremail: string) {
    return this.apiService.resendOTP(mobileoremail).toPromise();
  }

  resendOTPLogin(mobileoremail: string) {
    return this.apiService.resendOTPLogin(mobileoremail).toPromise();
  }

  varifyOTP(mobile: string, otp: string) {
    return this.apiService.verifyOTP(mobile, otp).toPromise();
  }

  requestForOTP(mobileEmail: string) {
    return this.apiService.requestForOTP(mobileEmail).toPromise();
  }

  loginByPassword(userId: string, password: string) {
    return this.apiService
      .loginByPassword(userId, password)
      .toPromise()
      .then((res: any) => {
        let Future = 10 * 365 * 24 * 60 * 60;
        if (res.data) {
          this.cookies.set('user', JSON.stringify(res.data), Future, '/');

          localStorage.setItem('user', JSON.stringify(res.data));
        }
        this.cookies.set('token', res.token, Future, '/');

        // localStorage.setItem('token', res.token);
      });
  }
  loginByOtp(mobile: string, otp: string) {
    return this.apiService
      .loginByOTP(mobile, otp)
      .toPromise()
      .then((res: any) => {
        let Future = 10 * 365 * 24 * 60 * 60;
        if (res.data) {
          // localStorage.setItem('user', JSON.stringify(res.data));
          this.cookies.set('user', JSON.stringify(res.data), Future, '/');
        }
        // localStorage.setItem('token', res.token);
        this.cookies.set('token', res.token, Future, '/');
      });
  }
  isUserExist(mobileoremail: string) {
    return this.apiService
      .isUserExist(mobileoremail)
      .toPromise()
      .then((r: any) => {
        return r;
      });
  }

  forgotPasswordUserId(userId: string) {
    return this.apiService.getUserForgotPassword(userId).toPromise();
  }

  updatePassword(login: string, password: string) {
    return this.apiService.updatePassword(login, password).toPromise();
  }
}
