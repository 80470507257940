import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/Api/common.service';
import { IModule } from 'src/app/model/home/module-list';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
})
export class LangMenuComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((r: any) => {
      if (r.courseName) {
        this.homeService.fetchModulesLangId(r.courseName);
        setTimeout(() => {
          this.findNav();
        }, 2000);
      }
    });
  }

  findNav() {
    var left = $('.wx-nav-home-bar .small-scroller .active-nav').offset()?.left;
    if (left != undefined) {
      $('.wx-nav-home-bar .small-scroller')[0].scrollLeft =
        ($('.wx-nav-home-bar .small-scroller .active-nav').offset()?.left ??
          0) - 70;
    } else {
      this.findNav();
    }
  }
  get modules(): IModule[] {
    return this.homeService.modulesBylangs;
  }

  get routeName() {
    return this.commonService.courseRouteName;
  }
}
