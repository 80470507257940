import { IUser, User } from "../home/user";

export interface IInterviewQuestions {
  iqId: number;
  langId: number;
  techId: number;
  topicId: number;
  link: string;
  question: string;
  answer: string;
  status: string;
  priority: string;
  video: string;
  vote: number;
  voted: boolean;
  createdDate: string;
  author: IUser;
  get toAnswerArr(): any[];
  videoArr: any;
  bookmark: number;
}
export class InterviewQuestions implements IInterviewQuestions {
  iqId: number;
  langId: number;
  techId: number;
  topicId: number;
  link: string;
  question: string;
  answer: string;
  status: string;
  priority: string;
  video: string;
  vote: number;
  voted: boolean;
  createdDate: string;
  author: IUser;
  videoArr: any;
  bookmark: number;
  constructor(props: IInterviewQuestions) {
    this.iqId = props.iqId;
    this.langId = props.langId;
    this.techId = props.techId;
    this.topicId = props.topicId;
    this.link = props.link;
    this.question = props.question;
    this.answer = props.answer;
    this.status = props.status;
    this.priority = props.priority;
    this.video = props.video;
    this.vote = props.vote;
    this.voted = false;
    this.createdDate = props.createdDate;
    this.author = new User(props.author);
    this.videoArr = this.video ? JSON.parse(this.video) : [];
    this.bookmark = props.bookmark;
  }

  get toAnswerArr() {
    if (!this.answer) return [];
    return JSON.parse(this.answer);
  }

  get toVideoObj() {
    if (!this.video) return {};
    return JSON.parse(this.video);
  }
}

export interface IInterviewTitle {
  iqId: number;
  priority: string;
  question: string;
  techName: string;
  title: string;
  topicName: string;
  get route(): string;
}
export class InterviewTitle implements IInterviewTitle {
  iqId: number;
  priority: string;
  question: string;
  techName: string;
  title: string;
  topicName: string;

  constructor({ iqId, priority, question, techName, title, topicName }: IInterviewTitle) {
    this.iqId = iqId;
    this.priority = priority;
    this.question = question;
    this.techName = techName;
    this.title = title;
    this.topicName = topicName;
  }
  get route(): string {
    return this.title;
  }
}
