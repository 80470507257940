<div class="carousel" *ngIf="list.length !== 0">
  <div class="list" #carousalList [style]="'min-height:'+ height + 'px'">
    <div class="item w-100" *ngFor='let item of list; let i=index;trackBy: trackByMethod'
      [style]="'transform: translate('+ ((width) * (i - curIndex) + extraWidth) +'px);max-width:'+(width - gap)+'px;margin-right:'+(gap)+'px'">
      <ng-container *ngTemplateOutlet="tmplRef; context: { $implicit: item }"></ng-container>
    </div>
  </div>
  <div class="dots" *ngIf="dotsFlag">
    <ng-container *ngFor='let item of list; let i=index'>
      <ng-container *ngIf="carousalList">
        <div class="dot" [hidden]="hideDots(carousalList) < i" (click)="nextdot(i)" [ngClass]="curIndex === i ? 'active' : ''"></div>
      </ng-container>
    </ng-container>
  </div>
  <button class="left" [hidden]="curIndex === 0" (click)="prev()"><</button>
  <button class="right" [hidden]="curIndex >= hideDots(carousalList)" (click)="next()">></button>
</div>
