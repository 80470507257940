import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faClipboardList, faPhone, faShareAlt, faSpinner, faVideo } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { IMCQuestion } from 'src/app/model/language/mcq-question';
import { AuthService } from 'src/app/user/auth.service';
import { TutorialService } from '../../services/tutorial.service';


@Component({
  selector: 'app-mcq-question',
  templateUrl: './mcq-question.component.html',
  styleUrls: ['./mcq-question.component.scss']
})
export class McqQuestionComponent implements OnInit {
  youtube = faYoutube;
  editorOptions = { theme: 'myCustomTheme', language: 'java', readOnly: true};
  @Input() question!: IMCQuestion;
  @Input() index: number;
  
  spinner = faSpinner;
  shareIcon = faShareAlt;
  currentLink = window.location.origin + window.location.pathname + window.location.search;
  @Output() closeBtn = new EventEmitter();
  @ViewChild('supportModel') supportModel: any;
  notValid: boolean = false;
  errFlag = false;
  voted = false;
  videoFlag = false;
  reportMessage :string = "";

  callSupprot = [
    {
      icon: faPhone,
      title: 'Call to Export',
      subTitle: 'Get instance help on call or live meeting ie: zoom, anydesk etc.',
      button: 'Call',
      isActive: false
    },
    {
      icon: faVideo,
      title: 'Join Live Meeting',
      subTitle: 'Get instance help on call or live meeting ie: zoom, anydesk etc.',
      button: 'Join',
      isActive: false
    },
    {
      icon: faClipboardList,
      title: 'Raise Ticket',
      subTitle: 'Get instance help on call or live meeting ie: zoom, anydesk etc.',
      button: 'Ticket',
      isActive: false
    },
    {
      icon: faEnvelope,
      title: 'Send Email',
      subTitle: 'Get instance help on call or live meeting ie: zoom, anydesk etc.',
      button: 'Email',
      isActive: false
    }
  ]
  @ViewChild('') answerPopup!: ElementRef;
  errorComment: string = '';
  constructor(private tutorialService: TutorialService,
    private authService: AuthService, private cookie: CookieService) { }

  ngOnInit(): void {
  }

  checkSupport(obj: any) {
    if (!obj.isActive) {
      this.notValid = true;
    }
  }
  onChangeVideo(flag: boolean) {
    this.videoFlag = flag;
  }
  handleAnswer(question: IMCQuestion) {
    const user = this.authService.currentUser;
    if (this.authService.currentUserFlag) {
      if (user && this.levels(user.authorization.mcq).includes(question.complexity)) {
        question.answerFlag = !question.answerFlag;
        question.videoFlag = false;
        question.reportFlag = false;
        question.suggestFlag = false;
        if (!question.answer) {
          this.tutorialService.fetchMCQAnswer(question.mcqId)
        }
      }
    }
  }
  bookmark(question: IMCQuestion){
    if(!this.loginFlag) {
      this.LoginModal.modal('show');
      return false;
    }
    question.bookmark = question.bookmark == 1 ? 0 : 1;
    this.tutorialService.bookmark('mcq', question.mcqId);
    return true;
  }
  getAccess(question: any): boolean {
    const user = this.authService.currentUser;
    return user && this.levels(user.authorization.mcq).includes(question.complexity)
  }
  levels(v: any): any[] {
    if (v == 'L1') {
      return ['L1'];
    }
    if (v == 'L2') {
      return ['L1', 'L2'];
    }
    if (v == 'L3') {
      return ['L1', 'L2', 'L3'];
    }

    return [];
  }
  get loginFlag(): boolean {
    return this.authService.currentUserFlag;
  }
  LoginModal: any = $("#loginModal");
  handleVideo(question: IMCQuestion) {
    if (this.authService.currentUserFlag) {
      question.answerFlag = false;
      question.videoFlag = !question.videoFlag;
      question.reportFlag = false;
      question.suggestFlag = false;
      if (!question.answer) {
        this.tutorialService.fetchMCQAnswer(question.mcqId)
      }
    }
  }
  handleSuport(question: IMCQuestion) {
    if (this.authService.currentUserFlag) {
      question.answerFlag = false;
      question.suggestFlag = !question.suggestFlag;
      question.videoFlag = false;
      question.reportFlag = false;
    }
  }
  
  handleReport(question: IMCQuestion) {
    if(!this.loginFlag) {
      this.LoginModal.modal('show');
      return false;
    }
    const user = this.authService.currentUser;
    if (!user) return;
    this.tutorialService.submitErrorMes({
      "userId": user.userId,
      "mcqId": question.mcqId,
      "error": this.errorComment
    }).toPromise().then((res: any) => {
      this.closeAll();
      this.errorComment = '';
    }).catch(console.log)
    return true;
  }

  closeError(question: IMCQuestion){
    if (this.authService.currentUserFlag) {
      question.videoFlag = false;
      question.answerFlag = false;
      question.suggestFlag = false;
      question.reportFlag = !question.reportFlag;
    }
  }
  closeAll(){
    this.question.videoFlag = false;
    this.question.answerFlag = false;
    this.question.suggestFlag = false;
    this.question.reportFlag = false;
    this.errorComment = "";
    this.reportMessage = "";
  }

  closeReport() {
    if (this.authService.currentUserFlag) {
      this.closeBtn.emit();
      this.question.videoFlag = false;
      this.question.answerFlag = false;
      this.question.suggestFlag = false;
      this.question.reportFlag = false;
    }
  }

  voteMessage: any = "";
  async vote(vote: number){
    if(!this.loginFlag) {
      this.LoginModal.modal('show');
      return false;
    }
    this.voted = true;
    let res = await this.tutorialService.mcqVote(vote, this.question.mcqId);
    this.voteMessage = res.message;
    if(res.message != "You already voted!"){
      this.question.likes++;
    } else {
      setTimeout(() => {
        this.voted = false;
      }, 300);
    }
    return true;
  }
  reportSubmitButton: any;
  async submitErrorComment(event: any) {
    if(!this.loginFlag) {
      this.LoginModal.modal('show');
      return false;
    }
    this.reportSubmitButton = event.target;
    $(this.reportSubmitButton).hide();
    let res = await this.tutorialService.mcqReport(this.errorComment, this.question.mcqId);
    console.log(res);
    this.reportMessage = res.message;
    setTimeout(() => {
      $(this.reportSubmitButton).show();
      this.closeAll();
    }, 4000);
    return true;
  }
}
