import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  editorOptions = { theme: 'myCustomTheme', language: 'java', readOnly: true };
  @Input() languageDetails: any[] = [];
  @Input() videoId?: any;

  constructor() {}
  articleUrl = environment.articleUrl;
  adsUrl = environment.adsUrl;

  ngOnInit(): void {}

  isObject(obj: any) {
    return (
      obj !== null && typeof obj === 'object' && Array.isArray(obj) === false
    );
  }
}
