export interface IMetaProps {
    name: string;
    content: string;
}

export class MetaProps {
    name: string;
    content: string;

    constructor({ name, content }: IMetaProps) {
        this.name = name;
        this.content = content;
    }
}

export interface ILangKeywords {
    keywordId: number;
    langId: number;
    keyword: string;
    flag: boolean;
}
export class LangKeywords {
    keywordId: number;
    langId: number;
    keyword: string;
    flag: boolean;
    constructor({ keywordId, langId, keyword }: ILangKeywords){
      this.keywordId = keywordId;
      this.langId = langId;
      this.keyword = keyword;
      this.flag = false;
    }
}

export interface ILinkProps {
    name: string;
    content: string;
}

export class LinkProps {
    name: string;
    content: string;
    constructor({ name, content }: ILinkProps) {
        this.name = name;
        this.content = content;
    }
}

export interface ISeoProps {
    title: string;
    meta: IMetaProps[];
    link: ILinkProps[]
}
export class SeoProps implements ISeoProps {
    title: string;
    meta: IMetaProps[];
    link: ILinkProps[];
    constructor({ title, meta, link }: ISeoProps) {
        this.title = title;
        this.meta = meta.map(m => new MetaProps(m));
        this.link = link.map(l => new LinkProps(l));
    }
}
