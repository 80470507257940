import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CommonSeoService {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private metaService: Meta,
    private titleService: Title,
    private router: Router
  ) {}

  updateMetaTags(metaTags: any) {
    if (metaTags['title']) {
      this.titleService.setTitle(metaTags['title']);
    }
    if (metaTags['keywords']) {
      this.metaService.updateTag({
        property: 'keywords',
        content: metaTags['keywords'],
      });
    } else {
      this.metaService.removeTag("property='keywords'");
    }
    if (metaTags['description']) {
      this.metaService.updateTag({
        property: 'description',
        content: metaTags['description'],
      });
    } else {
      this.metaService.removeTag("property='description'");
    }
    if (metaTags['canonical'] && this.doc) {
      this.updateCanonicalUrl(metaTags['canonical']);
    }
    if (metaTags['og:url']) {
      this.metaService.updateTag({
        property: 'og:url',
        content: metaTags['og:url'],
      });
    } else {
      this.metaService.updateTag({
        property: 'og:url',
        content: this.router.url,
      });
    }
    if (metaTags['og:title']) {
      this.metaService.updateTag({
        property: 'og:title',
        content: metaTags['og:title'],
      });
    } else {
      this.metaService.removeTag("property='og:title'");
    }
    if (metaTags['og:description']) {
      this.metaService.updateTag({
        property: 'og:description',
        content: metaTags['og:description'],
      });
    } else {
      this.metaService.removeTag("property='og:description'");
    }
    if (metaTags['twitter:title']) {
      this.metaService.updateTag({
        property: 'twitter:title',
        content: metaTags['twitter:title'],
      });
    } else {
      this.metaService.removeTag("property='twitter:title'");
    }
    if (metaTags['twitter:description']) {
      this.metaService.updateTag({
        property: 'twitter:description',
        content: metaTags['twitter:description'],
      });
    } else {
      this.metaService.removeTag("property='twitter:description'");
    }
  }
  updateCanonicalUrl(url: string) {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement | null =
      this.doc.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
