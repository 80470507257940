import { Component, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ICategoryList } from 'src/app/model/categoryList';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-training-breadcrumbs',
  templateUrl: './training-breadcrumbs.component.html',
  styleUrls: ['./training-breadcrumbs.component.scss']
})
export class TrainingBreadcrumbsComponent implements OnInit {
  faBars = faBars;
  routelist: any = [
    { name: 'Home', route: '/training' },
    { name: 'Master Course', route: '/training/certification-course' },
    { name: 'FullStack Course', route: '/training/full-stack-courses' },
    // { name: 'Video Course', route: '/course' },
    { name: 'Corporate Training', route: '/corporate' },
  ]
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
  }

  get list(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }

}
