import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/Api/api.service';
import { CommonService } from 'src/app/Api/common.service';
import { SeoService } from 'src/app/home/services/seo.service';
import { TutorialService } from 'src/app/language/services/tutorial.service';
import { ISeoProps } from 'src/app/model/home/common';
import { IReview, Review } from 'src/app/model/home/review';
import { ILanguageDetails } from 'src/app/model/language/lang-details';
import { IPagination, Pagination } from 'src/app/model/language/mcq-question';
import { ILink, ITopic, ITree, Link, Tree } from 'src/app/model/language/tree';
import {
  BatchTable,
  CertificationCourse,
  CertificationCourseDetails,
  IBatchTable,
  ICertificationCourse,
  ICertificationCourseDetails,
} from 'src/app/model/training/certification-course';
import {
  CourseCostTable,
  ICourseCostTable,
  ITechnology,
  Technology,
} from 'src/app/model/training/common';
import { ICourse } from 'src/app/model/training/course';
import { Employee, IEmployee } from 'src/app/model/training/employees';
import {
  FSAlumni,
  FSTree,
  FullstackCourse,
  FullstackCourseDetails,
  IFSAlumni,
  IFSTree,
  IFullstackCourse,
  IFullstackCourseDetails,
} from 'src/app/model/training/fullstack-course';
import {
  IPrimePackCourses,
  ISelfpacedCourseDetails,
  ISelfpacedCourses,
  PrimePackCourses,
  SelfpacedCourseDetails,
  SelfpacedCourses,
} from 'src/app/model/training/Selfpaced-Course';
import { RequestBatch } from '../components/request-form/request-form.component';

@Injectable()
export class TrainingService {
  private _categories: any[];
  private _languages: any[];
  private _courseList: ICourse[] = [];
  private _courseTree: ITree[] = [];
  private _certificationCourses: ICertificationCourse[] = [];
  private _filteredCertificationCourses: ICertificationCourse[] = [];
  private _filterCertificationCourses: ICertificationCourse[] = [];
  private _certificationCoursesDetails!: ICertificationCourseDetails;
  private _selfpacedCoursesDetails!: ISelfpacedCourseDetails;
  private _fullstackCoursesDetails!: IFullstackCourseDetails;
  private _fullstackCoursesAlumniTXT: IFSAlumni[] = [];
  private _fullstackCoursesAlumniVID: IFSAlumni[] = [];
  private _selfpacedCourses: ISelfpacedCourses[] = [];
  private _primePackCourses: IPrimePackCourses[] = [];
  private _fullstackCourse: IFullstackCourse[] = [];
  private _curriculumTree: ITechnology[] = [];
  private _fsCurriculumTree: ITechnology[] = [];
  private _batchTable: IBatchTable[] = [];
  private _reviewList: IReview[] = [];
  private _courseCostTable!: ICourseCostTable;
  private _expandFlag = false;
  private _fsTree: IFSTree[] = [];
  private flagFetch = false;
  private prevFlagFetch = false;
  public selectedCategoryId = 0;
  private _advisorEmployees: IEmployee[] = [];
  constructor(
    private apiService: ApiService,
    private seoService: SeoService,
    private router: Router,
    private commonService: CommonService,
    private tutorialService: TutorialService
  ) {
    this.tutorialService.subjectOpenNode.subscribe(
      (value: ILanguageDetails) => {
        this.openBy(value.techId, value.topicId, value.title);
      }
    );
  }

  get categories(): any[] {
    return this._categories;
  }
  get languages(): any[] {
    return this._languages;
  }

  loadCategoryDropdown(): void {
    this.apiService.category.toPromise().then((x) => {
      this._categories = x.data;
    });
  }

  loadLanguagesByCategoryIdDropdown(categoryId: number): void {
    this.apiService
      .langWitCategoryId(categoryId)
      .toPromise()
      .then((x) => {
        this._languages = x.data;
      });
  }

  get courseList(): ICourse[] {
    return this._courseList;
  }

  get courseTree(): ITree[] {
    return this._courseTree;
  }

  fetchTreeLink(
    topic: ITopic,
    flag?: boolean,
    courseName?: string,
    moduleName?: string
  ) {
    if (topic.links.length !== 0) return;
    this.apiService
      .langTreeLink(topic.topicId)
      .pipe(
        map((lang: ILink[]) =>
          lang.map((l: ILink, i: number) => new Link(l, i))
        )
      )
      .toPromise()
      .then((v) => (topic.links = v))
      .then((v) => {
        if (flag) {
          this.router.navigate([
            courseName + '/' + moduleName + '/' + topic.links[0].route,
          ]);
        }
      })
      .catch((err) => console.log(err));
  }

  fetchCourseTree({ langId }: any): void {
    this.apiService
      .courseTree({ langId })
      .pipe(map((v) => v.map((v1: ITree, i: number) => new Tree(v1, this, i))))
      .toPromise()
      .then((v) => {
        this._courseTree = v;
        const details = this.tutorialService.tutorialDetails;
        if (details && details.techId && details.topicId && details.title) {
          this.openById(details.techId, details.topicId, details.title);
        }
      })
      .catch((err) => console.log(err));
  }
  fetchCertificationCourse(): void {
    this.apiService.certificationCourses
      .pipe(
        map((c: any) =>
          c.data.content.map(
            (c1: ICertificationCourse) => new CertificationCourse(c1)
          )
        )
      )
      .toPromise()
      .then((course: ICertificationCourse[]) => {
        this._certificationCourses = course;
      })
      .catch((err) => console.log(err));
  }

  get certificationCourses(): ICertificationCourse[] {
    return this._certificationCourses;
  }

  get filteredCertificationCourses(): ICertificationCourse[] {
    return this._filteredCertificationCourses;
  }

  fetchTrendingCourse(page: number): void {
    this.apiService
      .trendingCourse(page)
      .pipe(
        map((c: any) =>
          c.data.content.map(
            (c1: ICertificationCourse) => new CertificationCourse(c1)
          )
        )
      )
      .toPromise()
      .then((course: ICertificationCourse[]) => {
        this._filteredCertificationCourses = course || [];
      })
      .catch((err) => console.log(err));
    this.selectedCategoryId = 0;
  }

  fetchTrendingCourseById(categoryId: number, page: number): void {
    this.selectedCategoryId = categoryId;
    this.apiService
      .trendingCourseById(categoryId, page)
      .pipe(
        map((c: any) =>
          c.data.content.map(
            (c1: ICertificationCourse) => new CertificationCourse(c1)
          )
        )
      )
      .toPromise()
      .then((course: ICertificationCourse[]) => {
        this._filteredCertificationCourses = course || [];
      })
      .catch((err) => (this._filteredCertificationCourses = []));
  }

  fetchCertificationCourseDetails(courseName: string): void {
    this.apiService
      .certificationCoursesDetails(courseName)
      .pipe(
        map((c: any) => {
          return new CertificationCourseDetails(c.data);
        })
      )
      .toPromise()
      .then((course: ICertificationCourseDetails | undefined) => {
        if (course) {
          this._certificationCoursesDetails = course;
          this.setSeoProps(course.metadataArr);
          this.fetchCurriculumTree({ pageId: course.courseId });
          this.fetchBatches({ pageId: course.courseId });
          this.fetchCourseReview(course.courseId, 'cr');
          this.fetchConstTable(course.courseId, 'cr');
        }
      })
      .catch((err) => console.log(err));
  }

  get certificationCoursesDetails(): ICertificationCourseDetails {
    return this._certificationCoursesDetails;
  }

  fetchFullstackCourses(): void {
    if (this.fullstackCourses.length === 0) {
      this.apiService.fullstackCourses
        .pipe(
          map((c: any) =>
            c.data.content.map(
              (c1: IFullstackCourse) => new FullstackCourse(c1)
            )
          )
        )
        .toPromise()
        .then((course: IFullstackCourse[]) => {
          this._fullstackCourse = course;
        })
        .catch((err) => console.log(err));
    }
  }

  get fullstackCourses(): IFullstackCourse[] {
    return this._fullstackCourse;
  }

  fetchSelfpacedCourses(page: number): void {
    if (this.selfpacedCourses.length === 0) {
      this.apiService
        .selfpacedCourses(page)
        .pipe(
          map((c: any) =>
            c.data.content.map(
              (c1: ISelfpacedCourses) => new SelfpacedCourses(c1)
            )
          )
        )
        .toPromise()
        .then((course: ISelfpacedCourses[]) => {
          this._selfpacedCourses = course;
        })
        .catch((err: any) => console.log(err));
    }
  }
  fetchprimePackCourses(page: number): void {
    if (this.primepackCourses.length === 0) {
      this.apiService
        .primePackCourses(page)
        .pipe(
          map((c: any) =>
            c.data.content.map(
              (c1: IPrimePackCourses) => new PrimePackCourses(c1)
            )
          )
        )
        .toPromise()
        .then((course: IPrimePackCourses[]) => {
          this._primePackCourses = course;
        })
        .catch((err: any) => console.log(err));
    }
  }

  fetchSelfpacedCourseDetails(title: string, type: string): void {
    this.apiService
      .coursesDetails(title, type)
      .pipe(
        map(
          (self: ISelfpacedCourseDetails[]) =>
            new SelfpacedCourseDetails(self[0])
        )
      )
      .toPromise()
      .then((self: ISelfpacedCourseDetails) => {
        this._selfpacedCoursesDetails = self;
      })
      .catch(console.log);
  }

  get selfpacedCourseDetails(): ISelfpacedCourseDetails {
    return this._selfpacedCoursesDetails;
  }

  get selfpacedCourses(): ISelfpacedCourses[] {
    return this._selfpacedCourses;
  }
  get primepackCourses(): IPrimePackCourses[] {
    return this._primePackCourses;
  }

  fetchOldCertification() {
    this._filteredCertificationCourses = this._certificationCourses;
    this.selectedCategoryId = 0;
  }

  fetchCurriculumTree({ pageId }: any): void {
    this.apiService
      .curriculumTree(pageId)
      .pipe(
        map((c: any) => {
          return c.data.map((c1: ITechnology) => new Technology(c1));
        })
      )
      .toPromise()
      .then((c) => (this._curriculumTree = c))
      .catch((err) => (this._curriculumTree = []));
  }

  fetchBatches({ pageId }: any): void {
    this.apiService
      .certificationCourseBatch(pageId)
      .pipe(
        map((c: any) => {
          return c.data.map((c1: IBatchTable) => new BatchTable(c1));
        })
      )
      .toPromise()
      .then((c) => (this._batchTable = c))
      .catch((err) => (this._batchTable = []));
  }
  fetchfullStackBatches({ pageId }: any): void {
    this.apiService
      .fullstackCourseBatch(pageId)
      .pipe(
        map((c: any) => {
          return c.data.map((c1: IBatchTable) => new BatchTable(c1));
        })
      )
      .toPromise()
      .then((c) => (this._batchTable = c))
      .catch(console.log);
  }

  fetchCourseReview(id: any, type: string): void {
    this.apiService
      .trainingReviewDetails(id, type)
      .pipe(map((rs: any) => rs.data.map((r: IReview) => new Review(r))))
      .toPromise()
      .then((rs: IReview[]) => {
        this._reviewList = rs;
      })
      .catch(console.log);
  }

  fetchConstTable(id: number, type: string): void {
    this.apiService
      .getConstTable(id, type)
      .pipe(
        map((rs: any) => {
          console.log('rs', rs);
          return new CourseCostTable(rs.data);
        })
      )
      .toPromise()
      .then((rs: ICourseCostTable) => {
        this._courseCostTable = rs;
      })
      .catch(console.log);
  }

  get courseCostTable(): ICourseCostTable {
    return this._courseCostTable;
  }

  fetchEmployees(role: string | null): void {
    this.apiService
      .employees(role)
      .pipe(
        map((emps: any) => emps.data.map((emp: IEmployee) => new Employee(emp)))
      )
      .toPromise()
      .then((emp: IEmployee[]) => {
        this._advisorEmployees = emp;
      })
      .catch(console.log);
  }

  get advisorEmployees(): IEmployee[] {
    return this._advisorEmployees;
  }

  fetchCourseView(title: string, type: string = 'certificate') {
    this.apiService
      .coursesDetails(title, type)
      .pipe(
        map((fs: any) => {
          if (fs.data) {
            return new FullstackCourseDetails(fs.data);
          }
          return undefined;
        })
      )
      .toPromise()
      .then((fs: IFullstackCourseDetails | undefined) => {
        if (fs) {
          this._fullstackCoursesDetails = fs;
          this.fetchFullstackCourseTree(fs.programId, 'fullstack');
          this.setSeoProps(fs.metadataArr);
          this.fetchAlumni(fs.programId, 'TXT');
          this.fetchfullStackBatches({ pageId: fs.programId });
          this.fetchCourseReview(fs.programId, 'cr');
          this.fetchConstTable(fs.programId, 'FS');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchFullstackCourseTree(id: number, type: string) {
    this.apiService
      .fullstackCourseTree(id, type)
      .pipe(map((tree: IFSTree[]) => tree.map((tre) => new FSTree(tre))))
      .toPromise()
      .then((tree: IFSTree[]) => {
        this._fsTree = tree;
        if (this._fsCurriculumTree.length === 0) {
          this.fetchFSCurriculumTree(tree[0].langId);
        }
      })
      .catch(console.log);
  }

  get fsTree() {
    return this._fsTree;
  }

  get fscAlumniTXT(): IFSAlumni[] {
    return this._fullstackCoursesAlumniTXT;
  }
  get fscAlumniVID(): IFSAlumni[] {
    return this._fullstackCoursesAlumniVID;
  }
  get fullstackCoursesDetails(): IFullstackCourseDetails {
    return this._fullstackCoursesDetails;
  }

  get batchTable(): IBatchTable[] {
    return this._batchTable;
  }
  get curriculumTree(): ITechnology[] {
    return this._curriculumTree;
  }
  get fsCurriculumTree(): ITechnology[] {
    return this._fsCurriculumTree;
  }

  fetchFSCurriculumTree(id: number): void {
    this.apiService
      .curriculumTree(id)
      .pipe(
        map((c: any) => {
          return c.data
            ? c.data.map((c1: ITechnology) => new Technology(c1))
            : [];
        })
      )
      .toPromise()
      .then((c) => (this._fsCurriculumTree = c))
      .catch((err) => (this._fsCurriculumTree = []));
  }

  get prev(): string {
    let title = '';
    this.courseTree.forEach((tree: ITree) => {
      tree.topics.forEach((topic: ITopic) => {
        topic.links.forEach((link: ILink) => {
          if (link.urlTitle === this.commonService.topicName) {
            if (
              link.currentIndex === 0 &&
              topic.currentIndex === 0 &&
              tree.currentIndex === 0
            ) {
              title = '';
              return;
            } else {
              if (link.currentIndex === 0 && topic.currentIndex === 0) {
                const topicList =
                  this.courseTree[tree.currentIndex - 1].topics.length;
                const linkList =
                  this.courseTree[tree.currentIndex - 1].topics[topicList - 1]
                    .links.length;
                if (linkList === 0 && !this.prevFlagFetch) {
                  this.courseTree[tree.currentIndex - 1].topics[
                    topicList - 1
                  ].getLinks();
                  this.prevFlagFetch = true;
                  return;
                }
                title =
                  this.courseTree[tree.currentIndex - 1].topics[topicList - 1]
                    .links[linkList - 1]?.urlTitle || '';
                if (title) {
                  this.prevFlagFetch = false;
                }
                return;
              }
              if (link.currentIndex === 0) {
                const linkList =
                  tree.topics[topic.currentIndex - 1].links.length;
                if (linkList === 0 && !this.prevFlagFetch) {
                  tree.topics[topic.currentIndex - 1].getLinks();
                  this.prevFlagFetch = true;
                  return;
                } else {
                  title =
                    tree.topics[topic.currentIndex - 1].links[linkList - 1]
                      ?.urlTitle || '';
                  if (title) {
                    this.prevFlagFetch = false;
                  }
                  return;
                }
              }
              title = topic.links[link.currentIndex - 1]?.urlTitle || '';
              return;
            }
          }
        });
      });
    });
    return title;
  }
  get next(): string {
    let title = '';
    this.courseTree.forEach((tree: ITree) => {
      tree.topics.forEach((topic: ITopic) => {
        topic.links.forEach((link: ILink) => {
          if (link.urlTitle === this.commonService.topicName) {
            if (
              link.currentIndex === topic.links.length - 1 &&
              topic.currentIndex === tree.topics.length - 1 &&
              tree.currentIndex === this.courseTree.length - 1
            ) {
              title = '';
              return;
            }
            if (
              topic.currentIndex === tree.topics.length - 1 &&
              link.currentIndex === topic.links.length - 1
            ) {
              if (
                this.courseTree[tree.currentIndex + 1].topics[0].links
                  .length === 0 &&
                !this.flagFetch
              ) {
                this.courseTree[tree.currentIndex + 1].topics[0].getLinks();
                this.flagFetch = true;
                return;
              }
              title =
                this.courseTree[tree.currentIndex + 1].topics[0].links[0]
                  ?.urlTitle || '';
              if (title) {
                this.flagFetch = false;
              }
              return;
            }
            if (link.currentIndex === topic.links.length - 1) {
              if (
                tree.topics[topic.currentIndex + 1].links.length === 0 &&
                !this.flagFetch
              ) {
                tree.topics[topic.currentIndex + 1].getLinks();
                this.flagFetch = true;
                return;
              }
              title =
                tree.topics[topic.currentIndex + 1].links[0]?.urlTitle || '';
              if (title) {
                this.flagFetch = false;
              }
              return;
            }
            title = topic.links[link.currentIndex + 1]?.urlTitle || '';
            return;
          }
        });
      });
    });
    return title;
  }

  openById(techId: any, topicId: any, langId: any): void {
    const tech = this.courseTree.find((v: ITree) => v.techId === techId);
    const topic = tech?.topics.find((v: ITopic) => v.topicId === topicId);
    if (tech && topic && topic?.links.length === 0) {
      tech?.show();
      topic.show();
    }
  }

  openBy(techId: any, topicId: any, langId: any): void {
    const tech = this.courseTree.find((v: ITree) => v.techId === techId);
    const topic = tech?.topics.find((v: ITopic) => v.topicId === topicId);
    if (tech && topic) {
      tech?.show();
      topic.showWithoutFetch();
    }
  }

  // nextIndex(tech:ITree, topic: ITopic) {
  //   if(this.courseTree.length === tech.currentIndex && tech.currentIndex === tech.topics.length)
  // }

  // nextData(i: number,j: number,k: number,v: ITree,v1: ITopic,link: ILink, tree: ITree[]): string {
  //   if ((tree.length - 1) === i && (v.topics.length - 1) === j && (v.topics[j].links.length - 1) === k) return '';
  //   if ((v.topics.length - 1) === j && (v.topics[j].links.length - 1) === k) {
  //     return tree[i+1].topics[j+1].links[0].urlTitle;
  //   }
  //   if ((v.topics[j].links.length - 1) === k) {
  //     return v.topics[j+1].links[0].urlTitle;
  //   }
  //   return v.topics[j].links[k+1].urlTitle;
  // }

  // prevData(i: number,j: number,k: number,v: ITree,v1: ITopic,link: ILink): string {
  //   if (i === 0 && j === 0 && k === 0) return '';
  //   if (k === 0) {
  //     const last = v.topics[j-1].links.length - 1;
  //     return v.topics[j-1].links[last].urlTitle;
  //   }
  //   return v.topics[j].links[k-1].urlTitle;
  // }

  setExpandAll() {
    this._expandFlag = !this._expandFlag;
  }

  get isExpandAll() {
    return this._expandFlag;
  }

  expandAll() {
    this._courseTree.forEach((v: ITree) => {
      v.flagTopic = this._expandFlag;
    });
  }

  fetchReview(): void {
    this.apiService
      .trainingReview()
      .pipe(map((rs: any) => rs.data.map((r: IReview) => new Review(r))))
      .toPromise()
      .then((rs: IReview[]) => {
        this._reviewList = rs;
      })
      .catch(console.log);
  }

  get reviewList(): IReview[] {
    return this._reviewList;
  }

  setSeoProps(seoProps: ISeoProps): void {
    this.seoService.setTitle(seoProps);
    this.seoService.setMetaData(seoProps);
    this.seoService.setLink(seoProps);
  }

  fetchAlumni(courseId: number, type: 'TXT' | 'VID'): void {
    this.apiService
      .aluminList(courseId, type)
      .pipe(
        map((alumni: any) => alumni.data.map((v: IFSAlumni) => new FSAlumni(v)))
      )
      .toPromise()
      .then((al: IFSAlumni[]) => {
        if (type === 'TXT') {
          this._fullstackCoursesAlumniTXT = al;
        } else {
          this._fullstackCoursesAlumniVID = al;
        }
      })
      .catch(console.log);
  }

  addBatchRequest(value: RequestBatch): void {
    this.apiService.addBatchRequest(value).toPromise();
  }

  public coursePagination!: IPagination;
  filterCertificationCourse(body: any): void {
    this.apiService
      .filterCertificationCourse(body)
      .pipe(
        map((c: any) => {
          this.coursePagination = new Pagination(c.data);
          return c.data.content.map(
            (c1: ICertificationCourse) => new CertificationCourse(c1)
          );
        })
      )
      .toPromise()
      .then((course: ICertificationCourse[]) => {
        this._filterCertificationCourses = course || [];
      })
      .catch((err) => {
        this._filterCertificationCourses = [];
        console.log(err);
      });
  }

  filterFullStackCourse(body: any): void {
    this.apiService
      .filterFullStackCourse(body)
      .pipe(
        map((c: any) => {
          this.coursePagination = new Pagination(c.data);
          return c.data.content.map(
            (c1: IFullstackCourse) => new FullstackCourse(c1)
          );
        })
      )
      .toPromise()
      .then((course: IFullstackCourse[]) => {
        this._fullstackCourse = course || [];
      })
      .catch((err) => {
        this._fullstackCourse = [];
        console.log(err);
      });
  }

  get filterCertificationCourses(): ICertificationCourse[] {
    return this._filterCertificationCourses;
  }
}
