import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { CommonHeaderComponent } from './common-header.component';
import { CourseImgCardComponent } from './course-img-card/course-img-card.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LangMenuComponent } from './lang-menu/lang-menu.component';
import { CourseTreeComponent } from './course-tree/course-tree.component';
import { CourseBlogComponent } from './course-blog/course-blog.component';
import { SwitchLangComponent } from './switch-lang/switch-lang.component';
import { EnquiryComponent } from '../enquiry/enquiry/enquiry.component';
import { TechnologyComponent } from '../enquiry/technology/technology.component';
import { KeywordsComponent } from '../enquiry/keywords/keywords.component';
import { DetailsComponent } from '../enquiry/details/details.component';
import { CompletedComponent } from '../enquiry/completed/completed.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PageBreadcrumbsComponent } from './page-breadcrumbs/page-breadcrumbs.component';
import { TrainingBreadcrumbsComponent } from './training-breadcrumbs/training-breadcrumbs.component';
import {MatMenuModule} from '@angular/material/menu';
import { ShareLanguageModule } from 'src/app/language/components/share-language.module';
import { CategorySliderComponent } from './category-slider/category-slider.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { MaterialModule } from 'src/app/material/material.module';
import { MobMenuComponent } from './mob-menu/mob-menu.component';
import { HighlightModule } from 'ngx-highlightjs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CourseSliderComponent } from './course-slider/course-slider.component';
import { SPCourseBannerComponent } from './spcourse-banner/spcourse-banner.component';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

const COMPONENTS = [
  HomeBannerComponent,
  SPCourseBannerComponent,
  CommonHeaderComponent,
  CourseImgCardComponent,
  BlogCardComponent,
  CategoryMenuComponent,
  LangMenuComponent,
  CourseTreeComponent,
  CourseBlogComponent,
  SwitchLangComponent,
  EnquiryComponent,
  TechnologyComponent,
  KeywordsComponent,
  DetailsComponent,
  CompletedComponent,
  PageBreadcrumbsComponent,
  TrainingBreadcrumbsComponent,
  CategorySliderComponent,
  MobMenuComponent,
  CourseSliderComponent,
]
@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ShareLanguageModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    MaterialModule,
    HighlightModule,
    NgxIntlTelInputModule,
    ShareButtonsPopupModule,
    ShareIconsModule
  ],
  exports: [
    ...COMPONENTS,
    HighlightModule,
    ShareButtonsPopupModule,
    ShareIconsModule
  ],
})
export class HomeShareModule { }
