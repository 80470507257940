import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  faCircle,
  faMinusSquare,
  faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { CommonService } from 'src/app/Api/common.service';
import { TrainingService } from 'src/app/training/services/training.service';
import { TutorialService } from 'src/app/language/services/tutorial.service';
import { ITreeQuestion } from 'src/app/model/language/tree';
import { HomeService } from 'src/app/home/services/home.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tree-question',
  templateUrl: './tree-question.component.html',
  styleUrls: ['./tree-question.component.scss'],
})
export class TreeQuestionComponent implements OnInit {
  plusSquare = faPlus;
  minusSquare = faMinus;
  fullpoint = faCircle;
  @Input() routerId = '';
  @Input() navTitle = '';
  @Input() navLogo = '';

  constructor(
    private route: ActivatedRoute,
    private tutorialService: TutorialService,
    private homeService: HomeService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((r) => {
      this.homeService.setQustionTree(r.courseName);
    });
  }

  get nodes(): ITreeQuestion[] {
    return this.tutorialService.getTreeQuestions;
  }

  get courseName(): string {
    return '/' + this.commonService.courseRouteName || '';
  }
}
