import { IUser } from "../home/user";

export interface ICodingTitle {
  codeId: number;
  topicId: number;
  topicName: string;
  title: string;
  blueTick: number;
  urlTitle: string;
  get route(): string;
}
export class CodingTitle implements ICodingTitle {
  codeId: number;
  topicId: number;
  topicName: string;
  title: string;
  blueTick: number;
  urlTitle: string;

  constructor({ codeId, topicId, title, blueTick, urlTitle, topicName }: ICodingTitle) {
    this.blueTick = blueTick;
    this.codeId = codeId;
    this.topicId = topicId;
    this.topicName = topicName;
    this.title = title;
    this.urlTitle = urlTitle;
  }
  get route(): string {
    if(location.href.indexOf(this.topicName) > -1){
      return this.urlTitle;
    }
    return this.topicName + "/" + this.urlTitle;
  }
}
export interface IQuestionCode {
  codeSolId: number;
  codeId: number;
  code: string;
  fileName: string;
}
export class QuestionCode {
  codeSolId: number;
  codeId: number;
  code: string;
  fileName: string;
  constructor({ codeSolId, code, codeId, fileName }: IQuestionCode) {
    this.codeSolId = codeSolId;
    this.codeId = codeId;
    this.code = code;
    this.fileName = fileName;
  }
}

export interface ICoding {
  code: string;
  codeId: number;
  codeSolId: number;
  explanation: string;
  fileName: string;
  input: string;
  output: string;
  user: IUser;
  video: string;
  subtitle:string;
  statement:any;
  likes:number;
  unlikes:number;
  voted: number;
  postedDate:string;
}

export class Coding {
  code: string;
  codeId: number;
  codeSolId: number;
  explanation: string;
  fileName: string;
  input: string;
  output: string;
  user: IUser;
  video: string;
  subtitle:string;
  statement:any;
  likes:number;
  unlikes:number;
  voted: number;
  postedDate:string;
  constructor(props: ICoding) {
    this.code = props.code;
    this.codeId = props.codeId;
    this.codeSolId = props.codeSolId;
    this.explanation = props.explanation;
    this.fileName = props.fileName;
    this.input = props.input;
    this.output = props.output;
    this.user = props.user;
    this.video =  props.video;
    this.subtitle = props.subtitle;
    this.statement = props.statement;
    this.likes = props.likes;
    this.unlikes = props.unlikes;
    this.voted = props.voted ?? -1;
    this.postedDate = props.postedDate;
  }

  get videoObj(): any {
    return this.video ? JSON.parse(this.video) : null;
  }
}

export interface ICodingQuestion {
  codeId: number;
  topicId: number;
  topicName: string;
  title: string;
  link: string;
  statement: string;
  blueTick: number;
  likes: number;
  unlikes: number;
  views: number;
  input: string;
  output: string;
  explanation: string;
  publishedDate:string;
  updatedDate:string;
  video: string;
  metadata: string;
  user: IUser;
  solution: string;
  solutionObj: any[];
  // outputJSON: any[];
  explanationObj: any[];
  coding: ICoding[];
  download: string;
  urlTitle: string;
  problemStmt: any;
  voted: number;
  prevTopicName: string;
  nextTopicName: string;
  prevTitle: string;
  nextTitle: string;
  bookmark: number;
}

export class CodingQuestion implements ICodingQuestion {
  codeId: number;
  topicId: number;
  topicName: string;
  title: string;
  link: string;
  statement: string;
  blueTick: number;
  likes: number;
  unlikes: number;
  views: number;
  input: string;
  output: string;
  explanation: string;
  video: string;
  metadata: string;
  publishedDate:string;
  updatedDate:string;
  user: IUser;
  solution: string;
  solutionObj: any[];
  // outputJSON: any[];
  explanationObj: any[];
  coding: ICoding[];
  download: string;
  urlTitle: string;
  problemStmt: any;
  voted: number;
  prevTopicName: string;
  nextTopicName: string;
  prevTitle: string;
  nextTitle: string;
  bookmark: number;
  constructor(props: ICodingQuestion) {
    this.codeId = props.codeId;
    this.topicId = props.topicId;
    this.title = props.title ? props.title.replace(/-/g, ' ') : '';
    this.link = props.link;
    this.statement = props.statement;
    this.topicName = props.topicName;
    this.blueTick = props.blueTick;
    this.likes = props.likes;
    this.unlikes = props.unlikes;
    this.views = props.views;
    this.input = props.input;
    this.output = props.output;
    this.explanation = props.explanation;
    this.video = props.video;
    this.metadata = props.metadata;
    this.download = props.download;
    this.user = props.user;
    this.solution = props.solution;
    this.urlTitle = props.urlTitle;
    this.publishedDate= props.publishedDate;
    this.updatedDate = props.updatedDate;
    this.problemStmt = props.problemStmt ? JSON.parse(props.problemStmt) : [];
    // this.outputJSON = props.output? JSON.parse(props.output):[];
    this.explanationObj = props.explanation ? JSON.parse(props.explanation) : [];
    this.solutionObj = props.solution ? JSON.parse(props.solution) : [];
    this.voted = props.voted ?? -1;
    this.coding = props.coding && props.coding.length !== 0 ? props.coding.map((c: ICoding) => new Coding(c)) : [];
    this.prevTopicName = props.prevTopicName;
    this.nextTopicName = props.nextTopicName;
    this.prevTitle = props.prevTitle;
    this.nextTitle = props.nextTitle;
    this.bookmark = props.bookmark;
  }
}
