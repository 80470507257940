import { Component, OnInit } from '@angular/core';
import { faFacebook, faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-mob-menu',
  templateUrl: './mob-menu.component.html',
  styleUrls: ['./mob-menu.component.scss']
})
export class MobMenuComponent implements OnInit {
  faFacebook = faFacebookF;
  faLinkedinIn = faLinkedinIn;
  twitter = faTwitter;
  youtube = faYoutube;
  onlineTrainIcon = onlineTrainIcon;
  homeIcon = homeIcon;
  constructor() { }

  ngOnInit(): void {
  }

}

const homeIcon ='https://www.svgrepo.com/show/21736/house-outline.svg';
const onlineTrainIcon ='/assets/icons/training.png';

