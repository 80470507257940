import { Component, Input, OnInit, Output, Renderer2,EventEmitter } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryList, ICategoryList } from 'src/app/model/categoryList';
import { HomeService } from '../../services/home.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-category-slider',
  templateUrl: './category-slider.component.html',
  styleUrls: ['./category-slider.component.scss']
})
export class CategorySliderComponent implements OnInit {
  faBars = faBars;
  iconURL = environment.iconUrl;

  constructor(private homeService: HomeService) { 

  }

  @Input() show: boolean;
  @Output() toggleVisibility = new EventEmitter;

  ngOnInit() {
    this.homeService.fetchListWithLanguage();
  }

  toggleButton() {
    this.show = !this.show
    this.toggleVisibility.emit(this.show)
  }


 
  get list(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }
  get selectedFirstItem():ICategoryList {
    return this.list && this.list[0];
  }


}
