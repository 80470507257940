<div class="mobile-phone">
  <i class="fa fa-search"></i>
</div>
<div class="search d-flex align-items-stretch d-none " id="search">
  <input type="text" placeholder="Search">
  <button><span class="material-icons-outlined">search</span></button>
</div>
<div>
  <a class="d-flex flex-column d-none align-items-center wx-main-menu c-pointer login pe-4">
    <span class="material-icons-outlined wx-main-menu-icons" (click)="openLoginPopUp()" *ngIf="!isLogedIn">person_outline</span>
    <p class="m-0 font-size-12" *ngIf="!isLogedIn" (click)="openLoginPopUp()">Login</p>
    <img *ngIf="isLogedIn" class="profile-img" [src]="user.image? user.image:'../assets/icons/default-user.png'" alt="">
    <div class="login-dropdown" *ngIf="isLogedIn">
      <div class="d-flex" *ngIf="!isLogedIn">
        <a href="/login">
          <button class="btn btn-primary px-4 me-3">Sign In</button>
        </a>
        <a href="/create-account">
          <button class="btn sign-in px-4">Sign up</button>
        </a>
      </div>
      <div *ngIf="isLogedIn" class="d-flex align-items-center justify-content-between">
        <p class="m-0">{{user?.name}}</p>
        <fa-icon [icon]="powerIcon" class="c-pointer" (click)="logout()"></fa-icon>
      </div>
      <hr *ngIf="isLogedIn" />
      <ul *ngIf="isLogedIn">
        <li *ngFor="let item of listOfLogin" [routerLink]="item.route">{{item.name}}</li>
      </ul>
    </div>
  </a>
</div>

<div class="d-flex align-items-center mobile_bottom_menu">
  <div class="d-none d-sm-block">
    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="expert-btn paragraph">{{categoryDropdown ?
      'Course Enquiry' : 'Talk to Expert'}}</button>
  </div>

  <a [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="top-header-router-link-active" class="d-flex flex-column align-items-center wx-main-menu nav-link">
    <span class="material-icons-outlined wx-main-menu-icons">home</span>
    <p class="m-0 font-size-12">Home</p>
  </a>
  <a [routerLink]="['/training']" routerLinkActive="top-header-router-link-active" class="d-flex flex-column align-items-center wx-main-menu nav-link" [routerLink]="['/training']">
    <!-- <img [src]="onlineTrainIcon" class="wx-main-menu-icons" alt=""> -->
    <span class="material-icons-outlined wx-main-menu-icons">emoji_people</span>
    <p class="m-0 font-size-12">Training</p>
  </a>
  <!-- <a routerLinkActive="top-header-router-link-active" [routerLink]="['/course']"
    class="d-flex flex-column align-items-center wx-main-menu nav-link">
    <span class="material-icons-outlined wx-main-menu-icons">videocam</span>
    <p class="m-0 font-size-12">Course</p>
  </a> -->
  <a class="d-flex flex-column align-items-center wx-main-menu" [routerLink]="['/practice-test']">
    <span class="material-icons-outlined wx-main-menu-icons">code</span>
    <p class="m-0 font-size-12">AAPractice Test</p>
  </a>
  <a class="d-flex flex-column align-items-center wx-main-menu" [routerLink]="['/certification']">
    <span class="material-icons-outlined wx-main-menu-icons">code</span>
    <p class="m-0 font-size-12">Certification</p>
  </a>
  <a class="d-flex flex-column align-items-center wx-main-menu" [routerLink]="['/community']">
    <span class="material-icons-outlined wx-main-menu-icons">forum</span>
    <p class="m-0 font-size-12">Community</p>
  </a>

  <!-- <a class="d-flex flex-column align-items-center d-none d-lg-block d-xl-block pt-3  wx-main-menu">
    <span class="material-icons-outlined wx-main-menu-icons">notifications</span>
    <p class="m-0 font-size-12">Notification</p>
  </a> -->
  <a class="d-flex flex-column d-none d-lg-block d-xl-block pt-3 align-items-center wx-main-menu c-pointer login pe-4">
    <span class="material-icons-outlined wx-main-menu-icons" (click)="openLoginPopUp()" *ngIf="!isLogedIn">person_outline</span>
    <p class="m-0 font-size-12" *ngIf="!isLogedIn" (click)="openLoginPopUp()">Login</p>
    <img *ngIf="isLogedIn" class="profile-img" [src]="user.image? user.image:'../assets/icons/default-user.png'" alt="">
    <div class="login-dropdown" *ngIf="isLogedIn">
      <div class="d-flex" *ngIf="!isLogedIn">
        <a href="/login">
          <button class="btn btn-primary px-4 me-3">Sign In</button>
        </a>
        <a href="/create-account">
          <button class="btn sign-in px-4">Sign up</button>
        </a>
      </div>
      <!-- <div class="d-flex" *ngIf="!isLogedIn">
          <a>
            <button class="btn btn-primary px-4 me-3" (click)="openLoginPopUp()"  >Sign In</button>
          </a>
          <a>
            <button class="btn sign-in px-4" (click)="openSignUpPopUp()"  >Sign up</button>
          </a>
        </div> -->

      <div *ngIf="isLogedIn" class="d-flex align-items-center justify-content-between">
        <p class="m-0">{{user?.name}}</p>abc
        <fa-icon [icon]="powerIcon" class="c-pointer" (click)="logout()"></fa-icon>
      </div>
      <hr *ngIf="isLogedIn" />
      <ul *ngIf="isLogedIn">
        <li *ngFor="let item of listOfLogin" [routerLink]="item.route">{{item.name}}</li>
      </ul>
    </div>
  </a>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="d-flex justify-content-end px-3 pt-3" [style.background-color]="closeIcon ? '' : '#f2f2f2'">
        <button type="button" #closeBtn class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <app-enquiry (closeAll)="closeBtn.click()" (hideCloseBtn)="hideBtn($event)"></app-enquiry>
    </div>
  </div>
</div>


<div class="modal fade" id="loginModal" tabindex="-1" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <button type="button" hidden class="btn-close" data-bs-dismiss="modal" #closePopup></button>
      </div>
      <div class="modal-body p-0 m-0">
        <app-login-popup (openSignUpPopUp)="openSignUpPopUp()" (close)="popupClose(); ngOnInit();"></app-login-popup>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="signUpModal" tabindex="-1" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header p-0">
        <button type="button" hidden class="btn-close" data-bs-dismiss="modal" #closePopup></button>
      </div>
      <div class="modal-body p-0">
        <app-signup-popup (openLoginPopUp)="openLoginPopUp()" (close)="closePopup.click(); ngOnInit();"></app-signup-popup>
      </div>
    </div>
  </div>
</div>