<div class="blog mt-2" *ngIf="blogs?.content?.length > 0">
    <div class="blog-header">
        <h6 class="m-0">Blogs</h6>
    </div>
    <div class="blog-list">
        <div *ngIf="blogs?.content?.length <= 0; else blogstemplet">
            <div class="txt-not-found text-center">
                <img src="./assets/icons/not-found.png" style="height: 40px; width: 40px;"><br>
                No Blogs available for <span class="text-capitalize">{{_courseRoute}}!</span>
            </div>
        </div>
        <ng-template #blogstemplet>
            <div class="carousel-inner">
                <div class=" d-flex align-items-center blog-card" *ngFor="let blog of blogs?.content">
                    <div class="blog-img">
                        <img class="card-img-top" [src]="blogThumbsUrl+blog?.image">
                    </div>
                    <a class="m-0 urlTitle" [routerLink]="[ '/'+_courseRoute+'/blog', blog?.urlTitle]">{{blog?.title}}</a>
                </div>
            </div>
        </ng-template>
    </div>
    <div class="text-center pb-3">
        <div style="border: none;">
            <button class="orange" style="margin-right:10px" [disabled]="blogs?.first" (click)="previousPage()">
                < </button>
                    <button class="orange ml-2" [disabled]="blogs?.last" (click)="nextPage()"> > </button>
        </div>
    </div>
</div>