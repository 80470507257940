<div class="">
 
        <ul class="navbar-nav d-flex flex-row justify-content-between pl-4 pr-4">
          <li class="nav-item ">
            <a [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="top-header-router-link-active"
            class="d-flex flex-column align-items-center wx-main-menu nav-link active">
            <span class="material-icons-outlined wx-main-menu-icons">home</span>
            <p class="m-0 font-size-12">Home</p>
          </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/training']" routerLinkActive="top-header-router-link-active"
            class="d-flex flex-column align-items-center wx-main-menu nav-link active" [routerLink]="['/training']">
            <!-- <img [src]="onlineTrainIcon" class="wx-main-menu-icons" alt=""> -->
            <span class="material-icons-outlined wx-main-menu-icons">emoji_people</span>
            <p class="m-0 font-size-12">Training</p>
          </a>
          </li>

          <li class="nav-item">
          <a routerLinkActive="top-header-router-link-active" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
            class="d-flex flex-column align-items-center wx-main-menu nav-link active">
            <span class="material-icons-outlined wx-main-menu-icons">call</span>
            <!-- <span class="material-icons-outlined wx-main-menu-icons">videocam</span> -->
            <p class="m-0 font-size-12">Enquiry</p>
          </a>
          </li>
          <li class="nav-item ">
            <a class="d-flex flex-column align-items-center wx-main-menu nav-link active" [routerLink]="['/assessment']">
              <span class="material-icons-outlined wx-main-menu-icons ">edit_note</span>
              <p class="m-0 font-size-12">Assessment</p>
            </a>
          </li>
          <!-- <li class="nav-item d-none d-lg-block d-xl-block d-xxl-block ">
            <a class="d-flex flex-column align-items-center wx-main-menu nav-link active" [routerLink]="['/certification']">
              <span class="material-icons-outlined wx-main-menu-icons">code</span>
              <p class="m-0 font-size-12">Certification</p>
            </a>
          </li> -->
          <li class="nav-item  d-lg-none xl-none d-xxl-none "  [matMenuTriggerFor]="aboveMenu" id="menuBtn">
            <i class="fa fa-bars d-flex flex-column align-items-center wx-main-menu nav-link active" style="height: 20px; margin-top:16px;" ></i>
            <p class="m-0 font-size-12">More</p>
            <mat-menu #aboveMenu="matMenu" yPosition="above">
                <button mat-menu-item>Community</button>
                <!-- <button mat-menu-item>Video Course</button> -->
                <!-- <button mat-menu-item>Certification</button> -->
                <button mat-menu-item>Apply for Job</button>
                <button mat-menu-item>Verify Certificate</button>
                <button mat-menu-item>Help Desk</button>
              </mat-menu>
          </li>
        </ul>

      </div>   