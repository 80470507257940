<div class="wx-nav-bar small-scroller overflow-auto">
  <ul>
    <li class="wx-nav-btn">
      <fa-icon [icon]="faBars"></fa-icon>
      <div class="wx-menu">
        <ul class="sub-menu-1">
          <li *ngFor="let item of list" class="d-flex arrow justify-content-between hover-me">
            {{ item.name }} <span *ngIf="item.languages.length > 0" class="material-icons arrowShow">chevron_right</span>
            <ul class="sub-menu-2">
              <li *ngFor="let v of (item.languages)" [routerLink]="'/'+v.route">{{v.name}}</li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
    <!-- Training Header Menu -->
    <li *ngFor="let routes of routelist" class="wx-nav-btn" [routerLink]="[routes.route]" routerLinkActive="active-nav"
    [routerLinkActiveOptions]="{ exact: true }">{{routes.name}}</li>
    <!-- <button *ngFor="let routes of routelist" type="button" class="btn wx-nav-btn" [routerLink]="[routes.route]" routerLinkActive="active-nav"
      [routerLinkActiveOptions]="{ exact: true }">
      {{routes.name}}
    </button> -->
  </ul>
</div>



