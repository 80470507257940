import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ISeoProps } from 'src/app/model/home/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  setTitle({ title }: ISeoProps): any {
    this.title.setTitle(title);
  }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  setMetaData({ meta }: ISeoProps): any {
    // this.meta.addTags(meta as any);
    this.meta.updateTag(meta as any);
  }

  setLink({ link }: ISeoProps): void {
    link.forEach((e: any) => {
      const l: any = this.doc.createElement('LINK');
      l.setAttribute('rel', e.name);
      l.setAttribute('href', e.content);
      this.doc.head.appendChild(l);
    });
  }

  updateCanonicalUrl(url: string) {
    const head = this.doc.getElementsByTagName('head')[0];
    var element: HTMLLinkElement | null =
      this.doc.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.doc.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
