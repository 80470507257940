import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/Api/api.service';
import { Enquiry, IEnquiry } from 'src/app/model/home/enquiry';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss'],
})
export class EnquiryComponent implements OnInit {
  // selectedTechnology = "";
  selectedLangs: string[] = [];
  technologyFlag: boolean = true;
  keywordFlag: boolean = false;
  detailsFlag: boolean = false;
  completedFlag: boolean = false;
  completedData: string;
  @Output() closeAll = new EventEmitter();
  @Output() hideCloseBtn = new EventEmitter<boolean>();
  enquiryForm!: IEnquiry;
  constructor(
    private homeService: HomeService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {}

  selectLang(value: number) {
    this.homeService.fetchKeywords(value);
    if (!this.enquiryForm) this.enquiryForm = {} as any;
    this.enquiryForm.courseId = value;
    this.enquiryForm.langId = value;
    this.activeKeyWord();
  }
  selectValues(value: string[]) {
    this.selectedLangs = value;
  }

  activeTechology(keywords: string) {
    this.enquiryForm.keywords = keywords;
    this.technologyFlag = true;
    this.keywordFlag = false;
    this.detailsFlag = false;
    this.completedFlag = false;
    this.hideCloseBtn.emit(true);
  }
  activeKeyWord() {
    this.technologyFlag = false;
    this.keywordFlag = true;
    this.detailsFlag = false;
    this.completedFlag = false;
    this.hideCloseBtn.emit(false);
  }
  activeDetails(keyWords: string) {
    this.enquiryForm.keywords = keyWords;
    this.technologyFlag = false;
    this.keywordFlag = false;
    this.detailsFlag = true;
    this.completedFlag = false;
    this.hideCloseBtn.emit(false);
  }

  activeCompleted(valeus: IEnquiry) {
    Object.assign(this.enquiryForm, valeus);
    this.completedData = String(valeus);
    this.enquiryForm.source = 'Website';
    this.enquiryForm.webFormName = 'Main';
    // this.postEnquiry(this.enquiryForm);
    this.technologyFlag = false;
    this.keywordFlag = false;
    this.detailsFlag = false;
    this.completedFlag = true;
    this.hideCloseBtn.emit(false);
  }

  postEnquiry(body: IEnquiry) {
    this.apiService
      .postCourseEnquiry(body)
      .toPromise()
      .then((value: any) => {
        this.completedData = value.message;
      })
      .catch(console.log);
  }

  closePopup() {
    this.activeTechology('');
    this.closeAll.emit();
  }
}
