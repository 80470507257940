<div style="background-color: #e1ebf6;" class="mt-5 swictToOther">
    <h3 class="py-1 text-center">Switch Technology</h3>
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-md-9">
                <div class="row mt-3 mx-0">
                    <div class="col-md-3 col-sm-3 col-5 p-0 cust-menu">
                        <ul>
                            <li *ngFor="let item of list" (mouseenter)="onMouseEnter(item)" [ngClass]="{'active': selectedCategory.categoryId === item.categoryId}"><a role="button">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-9 col-sm-9 col-7 p-0">
                        <div *ngIf="selectedCategory?.languages?.length === 0" class="m-5">
                            <p class="text-center w-100">No Languages Found!</p>
                        </div>
                        <div class="row my-3 mx-1">
                            <div class="col-lg-4 col-md-4 col-sm-6 col-6 mb-3" *ngFor="let tech of selectedCategory?.languages">
                                <div class="card-tech d-flex p-2 rounded align-items-center flex-column flex-md-row flex-sm-row flex-lg-row" [routerLink]="'/'+tech.route">
                                    <div class="img-div1 d-flex align-items-center justify-content-center">
                                        <img [src]="iconLink + tech.icon + '.svg'" alt="">
                                    </div>
                                    <p class="m-0">{{tech.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center full-view">
        <button class="btn">Full View</button>
    </div>
</div>