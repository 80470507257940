<div class="wx-nav-home-bar small-scroller">
    <div class="container-fluid d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
                <ng-container *ngFor="let item of list; let i = index;">
                    <button type="button" class="btn wx-nav-btn" [attr.indexAt]="i">
                        {{item.name}}
                        <div class="wx-menu">
                            <ul class="menu-list">
                                <ng-container *ngFor="let item of item.languages;let i = index">
                                    <li class="hover-me" [routerLink]="['/'+ item.route]" routerLinkActive="active-nav">
                                        {{ item.name }}
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </button>
                </ng-container>
            </div>
            <div class="wx-nav-btn dropdown-custom-menu">
                <fa-icon [icon]="faBars"></fa-icon>
                <div class="wx-menu">
                    <ul class="menu-list right-menu">
                        <ng-container *ngFor="let item of list; let i = index;">
                            <li class="hover-me dd-mm d-flex arrow" [attr.indexAt]="i">
                                <span *ngIf="item.languages.length > 0" class="material-icons arrowShow">chevron_left</span> {{ item.name }} 
                                <ul class="sub-menu-2">
                                    <li class="hover-me" *ngFor="let v of (item.languages)" [routerLink]="'/'+v.route">{{v.name}}</li>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>


            <!-- <button type="button" class="btn wx-nav-btn d-lg-none">
                {{list[0].name}}
                <div class="wx-menu">
                    <ul class="menu-list">
                        <ng-container *ngFor="let item of list[0].languages;">
                            <li class="hover-me " [routerLink]="['/'+ item.route]" routerLinkActive="active-nav">
                                {{ item.name }}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </button>
            
            <button type="button" class="btn wx-nav-btn d-lg-none">
                {{list[1].name}}
                <div class="wx-menu">
                    <ul class="menu-list">
                        <ng-container *ngFor="let item of list[1].languages;">
                            <li class="hover-me " [routerLink]="['/'+ item.route]" routerLinkActive="active-nav">
                                {{ item.name }}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </button> -->
        </div>
        <!-- <div class="d-flex align-items-center">
            <button type="button" class="btn   d-lg-none">
                <i class="fa fa-bars btn wx-nav-btn" >
                    <ng-container *ngFor="let item of list;">
                        <div class="wx-menu">
                            <ul class="">
                                    <li class="hover-me " >
                                        {{ item.name }}
                                        
                                        <ng-container *ngFor="let item of item.languages;let i = index">
                                            <ul >
                                                    <li class="hover-me " [routerLink]="['/'+ item.route]" routerLinkActive="active-nav">
                                                        {{ item.name }}
                                                    </li>
                                                    
                                                </ul>
                                            </ng-container>
                                    </li>
    
                            </ul>
                        </div>
    
                    </ng-container>
                </i>

            </button>
</div> -->
        <!-- <div>
            <button type="button" class="btn wx-nav-btn">
                <fa-icon [icon]="faBars"></fa-icon>
                <div class="wx-menu">
                    <ul class="sub-menu-1 rgt-sub-menu-1">
                        <ng-container *ngFor="let item of list;let i = index">
                            <li class="hover-me">
                                {{ item.name }}
                                <ul class="sub-menu-2 rgt-sub-menu-2">
                                    <li *ngFor="let v of (item.languages)">{{v.name}}</li>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </button>
        </div> -->
    </div>
</div>