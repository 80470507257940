export interface ILanguage {
    langId: number;
    name: string;
    icon: string;
    get route(): string;
    get routeToName(): string;
}
export class Language implements ILanguage {
    langId: number;
    name: string;
    icon: string;
    constructor({ langId, icon, name }: ILanguage) {
        this.langId = langId;
        this.icon = icon;
        this.name = name;
    }
    get route(): string {
        return this.name && this.name.toLowerCase().replace(/ /g, '-');
    }
    get routeToName(): string {
        return this.name && this.name.replace(/-/g, ' ');
    }
}
export interface ICategoryList {
    categoryId: number;
    name: string;
    categoryName: string;
    languages: ILanguage[];
    status: string;
    get route(): string;
    get routeToName(): string;
}
export class CategoryList implements ICategoryList {
    categoryId: number;
    name: string;
    status: string;
    languages: Array<ILanguage>;
    categoryName: string;
    constructor(categoryList: ICategoryList) {
        this.categoryId = categoryList.categoryId;
        this.name = categoryList.name;
        this.status = categoryList.status;
        this.categoryName = categoryList.categoryName || '';
        this.languages = categoryList.languages.map((lang: ILanguage)  => new Language(lang));
    }
    get route(): string {

        return this.name.replace(/ /g, '-')
    }
    get routeToName(): string {
        return this.name.replace(/-/g, ' ')
    }
}
