import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/Api/mainService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-blog',
  templateUrl: './course-blog.component.html',
  styleUrls: ['./course-blog.component.scss']
})
export class CourseBlogComponent implements OnInit {
  blogThumbsUrl = environment.blogThumbsUrl
  blogs:any = [  ];
  page =0 ;
  size= 5

  _courseRoute: any;
  constructor(
    private mainService: MainService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(async s => {
      if (s.courseName) {
        this._courseRoute = s.courseName;
        await this.getBlogs()
      }
    });
  }

  async getBlogs(){
    try{
      let data = await this.mainService.get(`/blogs/recent-blogs/${this._courseRoute}?page=${this.page}&size=${this.size}`);
      this.blogs = data.data;

    }catch(error){
    }
  }
  nextPage(){
    this.page=this.page+1
    this.getBlogs()
  }
  previousPage(){
    this.page=this.page-1
    this.getBlogs() 
  }
}
