import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-img-card',
  templateUrl: './course-img-card.component.html',
  styleUrls: ['./course-img-card.component.scss']
})
export class CourseImgCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
