import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  private _categoryDropdown: boolean = false;
  private _courseRouteName: string = '';
  private _topicName: string = '';
  constructor() { }

  get categoryDropdown() {
    return this._categoryDropdown;
  }

  get courseRouteName() {
    return this._courseRouteName;
  }

  get topicName() {
    return this._topicName;
  }

  setTopicName(topicName: string) {
    this._topicName = topicName;
  }

  setCategoryDropdown(flag: boolean) {
    this._categoryDropdown = flag;
  }

  setCourseRouteName(route: string) {
    this._courseRouteName = route;
  }
}
