<!-- <ngx-monaco-editor
  [options]="options"
  [(ngModel)]="code"
>
</ngx-monaco-editor> -->

<!-- <pre>
  <code  >
    {{code}}
  </code>
</pre> -->

<pre><code [lineNumbers]="true" [highlight]="code"></code></pre>
