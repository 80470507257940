import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  public endpoint = environment.apiUrl

  public httpsOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }),
  };

  public httpsOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `${this.cookies.get('token')}`
    }),
  }
  constructor(private https: HttpClient, private cookies: CookieService) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  get(url: any): Promise<any> {
    return this.https.get(this.endpoint + url, this.getHeaders()).toPromise();
  }

  getAuth(url: any): Promise<any> {
    return this.https.get(this.endpoint + url, this.getAuthHeaders()).toPromise();
  }

  getModuleList(url: any) {
    return this.https.get(this.endpoint + url, this.getAuthHeaders()).toPromise();
  }
  getLangList(url: any) {
    return this.https.get(this.endpoint + url, this.getAuthHeaders()).toPromise();
  }
  postAuth(url: any, body: any): Promise<any> {
    return this.https
      .post<any>(this.endpoint + url, body, this.getAuthHeaders())
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  postAuthWithoutPromise(url: any, body: any):any {
    return this.https.post<any>(this.endpoint + url, body, this.getAuthHeaders())
  }

  post(url: any, body: any): Promise<any> {
    return this.https
      .post<any>(this.endpoint + url, body, this.getHeaders())
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }
  handleError(error: any) {
    console.log(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error.error);
  }
  delete(url: any): Observable<any> {
    return this.https
      .delete<any>(this.endpoint + url, this.getHeaders())
      .pipe(map(this.extractData));
  }

  getHeaders() {
    return this.httpsOptions
  }

  getAuthHeaders() {
    return this.httpsOptionsAuth;
  }
}
