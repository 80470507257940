<div class="px-3 quiz-box">
    <div *ngIf="quizs?.content.length === 0">
        <p class="text-center w-80 mt-4" style="font-size: 13px; color: red;">Quiz Not Found!</p>
    </div>
    <div *ngFor="let data of quizs?.content" class="py-3">
        <div class="paragraph">{{data.title}}</div>
        <div class="d-flex align-items-center">
            <small class="text-muted small_text mb-1">ID: {{data.quizId}} &nbsp;|</small>
            <small class="text-muted small_text mb-1">{{(data.complexity=="L1")?"Beginner":(data.complexity=="L2")?"Medium":"Expert"}} &nbsp;|</small>
            <small class="text-muted small_text mb-1">{{data.maxNoq}} NOQ |</small>
            <small class="text-muted small_text mb-1">{{data.maxTime}} Mins</small>
        </div>
        <div>
            <div class="d-flex align-items-center justify-content-between">
                <div class="strat_btn">
                    <button type="submit"  (click)="routeToQuizPage(data)" class="btn btn-success"> Start</button>
                </div>
            <div class="d-flex">
                <div class="ratings">
                    <div class="empty-stars"></div>
                    <div class="full-stars" [style.width]="(data.rating || 0)*20+'%'"></div>
                </div>
                <span>{{data.rating}} ({{(data.ratingCount==0)?0:data.ratingCount | shortNumber}})</span>           </div>
            </div>
        </div>
    </div>
    <div *ngIf="quizs?.content.length !== 0" class="text-center mb-3" style="border: none;" >
        <button class="orange" style="margin-right:10px" [disabled]="quizs?.first"  (click)="previousPage()"><</button>
        <button class="orange ml-2" [disabled]="quizs?.last"  (click)="nextPage()">></button> 
    </div>
</div>