import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ILangKeywords } from 'src/app/model/home/common';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
})
export class KeywordsComponent implements OnInit {
  @Output() activeDetails = new EventEmitter<string>();
  @Output() activeTechology = new EventEmitter<string>();
  showMessage: boolean = false;

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {}

  get langKeywords(): ILangKeywords[] {
    return this.homeService.langKeywords;
  }
  next() {
    const keys: number[] = this.langKeywords
      .filter((key: ILangKeywords) => key.flag)
      .map((key: ILangKeywords) => key.keywordId);
    if (keys.length > 0) {
      this.activeDetails.emit(JSON.stringify(keys));
    } else {
      this.showMessage = true;
    }
  }
  checked(event: Event, item: ILangKeywords) {
    this.showMessage = false;
    item.flag = (event.target as any).checked;
  }
  back() {
    this.activeTechology.emit('');
  }
}
