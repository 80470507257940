<div class="card h-100">
  <img class="card-img-top" [src]="blogUrl+ blog?.image" alt="">
  <div class="card-body">
    <div>
      <a [routerLink]="[ '/'+blog?.langName+'/blog', blog?.urlTitle]">
        <div class="card-title elipsis-para elipsis-2" [innerHTML]="blog?.title"></div>
      </a>
      <p class="card-text paragraph elipsis-para elipsis-2" [innerHTML]="blog?.summary"></p>
    </div>
    <div class="d-flex pt-4 card-bottum align-items-start ">
      <div> {{blog?.author}} | &nbsp;</div>
      <div class="m-0">{{blog?.publishedDate | date:'mediumDate'}}</div>
      <!-- <span class="material-icons mr-4">favorite_border</span> -->
    </div>
  </div>
</div>