<div class="container">
    <div class="col-12 pl-0">
        <div class="row">
            <div class="col-3" style="height:448px;background-color: #ccc;padding-top: 25px;">
                <div class="position-relative py-5">
                    <div class="ball bg-ch"></div>
                    <div class="line bg-ch"></div>
                    <span class="text-form">Select Technology</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball bg-ch"></div>
                    <div class="line bg-ch"></div>
                    <span class="text-form">Select Keywords</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball bg-ch"></div>
                    <div class="line bg-ch"></div>
                    <span class="text-form">Fill Details</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball bg-ch"></div>
                    <span class="text-form">Completed</span>
                </div>
            </div>
            <div class="col-9">
                <div class="completed-div">
                    <div class="d-flex align-items-center">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flat_tick_icon.svg/1200px-Flat_tick_icon.svg.png" alt="">
                        <h6>{{message}}</h6>
                    </div>
                    <div class="text-center mt-4">

                        <a href="training" class="bourse-course">Bourse Course</a>
                        <!-- <button class="bourse-course">Bourse Course</button> -->
                        <!-- <img style="width: 150px;" src="https://st.depositphotos.com/1967477/2542/v/950/depositphotos_25421271-stock-illustration-illustration-of-a-boy-with.jpg" alt=""> -->
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-end mt-2">
                    <button (click)="close()">Close</button>
                </div> -->
            </div>
        </div>

    </div>
</div>