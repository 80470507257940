
<div [ngClass]="!offCanvas ? 'd-none d-md-block d-lg-block': ''">
    <div class="lang_header" *ngIf="!offCanvas">
        <p class="m-0 mx-2">Switch</p>
    </div>
    <div class="container" [ngClass]="!offCanvas ? 'lang-box': ''">
        <div class="row">
            <div *ngIf="lLCR?.length === 0" class="mt-4">
                <p class="text-center w-80" style="font-size: 13px; color: red;">Nothing to switch in this category!</p>
            </div>
            <div *ngFor="let l of lLCR" class="lLCR d-flex justify-content-center align-items-center flex-column">
                <a [routerLink]="['/'+ l.route]" [title]="l.name" class="link-r d-inline-block">
                    <div class="text-center">
                        <img [src]="icon+l.icon+'.svg'" alt="">
                    </div>
                    <div class="text-center">
                        <div class="lang-name">{{l.name}}</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

    <!-- <div class="d-flex flex-wrap align-items-center justify-content-center mb-2">
        <div *ngFor="let l of lLCR" class="lLCR">
            <a [routerLink]="['/'+ l.route]" [title]="l.name" class="link-r">
                <div class="text-center">
                    <img [src]="icon+l.icon+'.svg'" alt="">
                </div>
                <div class="text-center">
                    <p class="name-lang paragraph mt-2" style="color: #212529;">{{l.name}}</p>
                </div>
            </a>
        </div>
    </div> -->

