import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Router, Event, NavigationEnd } from '@angular/router';
import { SeoService } from 'src/app/home/services/seo.service';
declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tehnical99.com';
  activatedRoute: any;
  seoService: SeoService;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router // private titleService: Title
  ) // private metaTagService: Meta
  {
    const navEndEvent$ = router.events.pipe(
      filter(
        (event: Event): event is NavigationEnd => event instanceof NavigationEnd
      )
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'UA-130030083-1', { page_path: e.urlAfterRedirects });
    });
  }
  windowScrolled: boolean = false;
  arrow = faArrowUp;

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        this.seoService.updateTitle(event['title']);
        // this.seoService.updateOgUrl(event['ogUrl']);
        // this.seoService.updateDescription(event['title'] + event['description'])
      });
  }
  // ngOnInit() {
  // this.titleService.setTitle(this.title);
  // this.metaTagService.addTags([
  //   { name: 'keywords', content: 'Angular SEO Integration, Music CRUD, Angular Universal', },
  //   { name: 'robots', content: 'index, follow' },
  //   { name: 'author', content: 'Digamber Singh' },
  //   { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  //   { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
  //   { charset: 'UTF-8' },
  // ]);
  // }

  // constructor(
  //   @Inject(DOCUMENT) private document: Document,
  //   private router: Router,
  //   private titleService: Title,
  //   private metaTagService: Meta
  //   ) {
  //var userDetails = localStorage.getItem('user');
  // if(userDetails != null){
  //  this.router.navigate(['/user/dashboard']);
  // } else {
  //   this.router.navigate(['/']);
  // }
}

// @HostListener("window:scroll", [])
// onWindowScroll() {
//   if (this.document.documentElement.scrollTop || this.document.body.scrollTop > 100) {
//       this.windowScrolled = true;
//   }
//  else if (this.windowScrolled && this.document.documentElement.scrollTop || this.document.body.scrollTop < 10) {
//       this.windowScrolled = false;
//   }
// }
//scrollToTop() {
// this.document.body.scrollIntoView();
//}

// }
