<div class="container">
    <div class="col-12 pl-0">

        <div class="row">
            <div class="col-3" style="height:448px;background-color: #ccc;padding-top: 25px;">
                <div class="position-relative py-5">
                    <div class="ball bg-ch"></div>
                    <div class="line bg-ch"></div>
                    <span class="text-form">Technology</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball"></div>
                    <div class="line"></div>
                    <span class="text-form">Keywords</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball"></div>
                    <div class="line"></div>
                    <span class="text-form">Fill Details</span>
                </div>
                <div class="position-relative py-5">
                    <div class="ball"></div>
                    <span class="text-form">Completed</span>
                </div>
            </div>
            <div class="col-9 mt-2" style="border-radius: 4px;">
                <div class="checkbox-list">
                    <div class="row checkbox-container-height" [ngClass]="{'scroll-container':langKeywords.length > 0}">
                        <div class="col-6" *ngFor="let item of langKeywords;let i=index">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [value]="item.keywordId" (change)="checked($event, item)" [id]="'flexCheckDefault'+i" [checked]="item.flag">
                                <label class="form-check-label" [for]="'flexCheckDefault'+i">{{item.keyword}}</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="d-flex" style="margin-bottom: 8px;" [ngClass]="showMessage? 'justify-content-between' : 'justify-content-end'">
                    <div style="padding-top: 8px;padding-left: 22px;">
                        <span class="text-danger" *ngIf="showMessage && langKeywords.length > 0">Select atleast one technology!</span>
                    </div>
                    <div>
                        <button class="mr-2" style="border: 1px solid #ccc;" (click)="back()">Back</button>
                        <button (click)="next()">Continue</button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>