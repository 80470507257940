import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowAltCircleLeft,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/user/auth.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from 'angularx-social-login';
declare var google: any;
@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
})
export class LoginPopupComponent implements OnInit {
  userIcon = faUser;
  googleIcon = faGoogle;
  closeIcon = faTimes;
  facebookIcon = faFacebook;
  otpFlag: boolean = false;
  passwordFlag: boolean = false;
  emailORmobile: string = '';
  mobileNo: any;
  otpMessage: any;
  password: string = '';
  otp: string = '';
  error: string = '';
  arrowLeft = faArrowAltCircleLeft;
  emailormobileFlag = false;
  signinErrorFlag = false;
  signinErrorMsg = '';
  signinSuccessMsg = '';
  spinner = faSpinner;
  mobileEmail: boolean = true;
  continueFlag = false;
  getOTPSpinnerFlag = false;
  loginSpinnerFlag = false;
  separateDialCode = false;
  otpVerified = false;
  forgetPasswordFlag: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  numberObj: any;
  otpERRMessage = '';
  rePassword = '';
  confirmPassword = '';
  updatePassowrdSuccess: string = '';
  updatePassowrdError: string = '';
  isPasswordUpdated = false;
  @Output() close = new EventEmitter();
  @Output() openSignUpPopUp = new EventEmitter();
  loginForm: FormGroup | undefined;
  socialUser!: SocialUser;
  // socialUser: import('angularx-social-login').SocialUser;
  isLoggedin: boolean = false;
  user: SocialUser;
  loggedIn: boolean;
  google: any;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private socialAuthService: SocialAuthService,
    private router: Router
  ) {}
  // handleCredentialResponse = () => {
  //   alert('sign in successfull');
  // };

  ngOnInit() {
    // google.accounts.id.initialize({
    //   client_id:
    //     '550227808003-lce74crqsje5la65ms6p3ej11m61eblh.apps.googleusercontent.com',
    //   callback: this.handleCredentialResponse,
    // });
    // google.accounts.id.renderButton(
    //   document.getElementById('buttonDiv'),
    //   { theme: 'outline', size: 'large' } // customization attributes
    // );
    // google.accounts.id.prompt();

    google.accounts.id.initialize({
      client_id:
        '550227808003-lce74crqsje5la65ms6p3ej11m61eblh.apps.googleusercontent.com',
      callback: this.handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', size: 'large' } // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog

    // this.socialAuthService.authState.subscribe((user) => {
    //   this.user = user;
    //   this.loggedIn = user != null;
    // });
  }
  handleCredentialResponse(response: any) {
    console.log('Encoded JWT ID token: ' + response.credential);
  }

  // ngOnInit(): void {
  //   this.loginForm = this.formBuilder.group({
  //     email: ['', Validators.required],
  //     password: ['', Validators.required],
  //   });

  // this.socialAuthService.authState.subscribe((user) => {
  //   this.socialUser = user;
  //   this.isLoggedin = user != null;
  //   console.log(this.socialUser);
  // });
  // }

  loginWithGoogle(): void {
    // this.socialAuthService
    //   .signIn(GoogleLoginProvider.PROVIDER_ID)
    //   .then((x) => console.log(x))
    //   .catch((e) => alert(e.message));
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  // Will use it for logout
  logOut(): void {
    this.socialAuthService.signOut();
  }

  onNumberChange($event: any) {
    if ($event) {
      this.emailORmobile = String($event.internationalNumber)
        .split(' ')
        .join('')
        .replace('+', '');
    } else {
      this.emailORmobile = '';
    }
  }

  signUpPopUp() {
    this.openSignUpPopUp.emit();
  }

  mobileNum() {
    this.mobileEmail = !this.mobileEmail;
    this.emailORmobile = '';
  }

  selectOTPOption() {
    this.otpFlag = true;
    this.passwordFlag = false;
    this.forgetPasswordFlag = false;
  }

  selectPasswordOption() {
    this.otpFlag = false;
    this.passwordFlag = true;
    this.forgetPasswordFlag = false;
  }

  selectForgotPassword() {
    this.otpFlag = false;
    this.passwordFlag = false;
    this.forgetPasswordFlag = true;
  }

  async verifyOTP() {
    this.isPasswordUpdated = false;

    this.rePassword = '';
    this.confirmPassword = '';
    try {
      this.otpVerified = false;

      let res = await this.authService.varifyOTP(this.mobileNo, this.otp);
      if (res) {
        if (res.status) {
          this.otpMessage = res.message;
          this.otpVerified = true;
          this.otpERRMessage = '';
        } else {
          this.otpERRMessage = res.message;
          this.otpVerified = false;
          this.otpMessage = '';
        }
      }
    } catch (eroro) {
      this.otpERRMessage = 'failed to send otp';
      this.otpVerified = false;
      this.otpMessage = '';
    }
  }

  async updatePassword() {
    this.updatePassowrdSuccess = '';
    this.updatePassowrdError = '';
    this.isPasswordUpdated = false;
    if (
      this.otpVerified &&
      this.rePassword &&
      this.confirmPassword &&
      this.rePassword == this.confirmPassword
    ) {
      try {
        let res: any = await this.authService.updatePassword(
          this.emailORmobile,
          this.rePassword
        );
        if (res.status == 200) {
          this.updatePassowrdSuccess = res.data;
          // this.close.emit();
          // setTimeout(()=>{
          // }, 1000);
          this.isPasswordUpdated = true;
        } else {
          this.updatePassowrdError = res.message;
        }
      } catch (error: any) {
        this.updatePassowrdError = error.message;
      }
    }
  }

  async resendOTP() {
    // resendOTPLogin
    try {
      this.otpMessage = '';
      let res = await this.authService.resendOTPLogin(this.mobileNo);
      if (res && res.data) {
        this.otpMessage = res.message;
      } else {
        this.otpERRMessage = 'failed to re-send OTP';
      }
    } catch (error) {
      this.otpERRMessage = 'failed to re-send OTP';
    }
  }

  // getOTP() {
  //   if(this.emailORmobile) {
  //     let value = this.emailORmobile;
  //     if (this.emailORmobile.length === 10 && parseInt(this.emailORmobile)) {
  //       value = this.emailORmobile;
  //     }
  //     this.getOTPSpinnerFlag = true;
  //     this.authService.resendOTP(this.mobileNo)
  //     .then((res: any) => {
  //       if (!res.success) {
  //         this.error = res.message;
  //         this.getOTPSpinnerFlag = false;
  //       } else {
  //         this.otpFlag = true;
  //         this.error = "";
  //         this.passwordFlag = false;
  //         if (this.emailORmobile.length === 10 && parseInt(this.emailORmobile)) {
  //           this.emailORmobile = this.emailORmobile;
  //         }
  //         this.getOTPSpinnerFlag = false;
  //       }
  //     }).catch(console.error);
  //   }
  // }

  async requestForOTP() {
    try {
      let res: any = await this.authService.requestForOTP(this.emailORmobile);
      if (res && res.data) {
        this.mobileNo = res.userName;
        this.otpMessage = res.message;
      } else {
        this.otpERRMessage = 'failed to send otp';
      }
    } catch (error) {
      this.otpERRMessage = 'failed to send otp';
    }
  }
  continue() {
    if (this.emailORmobile.length === 0) {
      this.emailormobileFlag = true;
      return;
    }
    this.continueFlag = true;
    let value = this.emailORmobile;
    if (this.emailORmobile.length === 10 && parseInt(this.emailORmobile)) {
      value = this.emailORmobile;
    }
    this.emailormobileFlag = false;
    this.authService
      .isUserExist(value)
      .then((r: any) => {
        this.error = '';
        this.otpFlag = false;
        this.continueFlag = false;
        this.passwordFlag = true;
        if (this.emailORmobile.length === 10 && parseInt(this.emailORmobile)) {
          this.emailORmobile = this.emailORmobile;
        }
      })
      .catch((err: any) => {
        this.error = err.error.message;
        this.continueFlag = false;
      });
  }
  login() {
    if (!this.otpFlag && this.passwordFlag) {
      this.loginByPassword();
    } else if (this.otpFlag && !this.passwordFlag) {
      this.loginByOTP();
    }
  }
  closePopup() {
    this.close.emit();
  }

  check(event: any): void {
    if (event.target.value.length === 0) {
      this.error = 'Please Enter valid Input';
    } else {
      this.error = '';
    }
  }
  async loginByPassword() {
    if (this.password.length !== 0) {
      this.loginSpinnerFlag = true;
      await this.authService
        .loginByPassword(this.emailORmobile, this.password)
        .then((res: any) => {
          console.log('login details', res);
          this.closePopup();
          location.reload();
        })
        .catch((err: any) => {
          console.log(err);
          this.error = 'Password is Wrong!';
        });
      this.loginSpinnerFlag = false;
    }
  }

  onPress(event: any) {
    if (this.otp.length == 4) {
      this.verifyOTP();
    }
  }

  async loginByOTP() {
    if (this.emailORmobile.length === 0) {
      this.emailormobileFlag = true;
      return;
    }
    this.emailormobileFlag = false;
    this.loginSpinnerFlag = true;
    await this.authService
      .loginByOtp(this.mobileNo, this.otp)
      .then(() => {
        this.closePopup();
        location.reload();
      })
      .catch((err: any) => {
        if (err.status === '400') {
          this.signinErrorFlag = true;
          this.signinErrorMsg = err.error;
        }
      });
    this.loginSpinnerFlag = false;
  }

  back() {
    this.otpFlag = false;
    this.passwordFlag = false;
    this.forgetPasswordFlag = false;
  }
  hideSignupMessage(): void {
    this.signinErrorFlag = false;
    this.signinErrorMsg = '';
    this.signinSuccessMsg = '';
  }
}
