import { Details, IDetails } from "./common";

export interface IOwner {
  userId: number
  name: string;
}

export class Owner {
  userId: number
  name: string;

  constructor({ name, userId }: IOwner) {
    this.name = name;
    this.userId = userId;
  }
}
export interface ISelfpacedCourses {
  courseId: number;
  title: string;
  courseName: string;
  price: number;
  payable: number;
  rating: number;
  ratingCount: number;
  image: string;
  discount: number;
  owner: IOwner;
}
export interface IPrimePackCourses {
  primePackId: number;
  title: string;
  primePackName: string;
  noc: number;
  duration: number;
  price: number;
  payable: number;
  discount: number;
  learners: number;
  image: string;
  owner: string;
  ownerId: number;
}

export class SelfpacedCourses implements ISelfpacedCourses {
  courseId: number;
  title: string;
  courseName: string;
  price: number;
  rating: number;
  ratingCount: number;
  image: string;
  owner: IOwner;
  payable: number;
  discount: number;
  constructor(props: ISelfpacedCourses) {
    this.courseId = props.courseId;
    this.title = props.title;
    this.courseName = props.courseName;
    this.price = props.price;
    this.rating = props.rating;
    this.ratingCount = props.ratingCount;
    this.image = props.image;
    this.payable = props.payable;
    this.discount = props.discount;
    this.owner = new Owner(props.owner);
  }
}
export class PrimePackCourses implements IPrimePackCourses {
  primePackId: number;
  title: string;
  primePackName: string;
  noc: number;
  duration: number;
  price: number;
  payable: number;
  discount: number;
  learners: number;
  image: string;
  owner: string;
  ownerId: number;
  constructor(props: IPrimePackCourses) {
    this.primePackId = props.primePackId;
    this.title = props.title;
    this.primePackName = props.primePackName;
    this.noc = props.noc;
    this.duration = props.duration;
    this.learners = props.learners;
    this.owner = props.owner;
    this.ownerId = props.ownerId;
    this.price = props.price;
    this.image = props.image;
    this.payable = props.payable;
    this.discount = props.discount;
  }
}

export interface ISelfpacedCourseDetails extends IDetails {
  courseId: number;
  price: number;
  tag: string;
  image: string;
  createdDate: string;
  updatedDate: string;
  owner: IOwner;
}

export class SelfpacedCourseDetails extends Details implements ISelfpacedCourseDetails {
  courseId: number;
  price: number;
  tag: string;
  image: string;
  createdDate: string;
  updatedDate: string;
  owner: IOwner;
  constructor(props: ISelfpacedCourseDetails) {
    super(props);
    this.courseId = props.courseId;
    this.price = props.price;
    this.tag = props.tag;
    this.image = props.image;
    this.createdDate = props.createdDate;
    this.updatedDate = props.updatedDate;
    this.owner = new Owner(props.owner);
  }
}
