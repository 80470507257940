<div class="row">
  <div class="col-12">
    <ng-container *ngIf="!educationFlag">
      <div class="px-4 py-2 w-80" style="background-color: #ffffff; border-radius: 4px;">
        <div class="d-flex align-items-center justify-content-between">
          <div class="heading_section d-flex align-items-center">
            <fa-icon class="fs-3 me-3" [icon]="userIcon"></fa-icon>
            <h5 class="m-0">SignUp</h5>
          </div>
          <button type="button" class="btn-close c-pointer" style="margin-right:-15px;margin-top:-10px;" data-bs-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
        </div>
        <div class="">
          <small class="text-danger text-small">{{signUpErrorMessage}}</small>
        </div>
        <div>
          <ng-container *ngIf="!otpFlag">
            <form [formGroup]="signupFormGroup" (ngSubmit)="createAccount()">
              <div class="invalid-feedback is-invalid">Enter full name</div>
              <div class="input-group mt-3 mb-2">
                <input type="text" formControlName="name" class="form-control ng-invalid" (keydown)="keypressAlphabats($event)" [ngClass]="isValid('name') ? 'is-invalid' : ''" placeholder="Full Name">
                <!-- <div class="invalid-feedback">Enter full name</div> -->
              </div>
              <div class="d-flex w-100">
                <!-- <div>
                  <select class="form-select mb-3 me-2" formControlName="countryCode" style="width: 80px;height:50px;">
                    <option selected *ngFor="let country of countryCodes" value="{{country?.dial_code}}">
                      {{country?.code}}-{{country?.name}}</option>
                  </select>
                </div>
                <div class="mb-3 px-2 d-flex align-items-center">{{signupFormGroup?.controls?.countryCode?.value}}</div>
                <div class="input-group mb-3">
                  <input type="text" maxlength="10" max="10" min="10" minlength="10" formControlName="mobile" (keydown)="keypressNumber($event)" [ngClass]="isValid('mobile') ? 'is-invalid' : ''" class="form-control" placeholder="Mobile" required="true">
                  <div class="invalid-feedback">Enter mobile number</div>
                </div> -->
                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates]" [phoneValidation]="true" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="12" [separateDialCode]="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="numberObj" name="numberObj" (ngModelChange)="onNumberChange($event)">
                </ngx-intl-tel-input>
              </div>
              <div class="input-group mb-2">
                <input type="email" formControlName="email" [ngClass]="isValid('email') ? 'is-invalid' : ''" class="form-control" placeholder="Email ID">
                <!-- <div class="invalid-feedback">Enter valid email</div> -->
              </div>
              <div class="d-flex justify-content-center my-4">
                <div class="form-check me-2">
                  <input class="form-check-input" value="Student" formControlName="profession" type="radio" id="student">
                  <label class="form-check-label mr-3" for="student">Student</label>
                </div>
                <div class="form-check me-2">
                  <input class="form-check-input" value="Working" formControlName="profession" type="radio" id="working">
                  <label class="form-check-label mr-3" for="working">Working</label>
                </div>
                <div class="form-check me-2">
                  <input class="form-check-input" value="JobSeeker" formControlName="profession" type="radio" id="jobSeeker">
                  <label class="form-check-label" for="jobSeeker">Job Seeker</label>
                </div>
              </div>
              <div class="mb-2">
                <button type="submit" class="btn-continue" (click)="createAccount()" [disabled]="!signupFormGroup.valid" [ngClass]="{'light-btn':signupFormGroup.valid, 'light-btn-s':!signupFormGroup.valid}">
                  <div class="d-flex align-items-center justify-content-center">Continue
                    <div class="spinner-icon ms-2" *ngIf="spinnerFlag">
                      <fa-icon [icon]="spinner"></fa-icon>
                    </div>
                  </div>
                </button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="otpFlag">
            <form [formGroup]="otpFormGroup" (ngSubmit)="verifyAccount()">
              <div class="input-group mb-3 mt-3">
                <input type="password" formControlName="password" placeholder="Enter Password" class="form-control">
                <div *ngIf="!f?.password?.valid && (f?.password?.touched  ) ">
                  <small class="text-danger text-small" *ngIf="f?.password?.errors?.minlength">Minimum 6 charachters</small>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="password" formControlName="c_password" placeholder="Confirm Password" class="form-control">
                <div *ngIf="!f?.c_password?.valid && (f?.c_password?.touched || f?.c_password?.dirty)">
                  <small class="text-danger" *ngIf="f?.c_password?.errors?.required || f?.c_password?.errors?.confirmedValidator">Not matched</small>
                </div>
              </div>
              <div class="d-flex mt-5 mb-5">
                <button type="button" [disabled]="btnDisable || !otpFormGroup.valid" [ngClass]="{'light-btn ':!(btnDisable || !otpFormGroup.valid), 'light-btn-s': btnDisable || !otpFormGroup.valid}" class="sign-btn my-2" (click)="signupUser()">Create Account</button>
              </div>
            </form>
          </ng-container>
          <!-- <ng-container *ngIf="otpFlag">
            <form [formGroup]="otpFormGroup" (ngSubmit)="verifyAccount()">
              <div class="d-flex align-items-center justify-content-between">
                <div class="toast" [ngClass]="signupErrorFlag ? signupErrorMsg ? 'bg-danger show' : 'bg-primary show': ''">
                  <div class="d-flex">
                    <div class="toast-body text-white">
                      {{signupErrorMsg || signupSuccessMsg}}
                    </div>
                    <button type="button" class="btn-close me-2 m-auto text-white" (click)="hideSignupMessage()" data-bs-dismiss="toast" aria-label="Close"></button>
                  </div>
                </div>
                <p class="paragraph">OTP sent to {{signupFormGroup.value.email}}, Enter OTP and verify the same.</p>
              </div>
              <div class="input-group mb-1">
                <input type="text" formControlName="otp" maxlength="4" max="4" class="form-control" autofocus [(ngModel)]="otp_input" placeholder="OTP" (input)="onPress($event)">
              </div>
              <div class="d-flex justify-content-between">
                <div style="float: left; ">
                  <small *ngIf="!message && loadingFlag">OTP Verifying... </small>
                  <small *ngIf="message" class="opt-text">{{message}}</small>
                </div>
                <div style="float: right; " *ngIf="!otpResentMessage">
                  <a href="javascript:void(0)" class="paragraph c-pointer text-primary" style="float: right;" (click)="resend()">Resend OTP</a>
                </div>
                <div style="float: right; " *ngIf="otpResentMessage">
                  <a href="javascript:void(0)" class="paragraph c-pointer text-success" style="float: right;">Resent...</a>
                </div>
              </div>
              <div class="input-group mb-3 mt-3">

                <input type="password" formControlName="password" placeholder="Enter Password" class="form-control">
                <div *ngIf="!f?.password?.valid && (f?.password?.touched  ) ">
                  <small class="text-danger text-small" *ngIf="f?.password?.errors?.minlength">Minimum 6 charachters</small>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="password" formControlName="c_password" placeholder="Confirm Password" class="form-control">
                <div *ngIf="!f?.c_password?.valid && (f?.c_password?.touched || f?.c_password?.dirty)">
                  <small class="text-danger" *ngIf="f?.c_password?.errors?.required || f?.c_password?.errors?.confirmedValidator">Not matched</small>
                </div>
              </div>
              <div class="d-flex mt-5 mx-4 mb-2 px-4">

                <button type="button" [disabled]="btnDisable || !otpFormGroup.valid" [ngClass]="{'light-btn ':!(btnDisable || !otpFormGroup.valid), 'light-btn-s': btnDisable || !otpFormGroup.valid}" class="sign-btn my-2" (click)="signupUser()">Create Account</button>
              </div>
            </form>
          </ng-container> -->
          <p class="paragraph text-center py-2 mb-2">Already have an account?&nbsp;&nbsp;<a href="javascript:void(0)" class="c-pointer" (click)="loginPopUp()">SignIn</a></p>
          <p class="font-size-11 text-center">Note: By continuing and signing in, you agree to technical99's <a href="#" class="txt_warning">Term & Conditions</a> and <a href="#" class="txt_warning">Privacy Policy</a></p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="educationFlag">
      <app-profile-education-form (openLoginPopup)="loginPopUp()" [name]="this.signupFormGroup.value && this.signupFormGroup.value.name" [userId]="userId"></app-profile-education-form>
    </ng-container>
  </div>
</div>
<div class="toast-container position-absolute p-3 top-0 end-0" id="toastPlacement">
  <div class="toast" [ngClass]="signupErrorFlag ? signupErrorMsg ? 'bg-danger show' : 'bg-primary show': ''">
    <div class="d-flex">
      <div class="toast-body text-white">
        {{signupErrorMsg || signupSuccessMsg}}
      </div>
      <button type="button" class="btn-close me-2 m-auto text-white" (click)="hideSignupMessage()" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</div>