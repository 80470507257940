import { Component, OnInit, ViewChild } from '@angular/core';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/Api/common.service';
import { AuthService } from 'src/app/user/auth.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupComponent } from 'src/app/language/components/login-popup/login-popup.component';
declare var $: any;

@Component({
  selector: 'app-page-breadcrumbs',
  templateUrl: './page-breadcrumbs.component.html',
  styleUrls: ['./page-breadcrumbs.component.scss']
})
export class PageBreadcrumbsComponent implements OnInit {

  listOfLogin: any[] = [
    { name: 'Home', route: '/user/home' },
    { name: 'Dashboard', route: '/user/dashboard' },
    { name: 'User Profile', route: '/user/profile' },
    { name: "My Course", route: '/user/my-course' },
    { name: "Quiz Results", route: '/user/quiz-results' },
    { name: "Bookmarks", route: '/user/bookmark' },
    { name: "My Subscription", route: '/subscription-plan' },
    { name: "My Contribution", route: '/my-contribution' },
    { name: "Write Review", route: '/write-review' },
    { name: "Help - Create Ticket", route: '/help-create-ticket' },
    { name: "Invite Friends", route: 'invite-friend' },
    { name: "Refer and Earn", route: 'refer-and-earn' },
    { name: "Change Password", route: 'change-password' }
  ];
  closeIcon: boolean = true;
  onlineTrainIcon = onlineTrainIcon;
  powerIcon = faPowerOff;
  isSearch : boolean=true;
  isCollapsed : boolean=true;
 

  constructor(private commonService: CommonService, private authService: AuthService ) {}
    userUrl = environment.userUrl;

    @ViewChild('closePopup') closePopup: any;
 
  ngOnInit(): void {
  }

  get categoryDropdown() {
    return this.commonService.categoryDropdown;
  }

  openSignUpPopUp() {
    $("#loginModal").modal('hide');
    $("#signUpModal").modal('show');
  }

  openLoginPopUp() {
    $("#signUpModal").modal('hide');
    $("#loginModal").modal('show');
  }


popupClose(){
    $("#loginModal").modal('hide');
  }
  
toggleCollapse(){
  this.isCollapsed = !this.isCollapsed;
}

  get isLogedIn(): boolean {
    return this.authService.currentUserFlag;
  }

  get user(): any {
    return this.authService.currentUser;
  }
  logout() {
    localStorage.clear();
    this.authService.logout();
  }

  hideBtn(event: boolean) {
    this.closeIcon = event;
  }

 


}

const onlineTrainIcon ='/assets/icons/training.png';

