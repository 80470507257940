import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-code-viewer',
  templateUrl: './code-viewer.component.html',
  styleUrls: ['./code-viewer.component.scss']
})
export class CodeViewerComponent implements OnInit {
  @Input() code: any = '';
  @Input() options:any =  {};
  @Input() codeModal: any = {};
  themeFlag = false;

  constructor() { }

  ngOnInit(): void {
    Object.assign(this.options, {minimap: {enabled: false}, scrollbar: {
      vertical: 'hidden', trimAutoWhitespace: true, automaticLayout: true, scrollBeyondLastLine: false
  },})
  }

}
