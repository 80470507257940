<!-- <app-header></app-header> -->
<router-outlet>
  <!-- <ng-adsense
    [adClient]="'ca-pub-8535817066678874'"
    [adSlot]="8944439968"
    [display]="'inline-block'"
    [width]="320"
    [height]="108">
  </ng-adsense> -->
</router-outlet>

<!-- <app-footer></app-footer> -->
<!-- <div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button class="btn btn-primary" (click)="scrollToTop()">
    <fa-icon [icon]="arrow"></fa-icon>
  </button>
</div> -->
