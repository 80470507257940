import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCircle, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/Api/common.service';
import { ITopic, ITree } from 'src/app/model/language/tree';
import { TrainingService } from 'src/app/training/services/training.service';

@Component({
  selector: 'app-course-tree',
  templateUrl: './course-tree.component.html',
  styleUrls: ['./course-tree.component.scss'],
})
export class CourseTreeComponent implements OnInit {
  plusSquare = faPlus;
  minusSquare = faMinus;
  fullpoint = faCircle;

  @Input() navTitle = '';
  @Input() navLogo = '';

  constructor(
    private route: ActivatedRoute,
    private trainingService: TrainingService,
    private commonService: CommonService
  ) {}

  replceDash(name: any) {
    return name.replaceAll('-', ' ');
  }

  ngOnInit() {}

  get nodes(): ITree[] {
    return this.trainingService.courseTree;
  }

  get isExpandTree(): boolean {
    return this.trainingService.isExpandAll;
  }

  get courseName(): string {
    return '/' + this.commonService.courseRouteName || '';
  }

  collapseAll() {
    this.trainingService.setExpandAll();
    this.trainingService.expandAll();
  }
}
