import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/Api/api.service';
import { CommonService } from 'src/app/Api/common.service';
import { TutorialService } from 'src/app/language/services/tutorial.service';
import { Category, ICategory } from 'src/app/model/category';
import {
  CategoryList,
  ICategoryList,
  ILanguage,
  Language,
} from 'src/app/model/categoryList';
import { Blog, IBlog } from 'src/app/model/home/blog';
import { ILangKeywords, LangKeywords } from 'src/app/model/home/common';
import { IEnquiry } from 'src/app/model/home/enquiry';
import { ILangTech, LangTech } from 'src/app/model/home/lang-tech';
import { IModule, Module } from 'src/app/model/home/module-list';
import { IReview, Review } from 'src/app/model/home/review';
import { IWriter } from 'src/app/model/home/writer';
import { CourseMenu, ICourseMenu } from 'src/app/model/training/common';
import { TrainingService } from 'src/app/training/services/training.service';

@Injectable()
export class HomeService {
  private _listWithLanguage: ICategoryList[] = [];
  private _categoryLangs: ICategoryList[] = [];
  private _category: ICategory[] = [];
  private _reviewList: IReview[] = [];
  private _courseMenuList: ICourseMenu[] = [];
  private _blogList: IBlog[] = [];
  private _langKeywords: ILangKeywords[] = [];
  private _langTechnologies: ILangTech[] = [];
  private _modules: IModule[] = [];
  private _modulesByLangName: IModule[] = [];
  private _homeTechs: ILanguage[] = [];
  private _aboutLang: any;
  constructor(
    private apiService: ApiService,
    private trainingService: TrainingService,
    private commonService: CommonService,
    private tutorialService: TutorialService
  ) {}

  get category(): ICategory[] {
    return this._category;
  }

  fetchCategory(): void {
    if (this._category.length === 0) {
      this.apiService.category
        .pipe(map((v: any) => v.data.map((n: ICategory) => new Category(n))))
        .toPromise()
        .then((data) => (this._category = data))
        .catch((err) => console.log(err));
    }
  }

  get listWithLanguage() {
    return this._listWithLanguage;
  }

  fetchKeywords(id: number): void {
    this._langKeywords = [];
    this.apiService
      .langKeywords(id)
      .pipe(
        map((keys: any) =>
          keys.data.map((key: ILangKeywords) => new LangKeywords(key))
        )
      )
      .toPromise()
      .then((keys: ILangKeywords[]) => (this._langKeywords = keys))
      .catch(console.log);
  }

  postEnquiry(body: IEnquiry) {
    this.apiService
      .postCourseEnquiry(body)
      .toPromise()
      .then((value: any) => {
        console.log(value);
      })
      .catch(console.log);
  }

  get langKeywords(): ILangKeywords[] {
    return this._langKeywords;
  }

  fetchListWithLanguage() {
    if (this._listWithLanguage.length === 0) {
      this.apiService
        .categoryListWithLanguage()
        .pipe(
          map((v: any) => v.map((v1: ICategoryList) => new CategoryList(v1)))
        )
        .toPromise()
        .then((data) => {
          this._listWithLanguage = data;
          const langName = this.commonService.courseRouteName;
          this.setTreeFromLanguage({ langName });
          this.setQustionTree(langName);
          this.setLangBoxImage({ langName });
          this.fetchModulesLangId(langName);
        })
        .catch((err) => console.log(err));
    }
  }

  fetchHomeLangs(moduleId: number) {
    this.apiService
      .getHomeLangs(moduleId)
      .pipe(
        map((v: any) => {
          return v.data.map((v1: ICategoryList) => new CategoryList(v1));
        })
      )
      .toPromise()
      .then((langs: ICategoryList[]) => {
        this._categoryLangs = langs;
      })
      .catch((err) => {
        this._categoryLangs = [];
      });
  }

  get categoryLangs(): ICategoryList[] {
    return this._categoryLangs;
  }
  get getlangs(): ILanguage[] {
    return this._homeTechs;
  }

  fetchLangTechnologiesList(lang: string): void {
    this.apiService
      .getLangTechnologyList(lang)
      .pipe(
        map((techs: ILangTech[]) =>
          techs.map((tech: ILangTech) => new LangTech(tech))
        )
      )
      .toPromise()
      .then((techs: ILangTech[]) => {
        this._langTechnologies = techs;
      })
      .catch(console.log);
  }

  get langTechnologies(): ILangTech[] {
    return this._langTechnologies;
  }

  setTreeFromLanguage({ langName }: any): void {
    this._listWithLanguage.forEach((v: ICategoryList) => {
      v.languages.forEach((v1: ILanguage) => {
        if (v1.route === langName) {
          this.trainingService.fetchCourseTree(v1);
        }
      });
    });
  }

  setQustionTree(langName: string): void {
    this._listWithLanguage.forEach((v: ICategoryList) => {
      v.languages.forEach((v1: ILanguage) => {
        if (v1.route === langName) {
          this.tutorialService.fetchTreeQuestions(v1.langId);
        }
      });
    });
  }

  setLangBoxImage({ langName }: any): void {
    this._listWithLanguage.forEach((cat: ICategoryList) => {
      cat.languages.forEach((lang: ILanguage) => {
        if (lang.route === langName) {
          this.tutorialService.fetchLangBox(cat.categoryId, lang.langId);
        }
      });
    });
  }

  get reviewList(): IReview[] {
    return this._reviewList;
  }

  fetchHomeReview(): void {
    this.apiService
      .webReview()
      .pipe(map((r: any) => r.data.map((r1: IReview) => new Review(r1))))
      .toPromise()
      .then((r: IReview[]) => {
        this._reviewList = r;
      })
      .catch(console.log);
  }

  fetchBlogs(): void {
    this.apiService.blogs().subscribe((blogs: IBlog[]) => {
      this._blogList = blogs;
    });
  }

  get blogs(): IBlog[] {
    return this._blogList;
  }

  get courseMenuList(): ICourseMenu[] {
    return this._courseMenuList;
  }

  fetchTrainingMenu(): void {
    this.apiService
      .trainingMenu()
      .pipe(map((v: ICourseMenu[]) => v.map((v1) => new CourseMenu(v1))))
      .toPromise()
      .then((c: ICourseMenu[]) => {
        this._courseMenuList = c;
      })
      .catch((err) => {
        this._courseMenuList = [];
      });
  }

  postWriter(body: IWriter) {
    return this.apiService.postWriter(body);
  }

  fetchModules() {
    this.apiService
      .getModules()
      .pipe(map((m: any) => m.data.map((m: IModule) => new Module(m))))
      .toPromise()
      .then((m: IModule[]) => {
        this._modules = m;
      })
      .catch(console.log);
  }

  // fetchHomeTechnologies(): void {
  //   this.apiService.getHomeTechnologies.pipe(
  //     map((v: any[])=> {
  //       return v[0].languages.map((v: ILanguage) => new Language(v));
  //     })
  //   ).toPromise().then((langs: any) => {
  //     this._homeTechs = langs;
  //   })
  // }

  fetchModulesByLang(langId: number): void {
    this.apiService
      .getModules(langId)
      .pipe(map((m: any) => m.data.modules.map((m: IModule) => new Module(m))))
      .toPromise()
      .then((m: IModule[]) => {
        this._modulesByLangName = m;
      })
      .catch(console.log);
  }

  fetchModulesLangId(langName: string) {
    this._listWithLanguage.forEach((v: ICategoryList) => {
      v.languages.forEach((l: ILanguage) => {
        if (
          l.name &&
          l.name.toLocaleLowerCase().replace(/ /g, '-') === langName
        ) {
          this.fetchModulesByLang(l.langId);
        }
      });
    });
  }
  get modulesBylangs(): IModule[] {
    return this._modulesByLangName;
  }
  get modules(): IModule[] {
    return this._modules;
  }

  fetchAboutLang(lang: string) {
    return this.apiService
      .aboutLanguage(lang)
      .pipe(map((lang: any) => lang.data))
      .toPromise()
      .then((lang: any) => {
        this._aboutLang = lang;
      })
      .catch(console.log);
  }
  get aboutLang(): any {
    return this._aboutLang;
  }
}
