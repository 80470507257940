import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizCardComponent } from './quiz-card/quiz-card.component';
import { TreeQuestionComponent } from './tree-question/tree-question.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { LangBoxComponent } from './lang-box/lang-box.component';
import { CodeViewerComponent } from './code-viewer/code-viewer.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McqQuestionComponent } from './mcq-question/mcq-question.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MaterialModule } from 'src/app/material/material.module';
import { TemplateComponent } from './template/template.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { SignupPopupComponent } from './signup-popup/signup-popup.component';
import { ProfileEducationFormComponent } from 'src/app/user/profile-education-form/profile-education-form.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ShortNumberPipe } from '../quiz/shortnumber.pipe';
import { HighlightModule } from 'ngx-highlightjs';
import { HighlightLangCodePipe } from '../../home/filter-code.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AdsenseModule } from 'ng2-adsense';


const COMPONENTS = [
  QuizCardComponent,
  TreeQuestionComponent,
  LangBoxComponent,
  CodeViewerComponent,
  McqQuestionComponent,
  TemplateComponent,
  LoginPopupComponent,
  SignupPopupComponent,
  ProfileEducationFormComponent,
  ShortNumberPipe,
  HighlightLangCodePipe,
  PaginationComponent
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    //HighlightModule,
    RouterModule,
    MonacoEditorModule.forRoot(),
    FormsModule,
    ClipboardModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    HighlightModule,
    ShareButtonsPopupModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-8535817066678874',
      adSlot: 8944439968,
    }),
  ],
  exports: [...COMPONENTS, HighlightModule],
  providers: []
})
export class ShareLanguageModule { }
