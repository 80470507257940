export interface ICategory {
    categoryId: number;
    name: string;
    spclearners: number;
    get route(): string;
    get routeToName(): string;
}
export class Category implements ICategory {
  categoryId: number;
  name: string;
  spclearners: number;

  constructor({ categoryId, name, spclearners }: ICategory) {
    this.categoryId = categoryId;
    this.name = name;
    this.spclearners = spclearners;
  }

  get route(): string {
    return this.name.replace(/ /g, '-')
  }
  get routeToName(): string {
    return this.name.replace(/-/g, ' ')
  }
}
