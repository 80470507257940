import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryList, ICategoryList } from 'src/app/model/categoryList';
import { HomeService } from '../../services/home.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})
export class CategoryMenuComponent implements OnInit, AfterViewInit {
  faBars = faBars;
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.fetchListWithLanguage();
    setTimeout(() => {
      setMenuSize();
    }, 500);
  }

  ngAfterViewInit(): void {
    $(window).on("resize", function () {
      setMenuSize();
    });
    setTimeout(() => {
      setMenuSize();
    }, 1000);
  }

  get list(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }

  function() {
    // whenever we hover over a menu item that has a submenu
    $('button.wx-nav-btn').on('mouseover', function () {
      var $menuItem = $(this),
        $submenuWrapper = $('> .tessting', $menuItem);

      // grab the menu item's position relative to its positioned parent
      var menuItemPos = $menuItem.position();

      // place the submenu in the correct position relevant to the menu item
      $submenuWrapper.css({
        top: menuItemPos.top + 38,
        left: menuItemPos.left
      });
    });
  };

}

function setMenuSize() {
  var windowSizeWidth: number = $('.wx-nav-home-bar').outerWidth() || 0;
  var totalMenuWidth = 60;
  var everythingShown = true;
  
  $('.wx-nav-home-bar button.wx-nav-btn').each(function () {
    totalMenuWidth += $(this).outerWidth() || 0;
    var index = $(this).attr("indexAt");
    if (totalMenuWidth <= windowSizeWidth) {
      $(this).show();
      $('.dd-mm[indexAt="'+index+'"]').hide();
    } else {
      $(this).hide();
      $('.dd-mm[indexAt="'+index+'"]').show();
      everythingShown = false;
    }
  })
  if(everythingShown){
    $('.dropdown-custom-menu').hide();
  } else {
    $('.dropdown-custom-menu').show();
  }
}

