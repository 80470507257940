// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://technical99.com:8443',
  // apiUrl: 'http://43.225.54.139:8443',
  // apiUrl: 'http://localhost:8443',

  iconUrl: '/assets/images/lang/',
  techUrl: '/assets/images/tech/',
  commonUrl: '/assets/images/common/',

  articleUrl: '/assets/live-images/images/',
  blogThumbsUrl: '/assets/live-images/images/blog/',
  courseUrl: '/assets/live-images/images/course/',
  userUrl: '/assets/live-images/images/user/',
  trainerUrl: '/assets/live-images/images/trainer/',
  userDocsUrl: '/assets/live-images/images/user/docs/',
  pngIconUrl: '/assets/live-images/images/icons/',
  pngUrl: '/assets/live-images/icons/',
  adsUrl: '/assets/live-images/ads/',
  clientUrl: '/assets/live-images/images/clients/',
  bannerUrl: '/assets/live-images/banners/',
  blogUrl: '/assets/live-images/images/blog/',
  projectUrl: '/assets/live-images/images/',
};
