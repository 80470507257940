<div class="top-1-div">
    <div class="container">
        <div class="training-quote">
            <h3>Enhance your skills with us</h3>
            <p>Your trusted partner for online technical education</p>
            <div class="search d-flex align-items-stretch text-muted">
                <input type="text" placeholder="Search Course">
                <button><span class="material-icons-outlined" style="position: relative; top: 4px;">search</span></button>
            </div>
            <div class="buttons">
                <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" type="button">Book FREE Class</button>
                <button>Download Brochure</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="d-flex justify-content-end px-3 pt-3" [style.background-color]="closeIcon ? '' : '#f2f2f2'">
                <button type="button" #closeBtn class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <app-enquiry (closeAll)="closeBtn.click()" (hideCloseBtn)="hideBtn($event)"></app-enquiry>
        </div>
    </div>
</div>