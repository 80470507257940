export const environment = {
  production: true,

  apiUrl: 'https://technical99.com:8443',
  // apiUrl: 'http://103.211.218.167:8443',

  iconUrl: '/assets/images/lang/',
  techUrl: '/assets/images/tech/',
  commonUrl: '/assets/images/common/',

  userDocsUrl: 'https://technical99.com/images/user/docs/',
  blogUrl: 'https://technical99.com/images/blog/',
  trainerUrl: 'https://technical99.com/images/trainer/',
  projectUrl: 'https://technical99.com/images/',

  pngUrl: 'https://technical99.com/icons/',
  articleUrl: 'https://technical99.com/images/',
  userUrl: 'https://technical99.com/images/user/',
  courseUrl: 'https://technical99.com/images/course/',
  blogThumbsUrl: 'https://technical99.com/images/blog/',
  pngIconUrl: 'https://technical99.com/images/icons/',
  clientUrl: 'https://technical99.com/images/clients/',
  bannerUrl: 'https://technical99.com/banners/',
  adsUrl: 'https://technical99.com/ads/',
};
