
export interface IMCQOption {
  optionId:  number;
  mcqId:  number;
  optionSeq:  number;
  optionValue: string;
  optImage: string;
}
export class MCQOption {
  optionId:  number;
  mcqId:  number;
  optionSeq:  number;
  optionValue: string;
  optImage: string;
  constructor(props: IMCQOption) {
    this.optionId = props.optionId;
    this.mcqId = props.mcqId;
    this.optionSeq = props.optionSeq;
    this.optionValue = props.optionValue;
    this.optImage = props.optImage;
  }
}
export interface IMCQuestion {
  mcqId: number;
  langId: number;
  techId: number;
  topicId: number;
  title: string;
  quesType: string;
  question: string;
  subjective: string;
  image: string;
  optType: string;
  status: string;
  complexity: string;
  views: number;
  likes: number;
  options: IMCQOption[];
  scope: number;
  answerFlag: boolean;
  videoFlag: boolean;
  suggestFlag: boolean;
  reportFlag: boolean;
  get codeModel(): any;
  answer?: IAnswer | null;
  video: any;
  bookmark: number;
}
export interface IPagination {
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
}
export class Pagination {
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
  constructor(props: IPagination) {
    this.first = props?.first;
    this.last = props?.last;
    this.size = props?.size;
    this.number = props?.number;
    this.numberOfElements = props?.numberOfElements;
    this.totalPages = props?.totalPages;
    this.totalElements = props?.totalElements;
  }
}
export interface IOption {
  optionSeq: string;
}
export class Option {
  optionSeq: string;
  constructor({ optionSeq }: IOption) {
    this.optionSeq = optionSeq;
  }
}
export interface IAnswer {
  answer: IOption[];
  description: string;
  video: string;
  get videoList(): any;
}
export class Answer {
  answer: IOption[];
  description: string;
  video: string;
  constructor({ answer, description, video }: IAnswer) {
    this.answer = answer.map((option: IOption) => new Option(option));
    this.description = description;
    this.video = video;
  }
  get videoList(): any {
    if (this.video) return [];
    return JSON.parse(this.video);
  }
}
export class MCQuestion implements IMCQuestion{
  mcqId: number;
  langId: number;
  techId: number;
  topicId: number;
  title: string;
  quesType: string;
  question: string;
  subjective: string;
  image: string;
  optType: string;
  status: string;
  complexity: string;
  views: number;
  likes: number;
  options: IMCQOption[];
  scope: number;
  answerFlag: boolean;
  videoFlag: boolean;
  suggestFlag: boolean;
  reportFlag: boolean;
  answer: IAnswer | null;
  video: any;
  bookmark: number;
  constructor(props: IMCQuestion) {
    this.mcqId = props.mcqId;
    this.langId = props.langId;
    this.techId = props.techId;
    this.topicId = props.topicId;
    this.title = props.title;
    this.quesType = props.quesType;
    this.question = props.question;
    this.subjective = props.subjective;
    this.image = props.image;
    this.optType = props.optType;
    this.status = props.status;
    this.complexity = props.complexity;
    this.views = props.views;
    this.likes = props.likes;
    this.options = props.options.map((o: IMCQOption) => new MCQOption(o));
    this.scope = props.scope;
    this.answerFlag = false;
    this.videoFlag = false;
    this.suggestFlag = false;
    this.reportFlag = false;
    this.answer = null;
    this.video = props.video ? JSON.parse(props.video) : null;
    this.bookmark = props.bookmark;
  }

  get codeModel(): any {
    return {
      language: 'java',
      uri: this.mcqId + '.java',
      value: this.question
    }
  }
}
