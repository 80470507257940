import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ICategoryList, ILanguage } from 'src/app/model/categoryList';
import { environment } from 'src/environments/environment';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})
export class SwitchLangComponent implements OnInit, AfterViewInit {
  iconLink = environment.iconUrl;
  _selectedCategory: ICategoryList = this.list && this.list[0];
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.homeService.fetchListWithLanguage()
  }

  ngAfterViewInit(): void {
    this.homeService.fetchListWithLanguage()
  }
  get list(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }

  get selectedCategory(): ICategoryList {
    return this._selectedCategory || (this.list && this.list[0]);
  }

  setSelectedCategory(category: ICategoryList) {
    this._selectedCategory = category;
  }

  onMouseEnter(category: ICategoryList) {
    this.setSelectedCategory(category);
  }
}
