<div class="container pl-0">
    <div class="col-12 pl-0">

        <div class="row">
            <div class="col-3 ">
                <div class="technology-list">
                    <ul>
                        <li *ngFor="let item of langList" [class.select-hover]="item.categoryId === selectedCategory?.categoryId" (mouseenter)="mouseEnter(item)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-9 mt-4">
                <!-- <div class="row row-cols-3 py-4 pe-4 pl-3 justify-content-stretch"> -->
                <div class="checkbox-list row row-col-3  py-3">
                    <div class="col-md-4" *ngFor="let o of selectedCategory?.languages">
                        <div class="d-flex align-items-center card-box" (click)="selectTechno(o.langId)">
                            <img [src]="iconLink + o.icon + '.svg'" alt="">
                            <span style="font-size:14px">{{o.name}}</span>
                        </div>
                    </div>
                </div>
                <!-- <div class="row d-flex justify-content-end">
            <button class="mr-5 mt-5" (click)="cancel()">Cancel</button>
        </div> -->
                <!-- <a href="training" class="bourse-course">Bourse Course</a> -->
                <!-- <button data-bs-toggle="modal" class="bourse-course" data-bs-target="#certifiateModel" type="button">Corporate Enquiry</button> -->
                <a href="javascript:void(0)" class="bourse-course" data-bs-toggle="modal" data-bs-target="#certifiateModel">Corporate Enquiry</a>
                <!-- <button class="bourse-course" data-bs-toggle="modal" data-bs-target="#certifiateModel">Corporate Enquiry</button> -->
            </div>
        </div>
    </div>
</div>