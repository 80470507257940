export interface ILangTech {
  langId: number;
  langName: string;
  techName: string;
  status: string;
  blogCount: number;
  tuCount: number;
  mcqCount: number;
  iqCount: number;
  csCount: number;
  weight: number;
  icon: string;
  Id: number;
}
export class LangTech {
  langId: number;
  langName: string;
  techName: string;
  status: string;
  tuCount: number;
  blogCount: number;
  mcqCount: number;
  iqCount: number;
  csCount: number;
  weight: number;
  icon: string;
  Id: number;
  constructor(props: ILangTech) {
    this.langId = props.langId;
    this.langName = props.langName;
    this.techName = props.techName;
    this.status = props.status;
    this.tuCount = props.tuCount;
    this.blogCount = props.blogCount;
    this.mcqCount = props.mcqCount;
    this.iqCount = props.iqCount;
    this.csCount = props.csCount;
    this.weight = props.weight;
    this.icon = props.icon;
    this.Id = props.Id;
  }
}
