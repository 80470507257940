import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { HeightDirective } from './height.directive';
import { VideoPipe } from './video.pipe';

@NgModule({
  declarations: [
    CarouselComponent,
    HeightDirective,
    VideoPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [CarouselComponent, VideoPipe]
})
export class MaterialModule { }
