import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent implements OnInit {
  @Output() closePopup = new EventEmitter();
  @Input() completedData: string;
  message: string;
  constructor() {}

  ngOnInit(): void {
    this.message = this.completedData
      ? this.completedData
      : 'An error occured!';
  }

  close() {
    this.closePopup.emit();
  }
}
