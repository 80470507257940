<div class="px-4 py-2 w-80" style="background-color: #ffffff; border-radius: 4px;">
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <div class="profile-img-div">
        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="">
        <fa-icon [icon]="cameraIcon" class="c-pointer"></fa-icon>
      </div>
      <div>
        <h5 class="fw-normal m-0">{{name}}</h5>
        <p class="m-0 font-size-12 text-success">Registration successfully created</p>
        <p class="m-0 font-size-12">User Id: {{userId}}</p>
      </div>
    </div>
    <!-- <fa-icon [icon]="arrowLeft" class="c-pointer" *ngIf="otpFlag" (click)="back()"></fa-icon> -->
  </div>
  <hr class="w-100 my-4" />
  <div class="mt-3">
    <form [formGroup]="educationFormGroup" (ngSubmit)="onSubmitEducation()" *ngIf="!successMessage">
      <div class="me-3 mb-3 d-flex">
        <div class="input-group w-50 me-3">
          <input type="date" class="form-control" placeholder="DOB" formControlName="dob">
        </div>
        <div class="me-3 d-flex ml-2">
          <div class="m-2 me-3">
            <input class="form-check-input" value="Male" formControlName="gender" type="radio" id="Male">
            <label class="form-check-label" for="Male">Male</label>
          </div>
          <div class="m-2">
            <input class="form-check-input" value="Female" formControlName="gender" type="radio" id="Female">
            <label class="form-check-label" for="Female">Female</label>
          </div>
        </div>
      </div>
      <!-- <div class="me-3 d-flex">
        <div>
          <input class="form-check-input" value="Male" formControlName="gender" type="radio" id="Male">
          <label class="form-check-label" for="Male">Male</label>
        </div>
        <div>
          <input class="form-check-input" value="Female" formControlName="gender" type="radio" id="Female">
          <label class="form-check-label" for="Female">Female</label>
        </div>
      </div> -->
      <div class="d-flex">
        <select class="form-select mb-3  me-3" formControlName="qualification">
          <option value="" selected disabled>Qualification</option>
          <option value="Enginering">Enginering</option>
          <option value="BCA">BCA</option>
          <option value="BSC">BSC</option>
          <option value="MCA">MCA</option>
        </select>
        <select class="form-select  mb-3" formControlName="passout">
          <option value="" selected disabled>Year</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
        </select>
      </div>
      <div class="d-flex">

        <select class="form-select mb-3 me-3" formControlName="experience">
          <option value="Experience">Experience</option>
          <option value="Fresher">Fresher</option>
          <option value="1-2">1-2</option>
          <option value="3-4">3-4</option>
        </select>
        <div class="input-group mb-3 ">
          <input type="text" class="form-control" formControlName="designation" placeholder="Designation">
        </div>
      </div>

      <div>
        <div class="input-group mb-3">
          <input type="url" class="form-control" formControlName="linkedIn" placeholder="LinkedIn URL">
        </div>
      </div>
      <div class="mx-5 px-4">
        <button type="submit" class="submit-btn sign-btn" [disabled]="!educationFormGroup.valid" [ngClass]="{'light-btn ':educationFormGroup.valid, 'light-btn-s': !educationFormGroup.valid}">
          <div class="d-flex align-items-center justify-content-center">
            Update
            <div class="spinner-icon ms-2" *ngIf="spinnerFlag">
              <fa-icon [icon]="spinner"></fa-icon>
            </div>
          </div>
        </button>
      </div>
    </form>
    <div *ngIf="successMessage">
      <div class="text-success"> {{successMessage}}</div>
      <div>
        <button class="submit-btn sign-btn" (click)="signIn()">Sign In</button>
      </div>
    </div>
    <p *ngIf="!successMessage" class="paragraph text-center py-2 mb-2">Already have an account? <a class="c-pointer" (click)="signIn()">SignIn</a></p>
    <p *ngIf="!successMessage" class="font-size-11 text-justify">Note: By continuing and signing in, you agree to technical99's <a href="#" class="txt_warning">Term & Conditions</a> and <a href="#" class="txt_warning">Privacy Policy</a></p>

  </div>
</div>

<div class="toast-container position-absolute p-3 top-0 end-0" id="toastPlacement">
  <div class="toast" [ngClass]="signupErrorFlag ? signupErrorMsg ? 'bg-danger show' : 'bg-primary show': ''">
    <div class="d-flex">
      <div class="toast-body text-white">
        {{signupErrorMsg || signupSuccessMsg}}
      </div>
      <button type="button" class="btn-close me-2 m-auto text-white" (click)="hideSignupMessage()" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</div>