import { NumberSymbol } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/Api/api.service';
import { MainService } from 'src/app/Api/mainService';
import { ILanguage, Language } from 'src/app/model/categoryList';
import {
  IInterviewQuestions,
  IInterviewTitle,
  InterviewQuestions,
  InterviewTitle,
} from 'src/app/model/language/interview-details';
import {
  ILanguageDetails,
  LanguageDetails,
} from 'src/app/model/language/lang-details';
import {
  Answer,
  IAnswer,
  IMCQuestion,
  IPagination,
  MCQuestion,
  Pagination,
} from 'src/app/model/language/mcq-question';
import { ITreeQuestion, TreeQuestion } from 'src/app/model/language/tree';
import { AuthService } from 'src/app/user/auth.service';
import {
  ICodingTitle,
  CodingTitle,
  CodingQuestion,
  ICodingQuestion,
} from './../../model/language/coding-details';
import { DOCUMENT } from '@angular/common';
import { CommonSeoService } from 'src/app/services/common-seo.service';

@Injectable()
export class TutorialService {
  private _getTreeQuestionList: ITreeQuestion[] = [];
  private _getLangListCurrentRemove: ILanguage[] = [];
  private _MCQuestionsList: IMCQuestion[] = [];
  public mcqPagination!: IPagination;
  private _codingTitles: ICodingTitle[] = [];
  private _interviewTitles: IInterviewTitle[] = [];
  private _codingQuetionDetails!: ICodingQuestion;
  public codingQuePagination!: IPagination;
  private _interviewDetails: IInterviewQuestions[] = [];
  private _interviewDetailsById!: IInterviewQuestions;
  public interviewPagination!: IPagination;
  public codeSolutionPagination!: IPagination;
  public quizQuesAnalysisPagination!: IPagination;
  private _tutorialDetails!: ILanguageDetails;
  private _langTech: string = '';
  private _langTopic: string = '';
  public subjectOpenNode = new Subject<any>();
  public _getMCQQuestion!: IMCQuestion | null;
  public _aboutLang: any;
  constructor(
    private apiService: ApiService,
    private mainService: MainService,
    private seoService: CommonSeoService,
    private auth: AuthService,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  // fetchTutorialDetails(name: string): void {
  //   this.apiService
  //     .tutorialDetails(name)
  //     .pipe(map((value: any) => new LanguageDetails(value.data)))
  //     .toPromise()
  //     .then((data: ILanguageDetails) => {
  //       this._tutorialDetails = data;
  //       if (this._tutorialDetails.metadata) {
  //         const metaTags = JSON.parse(this._tutorialDetails.metadata);
  //         this.seoService.updateMetaTags(metaTags);
  //         this.subjectOpenNode.next(data);
  //       }
  //     })
  //     .catch((e) => {});
  // }

  async getTutorialDetail(name: string) {
    let response = await this.apiService.tutorialDetails(name).toPromise();
    response.data['pageText'] =
      response.data.page && JSON.parse(response.data.page);
    response.data['videos'] =
      response.data.videoId && JSON.parse(response.data.videoId);
    if (response.data.metadata) {
      const metaTags = JSON.parse(response.data.metadata);
      this.seoService.updateMetaTags(metaTags);
    }
    this._tutorialDetails = response.data;

    this.subjectOpenNode.next(response.data);
    return response.data;
  }

  setLangTech(tech: string): void {
    this._langTech = tech;
  }
  setLangTopic(topic: string): void {
    this._langTopic = topic;
  }
  get langTech(): string {
    return this._langTech;
  }
  get langTopic(): string {
    return this._langTopic;
  }
  get tutorialDetails(): ILanguageDetails {
    return this._tutorialDetails;
  }
  fetchTreeQuestions(langId: number) {
    this.apiService
      .questionTree(langId)
      .pipe(
        map((m: ITreeQuestion[]) =>
          m.map((t: ITreeQuestion) => new TreeQuestion(t))
        )
      )
      .toPromise()
      .then((list) => {
        this._getTreeQuestionList = list;
        this._getTreeQuestionList.forEach((v) => {
          v.topics.forEach((v1) => {
            if (v1.route && v1.route === this._langTopic) {
              v.show();
            }
          });
        });
      })
      .catch((err) => console.log(err));
  }

  get getLangListCurrentRemove() {
    return this._getLangListCurrentRemove;
  }

  fetchLangBox(catId: number, langId: number) {
    this.apiService
      .langBox(catId, langId)
      .pipe(
        map((lang: ILanguage[]) => {
          return lang.map((l) => new Language(l));
        })
      )
      .toPromise()
      .then((lang: ILanguage[]) => (this._getLangListCurrentRemove = lang))
      .catch(console.log);
  }

  get getTreeQuestions() {
    return this._getTreeQuestionList;
  }

  get mcquestionList() {
    return this._MCQuestionsList;
  }
  // This method is written by Tarique
  fetchMCQQuestionByTech(
    name: string,
    page?: NumberSymbol,
    size?: number
  ): void {
    this.apiService
      .getMCQByTech(name, page, size)
      .pipe(
        map((ques: any) => {
          if (ques.data) {
            this.mcqPagination = new Pagination(ques.data);
            return ques.data.content.map(
              (que: IMCQuestion) => new MCQuestion(que)
            );
          }
          return [];
        })
      )
      .toPromise()
      .then((ques: IMCQuestion[]) => {
        this._MCQuestionsList = ques;
        if (ques.length !== 0) {
          this.getTreeQuestions.forEach((q: ITreeQuestion) => {
            if (q.langId === ques[0].langId) q.show();
          });
        }
      })
      .catch(console.log);
  }

  fetchMCQQuestion(
    tech: string,
    name: string,
    page?: number,
    size?: number
  ): void {
    this.apiService
      .getMCQTopics(tech, name, page, size)
      .pipe(
        map((ques: any) => {
          if (ques.data) {
            this.mcqPagination = new Pagination(ques.data);
            return ques.data.content.map(
              (que: IMCQuestion) => new MCQuestion(que)
            );
          }
          return [];
        })
      )
      .toPromise()
      .then((ques: IMCQuestion[]) => {
        this._MCQuestionsList = ques;
        if (ques.length !== 0) {
          this.getTreeQuestions.forEach((q: ITreeQuestion) => {
            if (q.langId === ques[0].langId) q.show();
          });
        }
      })
      .catch(console.log);
  }

  async fetchMCQBookmarkedQuestion(
    tech: string,
    name: string,
    page?: number,
    size?: number
  ) {
    this.mainService
      .postAuthWithoutPromise('/auth-mcq/bookmarked-list', {
        page: page,
        size: size,
      })
      .pipe(
        map((ques: any) => {
          if (ques.data) {
            this.mcqPagination = new Pagination(ques.data);
            return ques.data.content.map(
              ({ mcqs }: any) => new MCQuestion(mcqs)
            );
          }
          return [];
        })
      )
      .toPromise()
      .then((ques: IMCQuestion[]) => {
        this._MCQuestionsList = ques;
        if (ques.length !== 0) {
          this.getTreeQuestions.forEach((q: ITreeQuestion) => {
            if (q.langId === ques[0].langId) q.show();
          });
        }
      })
      .catch(console.log);
  }

  fetchMCQQuestionByUser(
    tech: string,
    name: string,
    page?: number,
    size?: number
  ): void {
    this.apiService
      .getMCQTopicByuser(tech, name, page, size)
      .pipe(
        map((ques: any) => {
          if (ques.data) {
            this.mcqPagination = new Pagination(ques.data);
            return ques.data.content.map(
              (que: IMCQuestion) => new MCQuestion(que)
            );
          }
          return [];
        })
      )
      .toPromise()
      .then((ques: IMCQuestion[]) => {
        this._MCQuestionsList = ques;
        if (ques.length !== 0) {
          this.getTreeQuestions.forEach((q: ITreeQuestion) => {
            if (q.langId === ques[0].langId) q.show();
          });
        }
      })
      .catch(console.log);
  }

  fetchMCQAnswer(id: any): void {
    this.apiService
      .getAnswer(id)
      .pipe(map((ans: any) => new Answer(ans.data)))
      .toPromise()
      .then((ans: IAnswer) => {
        this.mcquestionList.forEach((obj: IMCQuestion) => {
          if (obj.mcqId === id) {
            obj.answer = new Answer(ans);
          }
        });
      })
      .catch(console.log);
  }

  fetchMCQuestion(mcq: number): void {
    this.apiService
      .getMCQById(mcq)
      .pipe(map((que: any) => new MCQuestion(que.data)))
      .toPromise()
      .then((que: IMCQuestion) => {
        this._getMCQQuestion = que;
        console.log(que);
      })
      .catch(console.log);
  }

  get getMCQQuestion(): IMCQuestion | null {
    return this._getMCQQuestion;
  }

  fetchCodingQuestion(
    tech: string,
    title: string,
    size: number,
    page: number
  ): void {
    this.apiService
      .getCodingQuetionTitle(tech, title, size, page)
      .pipe(
        map((queList: any) => {
          this.codingQuePagination = new Pagination(queList.data);
          return queList.data.content.map(
            (q: ICodingTitle) => new CodingTitle(q)
          );
        })
      )
      .toPromise()
      .then((ques: ICodingTitle[]) => {
        console.log(ques);

        this._codingTitles = ques;
      })
      .catch(console.log);
  }
  fetchCodingQuestionById(
    tech: string,
    id: string,
    size: number,
    page: number
  ): void {
    this.apiService
      .getCodingQuetionTitle(tech, id, size, page)
      .pipe(
        map((queList: any) => {
          this.codingQuePagination = new Pagination(queList.data);
          return queList.data.content.map(
            (q: ICodingTitle) => new CodingTitle(q)
          );
        })
      )
      .toPromise()
      .then((ques: ICodingTitle[]) => {
        this._codingTitles = ques;
      })
      .catch(console.log);
  }

  get codingTitles(): ICodingTitle[] {
    return this._codingTitles;
  }

  async solVote(vote: any, codeSolId: any, codeId: any) {
    let post = await this.mainService.postAuth('/auth-code/sol-votes', {
      codeSolId: codeSolId,
      codeId: codeId,
      vote: vote,
    });
    return post;
  }

  async codeVote(vote: any, codeId: any) {
    let post = await this.mainService.postAuth('/auth-code/votes', {
      codeId: codeId,
      vote: vote,
    });
    return post;
  }

  async iqVote(vote: any, iqId: any) {
    let post = await this.mainService.postAuth('/auth-iq/votes', {
      iqId: iqId,
      vote: vote,
    });
    return post;
  }

  async mcqVote(vote: any, mcqId: any) {
    let post = await this.mainService.postAuth('/auth-mcq/votes', {
      mcqId: mcqId,
      vote: vote,
    });
    return post;
  }

  async mcqReport(msg: any, mcqId: any) {
    let post = await this.mainService.postAuth('/auth-mcq/error', {
      mcqId: mcqId,
      error: msg,
    });
    return post;
  }

  async bookmark(type: any, data: any) {
    let post = await this.mainService.postAuth(`/auth-${type}/bookmark`, {
      bookmarkId: data,
    });
    return post;
  }

  submitErrors(body: any) {
    this.apiService
      .sendMCQError(body)
      .toPromise()
      .then((res) => {
        console.log(res);
      })
      .catch(console.error);
  }

  // fetchCodingQuestionDetails(topic: string, name: string): void
  // {
  //   this.apiService.getCodingTopics(topic, name).pipe(
  //     map((question: any) => {
  //       // this.codeSolutionPagination = new Pagination(question.data);
  //       return question.data.map((v: ICodingQuestion) => new CodingQuestion(v))
  //     })
  //   ).toPromise().then((ques: ICodingQuestion) => {
  //     // this._codingQuetionDetails = ques[0] ?? null;
  //     this._codingQuetionDetails = ques;
  //     console.log('Helloooo', this._codingQuetionDetails)
  //   }).catch(console.log);
  // }
  fetchCodingQuestionDetails(lang: string, topic: string, name: string): void {
    this.apiService
      .getCodingTopics(lang, topic, name)
      .pipe(map((question: any) => new CodingQuestion(question.data)))
      .toPromise()
      .then((ques: ICodingQuestion) => {
        this._codingQuetionDetails = ques;
      })
      .catch(console.log);
  }

  fetchCodingQuestionDetailsById(id: string, lang: string): void {
    this.apiService
      .getCodingTopicsById(id)
      .pipe(map((m: any) => m.data))
      .toPromise()
      .then((ques: ICodingQuestion) => {
        // this._codingQuetionDetails = ques;
        this.router.navigate([
          `/${lang}/coding/${ques.topicName}/${ques.urlTitle}`,
        ]);
      })
      .catch(console.log);
  }

  fetchInterviewDetails(
    techName: string,
    name: string,
    size: number,
    page: number,
    byTech: boolean,
    byList: boolean,
    loggedIn: boolean = false
  ): void {
    this.apiService
      .getInterviewTopics(techName, name, size, page, byTech, byList, loggedIn)
      .pipe(
        map((question: any) => {
          this.interviewPagination = new Pagination(question.data);
          if (!byList) {
            return question.data.content.map(
              (v: IInterviewQuestions) => new InterviewQuestions(v)
            );
          } else {
            return question.data.content.map(
              (v: IInterviewTitle) => new InterviewTitle(v)
            );
          }
        })
      )
      .toPromise()
      .then((ques: any[]) => {
        if (!byList) {
          this._interviewDetails = ques;
        } else {
          this._interviewTitles = ques;
        }
      })
      .catch(() => {
        this._interviewDetails = [];
      });
  }

  fetchInterviewDetailsByTitle(topic: string, topicTitle: string) {
    this.apiService
      .getInterviewByTopics(topic, topicTitle)
      .pipe(
        map((question: any) => {
          this.interviewPagination = new Pagination(question.data);
          return question.data;
        })
      )
      .toPromise()
      .then((ques: IInterviewQuestions) => {
        this._interviewDetails = [new InterviewQuestions(ques)];
      })
      .catch(console.log);
  }

  fetchInterviewDetailsById(id: string): void {
    this.apiService
      .getInterviewTopicsById(id)
      .pipe(
        map((question: any) => {
          return question.data;
        })
      )
      .toPromise()
      .then((ques: IInterviewQuestions) => {
        this._interviewDetailsById = new InterviewQuestions(ques);
      })
      .catch(console.log);
  }
  get interviewDetails() {
    return this._interviewDetails;
  }
  get interviewDetailsById() {
    return this._interviewDetailsById;
  }
  get interviewTitles() {
    return this._interviewTitles;
  }
  get questionCode(): ICodingQuestion {
    return this._codingQuetionDetails;
  }
  fetchAboutLang(lang: string) {
    return this.apiService
      .aboutLanguage(lang)
      .pipe(map((lang: any) => lang.data))
      .toPromise()
      .then((lang: any) => {
        this._aboutLang = lang;
      })
      .catch(console.log);
  }
  get aboutLang(): any {
    return this._aboutLang;
  }
  submitErrorMes(body: any): Observable<any> {
    return this.apiService.sendError(body);
  }
  async vote(body: any) {
    let post = await this.mainService.postAuth('/auth-tutorial/votes', {
      pageId: this._tutorialDetails.langId,
      vote: body,
    });
    return post;
  }
  async report(msg: any, type: any) {
    let post = await this.mainService.postAuth('/auth-tutorial/error', {
      pageId: this._tutorialDetails.langId,
      reportType: type,
      error: msg,
    });
    return post;
  }
}
