import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ILangKeywords } from 'src/app/model/home/common';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { IEnquiry } from 'src/app/model/home/enquiry';
import { ApiService } from 'src/app/Api/api.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Output() activeKeywords = new EventEmitter<any>();
  @Output() activeCompleted = new EventEmitter<any>();
  showError: boolean = false;
  detailFormGroup: FormGroup = this.fb.group({});
  demoFlag = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  submitted: boolean = false;
  submit: boolean = false;
  response: any = null;
  resTimeout: any;
  payload: any;
  constructor(private fb: FormBuilder, private apiservice: ApiService) {
    this.validation();
  }
  validateNumber(control: AbstractControl) {
    const value = control.value;
    if (
      value !== null &&
      value !== undefined &&
      value.toString().length === 4 &&
      !value.toString().includes('e')
    ) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const keyCode = event.keyCode || event.charCode;
    if ((value.length >= 4 && keyCode !== 8) || keyCode === 69) {
      event.preventDefault();
    }
  }
  validation() {
    this.detailFormGroup = this.fb.group({
      name: [[], Validators.required],
      mobile: [
        null,
        [
          Validators.compose([
            Validators.required,
            // Validators.minLength(10),
            // Validators.maxLength(12),
          ]),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      profession: ['', Validators.required],
      qualification: [[], Validators.required],
      passout: ['', [Validators.required, this.validateNumber]],
      comment: [],
      demoDate: [],
    });
    this.detailFormGroup.controls.demoDate.disable();
    this.detailFormGroup.valueChanges.subscribe((res: any) => {
      this.showError = false;
    });
  }

  ngOnInit(): void {}
  onChangeDemo(event: Event) {
    this.demoFlag = (event.target as any).checked;
    if (this.demoFlag) {
      this.detailFormGroup.controls.demoDate.enable();
    } else {
      this.detailFormGroup.controls.demoDate.disable();
    }
  }

  // next() {
  //   if(this.detailFormGroup.valid) {
  //     const values = this.detailFormGroup.value;
  //     this.activeCompleted.emit(values);
  //   }
  // }

  async saveEnquiry(body: IEnquiry) {
    if (!this.detailFormGroup.valid) {
      this.showError = true;
      this.submitted = true;
    } else {
      // this.activeCompleted.emit(this.response.message);
      this.payload = this.formatePayload(body);

      this.apiservice.postCourseEnquiry(this.payload).subscribe((res: any) => {
        if (res.status == 200) {
          this.submit = true;
          this.response = res;
          this.activeCompleted.emit(this.response.message);
          // this.detailFormGroup.reset({});
          // this.resTimeout = setTimeout(() => {
          //   this.submit = false;
          // }, 5000);
        }
      });
    }
  }

  formatePayload(data: any) {
    return {
      langId: 1,
      name: data.name,
      mobile: data.mobile.number,
      email: data.email,
      profession: data.profession,
      qualification: data.qualification,
      passout: data.passout,
      comment: data.comment,
      source: 'Website',
      webFormName: 'Main',
      demoDate: data.demoDate,
      keywords: '[1,3]',
    };
  }
  back() {
    this.detailFormGroup.reset();
    this.activeKeywords.emit();
  }
}
