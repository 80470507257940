import { FormGroup } from '@angular/forms';
    
export function ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function AgeValidator(controlName: string,){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        let eighteenYears = new Date();
		eighteenYears.setTime(eighteenYears.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000);
        if (control.value <  eighteenYears) {
            control.setErrors({ confirmedValidator: true });
        } else {
            control.setErrors(null);
        }
    }
}