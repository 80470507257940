import { Details, IDetails } from "./common";
import { IUser, User } from './../home/user';

export interface IFullstackCourse {
    programId: number;
    courseName: string;
    title: string;
    noc: number;
    duration: number;
    learners: number;
    ratingCount: number;
    icon: string;
    bgColor: string;
    rating: number;
}
export class FullstackCourse implements IFullstackCourse {
    programId: number;
    courseName: string;
    title: string;
    noc: number;
    duration: number;
    learners: number;
    ratingCount: number
    icon: string;
    bgColor: string;
    rating: number;

    constructor(props: IFullstackCourse) {
        this.programId = props.programId;
        this.courseName = props.courseName;
        this.noc = props.noc;
        this.duration = props.duration;
        this.learners = props.learners;
        this.ratingCount = props.ratingCount;
        this.icon = props.icon;
        this.rating = props.rating;
        this.bgColor = props.bgColor;
        this.title = props.title;
    }
}

export interface IFullstackCourseDetails extends IDetails {
    programId: number;
    noc: number;
    isTrending: number;
}
export class FullstackCourseDetails extends Details implements IFullstackCourseDetails {
    programId: number;
    noc: number;
    isTrending: number;

    constructor(props: IFullstackCourseDetails) {
        super(props)
        this.programId = props.programId;
        this.noc = props.noc;
        this.isTrending = props.isTrending;
    }
}

export interface IFSTree {
  courseId: number;
  langId: number;
  title: string;
  courseType: string
  icon: string;
  get route(): string;
}
export class FSTree implements IFSTree {
  courseId: number;
  langId: number;
  title: string;
  courseType: string
  icon: string;

  constructor(props: IFSTree) {
    this.courseId = props.courseId;
    this.langId = props.langId;
    this.title = props.title;
    this.courseType = props.courseType;
    this.icon = props.icon;
  }

  get route(): string {
    if (!this.title) return '';
    return this.title.replace(/-/, ' ');
  }
}

export interface IClient {
  clientId: number;
  companyName: string;
  logo: string;
}
export class Client {
  clientId: number;
  companyName: string;
  logo: string;

  constructor({ clientId, companyName, logo }: IClient) {
    this.clientId = clientId;
    this.companyName = companyName;
    this.logo = logo;
  }
}

export interface IPlacements {
  placementId: number;
  clientId: number;
  ctc: string;
  designation: string;
  client: IClient;
}
export class Placements implements IPlacements {
  placementId: number;
  clientId: number;
  ctc: string;
  designation: string;
  client: IClient;
  constructor({ placementId, clientId, ctc, designation, client }: IPlacements) {
    this.placementId = placementId;
    this.clientId = clientId;
    this.ctc =ctc;
    this.designation = designation;
    this.client = new Client(client);
  }
}

export interface IFSAlumni  {
  storyId: number;
  courseId: number;
  type: string;
  title: string;
  story: string;
  likes: number;
  video: string;
  createdDate: string;
  user: IUser;
  placements: IPlacements;
}
export class FSAlumni implements IFSAlumni {
  storyId: number;
  courseId: number;
  type: string;
  title: string;
  story: string;
  likes: number;
  video: string;
  createdDate: string;
  user: IUser;
  placements: IPlacements;

  constructor(props: IFSAlumni) {
    this.storyId = props.storyId;
    this.courseId = props.courseId;
    this.type = props.type;
    this.title = props.title;
    this.story = props.story;
    this.likes = props.likes;
    this.video = props.video;
    this.createdDate = props.createdDate;
    this.user = new User(props.user);
    this.placements = new Placements(props.placements)
  }
}
