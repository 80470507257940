import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spcourse-banner',
  templateUrl: './spcourse-banner.component.html',
  styleUrls: ['./spcourse-banner.component.scss']
})
export class SPCourseBannerComponent implements OnInit {
  closeIcon: boolean = true;
  constructor() { }
  ngOnInit(): void {
  }
  hideBtn(event: boolean) {
    this.closeIcon = event;
  }
}
