import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-header',
  template: `
  <div class="about-div-1" [style.background-image]="'url(' + imgLink + ')'"></div>
  <!-- <div class="">
      <div class="tabs">
          <ng-container *ngFor="let tab of tabRoutes">
              <div class="tab">
                  <button [routerLink]="'/' + tab.link" routerLinkActive="link-active">
                      {{tab.title}}
                  </button>
              </div>
          </ng-container>
      </div>
  </div> -->
  
  `,
  styles: [
    `
    .about-div-1 {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 250px;
    }
    .tabs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #f2f2f2;
        .tab > button {
            border: none;
            padding: 8px 20px;
            font-weight: 500;
        }
        .link-active {
            background-color: #db823c !important;
            color: #ffffff !important;
        }
    }
    `
  ]
})
export class CommonHeaderComponent implements OnInit {
  @Input() imgLink: string = "";
  tabRoutes = [{}]
  //   {
  //     title: 'About Us',
  //     link: 'about-us'
  //   },
  //   {
  //     title: 'Our Team',
  //     link: 'our-team'
  //   },
  //   {
  //     title: 'Reviews',
  //     link: 'reviews'
  //   },
  //   {
  //     title: 'Contact Us',
  //     link: 'contact-us'
  //   }
  // ]

  constructor() { }

  ngOnInit(): void {
  }

}
