import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/home/services/home.service';
import { ICategory } from 'src/app/model/category';
import { ICategoryList, ILanguage } from 'src/app/model/categoryList';
import { environment } from 'src/environments/environment';
import { TutorialService } from '../../services/tutorial.service';

@Component({
  selector: 'app-lang-box',
  templateUrl: './lang-box.component.html',
  styleUrls: ['./lang-box.component.scss'],
})
export class LangBoxComponent implements OnInit {
  icon = environment.iconUrl;
  constructor(
    private homeService: HomeService,
    private tutorialService: TutorialService,
    private route: ActivatedRoute
  ) {}

  @Input() offCanvas: boolean;

  ngOnInit(): void {
    this.route.params.subscribe((r) => {
      const courseName = r.courseName;
      if (this.langImages.length !== 0) {
        this.setLangImags(courseName);
      } else {
        this.homeService.fetchListWithLanguage();
      }
    });
  }

  setLangImags(courseName: string) {
    this.langImages.forEach((cat: ICategoryList) => {
      cat.languages.forEach((lang: ILanguage) => {
        if (lang.route === courseName) {
          this.tutorialService.fetchLangBox(cat.categoryId, lang.langId);
        }
      });
    });
  }

  get langImages(): ICategoryList[] {
    return this.homeService.listWithLanguage;
  }
  get lLCR(): ILanguage[] {
    return this.tutorialService.getLangListCurrentRemove;
  }
}
