import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISignUp, IUserInfo } from '../model/auth/singup';
import { IEnquiry } from '../model/home/enquiry';
import { IWriter } from '../model/home/writer';
import { RequestBatch } from '../training/components/request-form/request-form.component';
import { AuthService } from 'src/app/user/auth.service';

@Injectable()
export class ApiService {
  private apiUrl = environment.apiUrl;
  private trainingApiUrl = environment.apiUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, content-type',
    }),
  };
  constructor(private https: HttpClient, private cookies: CookieService) {}

  public httpsOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `${this.cookies.get('token')}`,
    }),
  };

  /* ---------------------------------- Common APIs  ---------------------------------*/
  categoryListWithLanguage(): Observable<any> {
    return this.https.get(
      this.apiUrl + '/common/category-lang',
      this.httpOptions
    );
  }

  langMenu(langId: number): Observable<any> {
    return this.https.get(this.apiUrl + '/common/modules/' + langId);
  }
  langMenuByName(langname: string): Observable<any> {
    return this.https.get(this.apiUrl + '/common/modulesbyname/' + langname);
  }
  courseTree({ langId }: any): Observable<any> {
    return this.https.get(this.apiUrl + '/common/tech-topic/' + langId);
  }

  langTreeLink(langId: number): Observable<any> {
    return this.https.get(this.apiUrl + '/common/tutorial-link/' + langId);
  }

  questionTree(id: any): Observable<any> {
    return this.https.get(this.apiUrl + `/common/tech-topic/${id}`);
  }

  getLangTechnologyList(lang: string): Observable<any> {
    return this.https.get(`${this.apiUrl}/common/technology/${lang}`);
  }

  getModules(langId?: number): Observable<any> {
    if (langId) {
      return this.https.get(`${this.apiUrl}/common/modules/${langId}`);
    }
    return this.https.get(`${this.apiUrl}/common/modules`);
  }
  getHomeTechnologies(): Observable<any> {
    return this.https.get(`${this.apiUrl}/common/languages`);
  }

  getHomeLangs(moduleId: number): Observable<any> {
    return this.https.get(`${this.apiUrl}/common/category-lang/${moduleId}`);
  }

  aboutLanguage(lang: string) {
    return this.https.get(`${this.apiUrl}/common/about-lang/${lang}`);
  }

  langBox(catId: number, langId: number): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/common/lang-left-menu?catId=${catId}&langId=${langId}`
    );
  }

  employees(role: string | null): Observable<any> {
    return this.https.get(`${this.apiUrl}/common/employees?role=${role}`);
  }

  /* ---------------------------------- Reviews APIs  ---------------------------------*/

  webReview(): Observable<any> {
    return this.https.get(this.apiUrl + '/review/web');
  }

  trainingReview(): Observable<any> {
    return this.https.get(this.apiUrl + '/review/courses');
  }

  trainingReviewDetails(id: any, type: string): Observable<any> {
    return this.https.get(this.apiUrl + '/review/' + type + '/course/' + id);
  }

  /* ----------------------------------------  MCQs   ---------------------------------*/

  sendError(body: any): Observable<any> {
    return this.https.post(`${this.apiUrl}/mcq/report-error`, body);
  }
  sendMCQError(body: any): Observable<any> {
    return this.https.post(`${this.apiUrl}/mcq/report-error`, body);
  }

  getMCQTopicsById(id: number): Observable<any> {
    return this.https.get(`${this.apiUrl}/mcq/topic/${id}`);
  }

  getAnswer(id: string): Observable<any> {
    return this.https.get(`${this.apiUrl}/mcq/answer?mcqid=${id}`);
  }
  // MCQ search by techName - Tarique Anwer
  getMCQByTech(name: string, page?: number, size?: number): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/mcq/guest/mcq-list-by-tech?name=${name}&page=${page}&size=${size}`
    );
  }
  getMCQTopics(
    tech: string,
    topic: string,
    page?: number,
    size?: number
  ): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/mcq/guest/mcq-list?tech=${tech}&topic=${topic}&page=${page}&size=${size}`
    );
  }

  getMCQTopicByuser(
    tech: string,
    topic: string,
    page?: number,
    size?: number
  ): Observable<any> {
    const header: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', `${this.cookies.get('token')}`);
    return this.https.get(
      `${this.apiUrl}/mcq/session/mcq-list?tech=${tech}&topic=${topic}&page=${page}&size=${size}`,
      { headers: header }
    );
  }
  searchMCQById(id: number): Observable<any> {
    return this.https.get(`${this.apiUrl}/mcq/search/guest/text?q=${id}`);
  }

  getMCQById(mcqId: number): Observable<any> {
    const header: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', `${this.cookies.get('token')}`);
    return this.https.get(`${this.apiUrl}/mcq/search/session/text?q=${mcqId}`, {
      headers: header,
    });
  }

  /* ----------------------------------------  Tutorials   ---------------------------------*/

  tutorialDetails(name: string): Observable<any> {
    return this.https.get(`${this.apiUrl}/tutorial/page-title/${name}`);
  }
  langDetails({ pageId }: any): Observable<any> {
    return this.https.get(this.apiUrl + '/tutorial/page/' + pageId);
  }
  /* ----------------------------------------  Interview Questions   ---------------------------------*/

  getInterviewTopicsById(id: string): Observable<any> {
    return this.https.get(`${this.apiUrl}/interview/ques?iqid=${id}`);
  }
  getInterviewByTopics(topic: string, topicTitle: string): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/interview/iq-by-title?topic=${topic}&title=${topicTitle}`
    );
  }
  getInterviewTopics(
    techName: string,
    topicName: string,
    size: number,
    page: number,
    byTech: boolean,
    byList: boolean,
    loggedIn: boolean
  ): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/${loggedIn ? 'auth-iq' : 'interview'}/iq-${
        byList ? 'list-' : ''
      }by-${
        byTech ? 'tech' : 'topic'
      }?tech=${techName}&topic=${topicName}&noq=0&size=${size}&page=${page}`,
      this.httpsOptionsAuth
    );
  }

  /* ----------------------------------------  Code Solutions   ---------------------------------*/

  getCodingQuetionTitle(
    tech: string,
    title: string,
    size: number,
    page: number
  ): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/coding/code-titles${
        title == '' ? '-by-tech' : ''
      }?tech=${tech}&topic=${title}&size=${size}&page=${page}`
    );
  }
  getCodingTopics(lang: string, topic: string, name: string): Observable<any> {
    return this.https.get(
      `${this.apiUrl}/coding/code-solution?lang=${lang}&topic=${topic}&${topic}&title=${name}`
    );
  }
  getCodingTopicsById(id: string): Observable<any> {
    // Need to send code in body
    return this.https.get(
      `${this.apiUrl}/coding/code-solution-by-id?codeid=${id}`
    );
  }

  /* ----------------------------------------  Blogs   ---------------------------------*/

  blogs(): Observable<any> {
    return this.https.get(`${this.apiUrl}/blogs/trending-blogs`);
  }

  /* ----------------------------------------  Training APIs   ---------------------------------*/

  get category(): Observable<any> {
    return this.https.get(this.trainingApiUrl + '/training/category');
  }

  langWitCategoryId(categoryId: number): Observable<any> {
    return this.https.get(
      this.trainingApiUrl + '/training/language/' + categoryId
    );
  }

  postWriter(body: IWriter): Observable<any> {
    return this.https.post(`${this.apiUrl}/contributor/addWriter`, body);
  }

  langKeywords(id: number): Observable<any> {
    return this.https.get(this.trainingApiUrl + '/training/keywords/' + id);
  }

  postCourseEnquiry(body: IEnquiry): Observable<any> {
    return this.https.post(
      `${this.trainingApiUrl}/training/enquiry-form`,
      body
    );
  }

  get certificationCourses(): Observable<any> {
    return this.https.get(this.trainingApiUrl + '/certificate/course');
  }

  trendingCourse(page: number): Observable<any> {
    return this.https.get(
      `${this.trainingApiUrl}/certificate/course-card-trending?size=${page}`
    );
  }

  trendingCourseById(categoryId: number, page: number): Observable<any> {
    return this.https.get(
      `${this.trainingApiUrl}/certificate/course-card-category?categoryId=${categoryId}&size=${page}`
    );
  }

  certificationCoursesDetails(courseName: string): Observable<any> {
    return this.https.get(
      this.trainingApiUrl + '/certificate/course/' + courseName
    );
  }

  coursesDetails(title: string, type: string): Observable<any> {
    return this.https.get(`${this.trainingApiUrl}/${type}/course/${title}`);
  }

  fullstackCourseTree(id: number, type: string): Observable<any> {
    return this.https.get(`${this.trainingApiUrl}/${type}/fscourses/${id}`);
  }

  fullstackCourseAlumni(type: string): Observable<any> {
    return this.https.get(`${this.trainingApiUrl}/training/stories/${type}`);
  }

  get fullstackCourses(): Observable<any> {
    return this.https.get(this.trainingApiUrl + '/fullstack/course');
  }

  selfpacedCourses(page: number): Observable<any> {
    return this.https.get(
      this.trainingApiUrl + '/selfpaced/course?size=' + page
    );
  }
  primePackCourses(page: number): Observable<any> {
    return this.https.get(
      this.trainingApiUrl + '/selfpaced/prime-pack-cards?size=' + page
    );
  }

  curriculumTree(id: any): Observable<any> {
    return this.https.get(
      this.trainingApiUrl + `/certificate/curriculum/${id}`
    );
  }

  certificationCourseBatch(id: any): Observable<any> {
    return this.https.get(this.trainingApiUrl + `/certificate/batch/${id}`);
  }
  // certificationCourseBatchNew(id: any): Observable<any> {
  //   return this.https.get(this.trainingApiUrl + `/certificate/batch-new/${id}`);
  // }
  fullstackCourseBatch(id: any): Observable<any> {
    return this.https.get(this.trainingApiUrl + `/fullstack/batch/${id}`);
  }
  // For header menu
  trainingMenu(): Observable<any> {
    return this.https.get(this.trainingApiUrl + '/training/courses');
  }

  getConstTable(courseId: number, type: string): Observable<any> {
    return this.https.get(
      `${this.trainingApiUrl}/training/course-discount?courseId=${courseId}&courseType=${type}`
    );
  }
  aluminList(courseId: number, type: 'TXT' | 'VID'): Observable<any> {
    return this.https.get(
      `${this.trainingApiUrl}/training/stories?type=${type}&courseId=${courseId}`
    );
  }

  filterCertificationCourse(body: any): Observable<any> {
    return this.https.post(
      `${this.trainingApiUrl}/certificate/master-course-filter`,
      body
    );
  }
  filterFullStackCourse(body: any): Observable<any> {
    return this.https.post(
      `${this.trainingApiUrl}/certificate/fullstack-program-filter`,
      body
    );
  }

  /* ----------------------------    User Authentication  APIs  ---------------------------------*/

  signUp(body: ISignUp): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/signup`, body);
  }

  updateUserInfo(body: IUserInfo): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/add-user-info`, body);
  }
  resendOTP(value: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/auth/sendOTP`, {
      userId: value,
    });
  }

  verifyOTP(mobile: string, otp: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/varify-otp`, { mobile, otp });
  }

  requestForOTP(mobileEmail: string) {
    return this.https.post(`${this.apiUrl}/user/auth/request-for-otp`, {
      userName: mobileEmail,
    });
  }

  resendOTPLogin(value: string): Observable<any> {
    return this.https.get(`${this.apiUrl}/user/auth/resend-otp/${value}`);
  }
  loginByPassword(login: string, password: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/auth/login-by-password`, {
      login,
      password,
    });
  }
  loginByOTP(mobile: string, otp: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/auth/login-by-otp`, {
      mobile,
      otp,
    });
  }
  isUserExist(name: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/auth/validate-username`, {
      userName: name,
    });
    // return this.https.get(`${this.ApiUrl}/user/auth/validate-username?userName=${userName}`);
  }
  getUserForgotPassword(userId: string): Observable<any> {
    return this.https.post(`${this.apiUrl}/user/auth/forgot-password`, {
      userId,
    });
  }

  updatePassword(login: string, password: string) {
    return this.https.post(`${this.apiUrl}/user/auth/update-password`, {
      login,
      password,
    });
  }

  getProfileDetails() {
    const header: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', `${this.cookies.get('token')}`);
    return this.https.get(`${this.apiUrl}/panel/profile`, { headers: header });
  }

  updateUserInfo2(value: any, userId: any) {
    let obj = {
      userId: userId,
      qualification: value.Qualification,
      passout: value.Passout,
      designation: value.Designation,
      altMob: value.AltContact,
      // "experience": value,
      aadharNo: value.Aadhar,
      company: value.Company,
      dob: value.DOB,
      gender: value.Gender,
      linkedIn: value.LinkedIn,
    };
    return this.https.post(`${this.apiUrl}/user/add-user-info`, obj);
  }

  getTraningTypes() {
    const header: HttpHeaders = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', `${this.cookies.get('token')}`);
    return this.https.get(`${this.apiUrl}/panel/my-courses`, {
      headers: header,
    });
  }

  updateAddressInfo(value: any, userId: any) {
    let obj = {};
    return this.https.post(`${this.apiUrl}/user/add-user-info`, obj);
  }

  corpEnquirySave(obj: any) {
    return this.https.post(`${this.apiUrl}/training/corp-enquiry`, obj);
  }
  saveFSEnquiry(value: RequestBatch): Observable<any> {
    return this.https.post(
      `${this.apiUrl}/training/enquiry-course-form`,
      value
    );
  }

  // need to check url may be course-form
  addBatchRequest(value: RequestBatch): Observable<any> {
    return this.https.post(
      `${this.trainingApiUrl}/training/enquiry-course-form`,
      value
    );
  }

  saveContactDetails(obj: any) {
    return this.https.post(`${this.apiUrl}/common/contact-enquiry`, obj);
  }

  applyJob(obj: any) {
    return this.https.post(`${this.apiUrl}/common/appy-job`, obj);
  }

  getCourse(title: string) {
    return this.https.get(`${this.apiUrl}/selfpaced/course/${title}`);
  }

  getSPPrice(vidCourseId: any) {
    return this.https.get(
      `${this.apiUrl}/training/course-discount?courseId=` +
        vidCourseId +
        `&courseType=` +
        'SP'
    );
  }

  getCurriculum(vidCourseId: any) {
    return this.https.get(`${this.apiUrl}/selfpaced/curriculum/${vidCourseId}`);
  }

  getReviews(vidCourseId: any) {
    return this.https.get(`${this.apiUrl}/review/sp/course/${vidCourseId}`);
  }
}
