import { ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() list: any = [];
  @Input() width: number = 50;
  @Input() gap: number = 10;
  @Input() height: number = 0;
  @Input() dotsFlag: boolean = true;
  curIndex = 0;
  extraWidth = 0;
  @ViewChild('carousalList') carousalList!: ElementRef;
  @ContentChild('tmpl') tmplRef!: TemplateRef<any>;
  constructor(private changeDetect: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  next(): void {
    const divEl: HTMLDivElement = this.carousalList.nativeElement;
    if (this.curIndex >= this.hideDots(this.carousalList.nativeElement) - 1) {
      this.extraWidth = divEl.offsetWidth % this.width;
      this.curIndex += 1;
    } else {
      this.curIndex += 1;
    }
  }
  nextdot(index: number): void {
    this.curIndex = index;
  }
  hideDots(carousalList: any): number {
    if (carousalList && carousalList.offsetWidth) {
      const limit = Math.floor(carousalList.offsetWidth / this.width);
      return this.list.length !== 0 ? this.list.length - limit : 0;
    }
    return 0;
  }
  prev(): void {
    if (this.extraWidth !== 0) {
      this.extraWidth = 0;
      this.curIndex -= 1;
    } else {
      this.curIndex -= 1;
    }
  }

  trackByMethod(index:number, el:any): number {
    return index;
  }

}
