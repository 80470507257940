<div class="container">
  <div class="col-12 pl-0">
    <div class="row">
      <div class="col-3" style="height:448px;background-color: #ccc;padding-top: 25px;">
        <div class="position-relative py-5">
          <div class="ball bg-ch"></div>
          <div class="line bg-ch"></div>
          <span class="text-form">Technology</span>
        </div>
        <div class="position-relative py-5">
          <div class="ball bg-ch"></div>
          <div class="line bg-ch"></div>
          <span class="text-form">Keywords</span>
        </div>
        <div class="position-relative py-5">
          <div class="ball"></div>
          <div class="line"></div>
          <span class="text-form">Fill Details</span>
        </div>
        <div class="position-relative py-5">
          <div class="ball"></div>
          <span class="text-form">Completed</span>
        </div>
      </div>

      <div class="col-9 mt-2">
        <form [formGroup]="detailFormGroup" id="main-enquiry" (ngSubmit)="saveEnquiry(detailFormGroup.value)">
          <div class="form-section">
            <div class="container">
              <div class="row">
                <div class="col-6">
                  <div class="form-group mb-3" [ngClass]="{'input-error': !detailFormGroup?.controls?.mobile?.valid && submitted}">
                    <ngx-intl-tel-input class="form-control" [preferredCountries]="[
                      CountryISO.India,
                      CountryISO.UnitedStates,
                      CountryISO.UnitedKingdom
                    ]" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                  ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" formControlName="mobile" name="Mobile"></ngx-intl-tel-input>

                    <!-- <div *ngIf="submitted && detailFormGroup.controls.mobile.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.mobile.errors.required" class="error">
                    Required Field
                  </div>

                  <div *ngIf="detailFormGroup.controls['mobile'].hasError('maxlength')" class="error">
                    <span>Mobile Number must be 10 digit</span>
                  </div>


                </div> -->

                  </div>
                  <div class="form-group mb-3">
                    <input type="text" formControlName="name" [ngClass]="{'input-error': !detailFormGroup?.controls?.name?.valid && submitted}" class="form-control" placeholder="Name" />
                    <!-- <div *ngIf="submitted && detailFormGroup.controls.name.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.name.errors.required" class="error">
                    Required Field
                  </div>
                </div> -->
                  </div>
                  <div class="form-group mb-3">
                    <input type="email" formControlName="email" [ngClass]="{'input-error': !detailFormGroup?.controls?.email?.valid && submitted}" class="form-control" placeholder="Email" />

                    <!-- <div *ngIf="submitted && detailFormGroup.controls.email.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.email.errors.required" class="error">
                    Required Field
                  </div>
                  <div *ngIf="detailFormGroup.controls.email.errors.email" class="error">Email must be a valid email address</div>
                </div> -->

                  </div>
                  <div class="form-group mb-3">
                    <select class="form-select" formControlName="profession" [ngClass]="{'input-error': !detailFormGroup?.controls?.profession?.valid && submitted}">
                      <option value="">Select</option>
                      <option value="Student">Student</option>
                      <option value="Working">Working</option>
                    </select>

                    <!-- <div *ngIf="submitted && detailFormGroup.controls.profession.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.profession.errors.required" class="error">
                    Required Field
                  </div>
                </div> -->


                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group mb-3">
                    <input type="text" [ngClass]="{'input-error': !detailFormGroup?.controls?.qualification?.valid && submitted}" formControlName="qualification" placeholder="Qualification" class="form-control" />

                    <!-- <div *ngIf="submitted && detailFormGroup.controls.qualification.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.qualification.errors.required" class="error">
                    Required Field
                  </div>
                </div> -->

                  </div>
                  <div class="form-group mb-3">
                    <input type="number" formControlName="passout" [ngClass]="{'input-error': !detailFormGroup?.controls?.passout?.valid && submitted}" placeholder="Year of Passing" class="form-control" (keydown)="onKeyDown($event)" />
                    <!-- <div *ngIf="submitted && detailFormGroup.controls.passout.errors" class="required_fld_alert">
                  <div *ngIf="!!detailFormGroup.controls.passout.errors.required" class="error">
                    Required Field
                  </div>

                  <div *ngIf="detailFormGroup.controls.passout.errors.minLength" class="alert alert-danger">
                    <span>passout at least 2 digit and max 4 digit</span>
                  </div>

                </div> -->
                  </div>
                  <div class="form-check mb-3">
                    <input type="checkbox" (change)="onChangeDemo($event)" class="form-check-input free-demo-chk" />
                    <label class="form-check-label free-demo">Free Demo ?</label>
                  </div>
                  <div class="row">
                    <div class="form-group mb-3">
                      <input type="datetime-local" [ngClass]="{'d-none':detailFormGroup.controls.demoDate.disabled}" formControlName="demoDate" placeholder="Demo Date" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <textarea class="form-control" formControlName="comment" [ngClass]="{'input-error': !detailFormGroup?.controls?.comment?.valid && submitted}" placeholder="Requirments" rows="2"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex" style="margin-top: 40px;" [ngClass]="showError? 'justify-content-between' : 'justify-content-end'">

            <div style="padding-top: 8px;padding-left: 22px;">
              <span class="text-danger" *ngIf="showError">Check Fields</span>
            </div>
            <div>
              <button class="mr-2" (click)="back()">Back</button>
              <!-- <button (click)="next()">Sumbit</button> -->
              <button type="submit">Submit</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>