<ng-container *ngIf="!bottom">
    <div class="d-flex align-items-center">
        <div class="btn-group" *ngIf="pagination && pagination.totalPages">
            <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                {{pagination.number + 1}}
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <div class="d-flex flex-row flex-wrap pagination-box">
                    <div class="page-count c-pointer" (click)="triggerChangeIndex(i)" *ngFor='let in of counter(pagination.totalPages);let i = index'>
                        {{i+1}}
                    </div>
                </div>
            </ul>
        </div>
        <div style="white-space: nowrap;">
            <button type="button" class="btn next-prev-btn mx-2" [disabled]="pagination?.first" (click)="triggerPrev()">
                <fa-icon [icon]="leftArrow"></fa-icon> Prev
            </button>
            <button type="button" [disabled]="pagination?.last" class=" btn next-prev-btn" (click)="triggerNext()">Next
                <fa-icon [icon]="rightArrow"></fa-icon>
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="bottom">
    <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center">
            <select class="form-select select-items" [(ngModel)]="size" (change)="triggerChangeSize(size)">
                <option value="10">10</option>
                <option value="20">20</option>
            </select>
            <span class="d-none d-md-flex d-lg-flex ml-2"> {{sizeText}}</span>
        </div>
        <div class="d-flex">
            <div class="btn-group dropup" *ngIf="pagination && pagination.totalPages">
                <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {{pagination.number + 1}}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <!-- For 10 pages in a row = width: 426px; -->
                    <div class="d-flex flex-row flex-wrap pagination-box">
                        <div class="page-count c-pointer" (click)="triggerChangeIndex(i)" *ngFor='let in of counter(pagination.totalPages);let i = index'>
                            {{i+1}}
                        </div>
                    </div>
                </ul>
            </div>
            <div style="white-space: nowrap;">
                <button type="button" class="btn next-prev-btn mx-2" [disabled]="pagination?.first" (click)="triggerPrev()">
                    <fa-icon [icon]="leftArrow"></fa-icon> Prev
                </button>
                <button type="button" [disabled]="pagination?.last" class=" btn next-prev-btn" (click)="triggerNext()">Next
                    <fa-icon [icon]="rightArrow"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</ng-container>

