import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApiService } from './Api/api.service';
import { CommonService } from './Api/common.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeService } from './home/services/home.service';
import { TutorialService } from './language/services/tutorial.service';
import { TrainingService } from './training/services/training.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SeoService } from './home/services/seo.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeShareModule } from './home/components/home-share-module';
import { AuthService } from './user/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { MainService } from './Api/mainService';
import { UtilityService } from './Api/utility.service';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { NgChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  HighlightModule,
  HIGHLIGHT_OPTIONS,
  HighlightOptions,
} from 'ngx-highlightjs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ShortNumberPipe } from './shortnumber.pipe';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import {
  APP_BASE_HREF,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { ApiInterceptor } from './Api/api.interceptor';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import * as $ from 'jquery';
import { AdsenseModule } from 'ng2-adsense';
import {
  SocialLoginModule,
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';

// import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    // ShortNumberPipe,

    // PbiShimmerCircleComponent,PbiShimmerComponent,PbiShimmerLineComponent, PbiShimmerGapComponent, PbiShimmerRepeatDirective
    // HeaderComponent,
    // FooterComponent,
  ],
  // exports:[
  //   HeaderComponent,
  //   FooterComponent,
  // ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    // NgxShimmeringLoaderModule,
    // CodeEditorModule.forRoot(),
    ReactiveFormsModule,
    ClipboardModule,
    HomeShareModule,
    MonacoEditorModule.forRoot(),
    NgxStarRatingModule,
    NgChartsModule,
    NgxPaginationModule,
    HighlightModule,
    NgxIntlTelInputModule,
    ShareButtonsPopupModule,
    ReactiveFormsModule,
    SocialLoginModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-8535817066678874',
      adSlot: 8944439968,
    }),
  ],
  exports: [ShareButtonsPopupModule],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '358816840818-09g7pgbm8vn4rif3sod63s9204m6il68.apps.googleusercontent.com'
            ),
            // },
            // {
            //   id: FacebookLoginProvider.PROVIDER_ID,
            //   provider: new FacebookLoginProvider(
            //     '550227808003-lce74crqsje5la65ms6p3ej11m61eblh.apps.googleusercontent.com'
            //   ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    // [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
    CookieService,
    ApiService,
    MainService,
    HomeService,
    TrainingService,
    CommonService,
    TutorialService,
    MatExpansionModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    SeoService,
    UtilityService,
    AuthService,
    {
      provide: MAT_TABS_CONFIG,
      useValue: { animationDuration: '0ms' },
    },
    AppRoutingModule,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
        //themePath: 'node_modules/highlight.js/styles/default.css',
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
          java: () => import('highlight.js/lib/languages/java'),
          javascript: () => import('highlight.js/lib/languages/javascript'),
          python: () => import('highlight.js/lib/languages/python'),
          scss: () => import('highlight.js/lib/languages/scss'),
          php: () => import('highlight.js/lib/languages/php'),
          sql: () => import('highlight.js/lib/languages/sql'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
