<div class="px-4 py-2 w-80 login" style="background-color: #ffffff; border-radius: 4px;">
  <div class="d-flex align-items-start justify-content-between pb-2">
    <div class="d-flex align-items-center">
      <fa-icon class="fs-3 me-3" [icon]="userIcon"></fa-icon>
      <div class="heading_section">
        <!-- *ngIf="!otpFlag && !passwordFlag && !forgetPasswordFlag" -->
        <!-- Live -->
        <h5 class="m-0">Sign In</h5>
        <!-- <small *ngIf="!otpFlag && !passwordFlag && !forgetPasswordFlag">Enter Mobile or Email</small> -->
        <!-- <div class="m-0 fw-normal" *ngIf="otpFlag || passwordFlag">{{emailORmobile}}
          <small (click)="back()" *ngIf="otpFlag || passwordFlag" style="color: #0c0c0c; cursor: pointer;font-size:12px;"> (change)</small>
        </div> -->
        <!-- <small *ngIf="passwordFlag">Enter password</small> -->
        <small *ngIf="otpFlag">Enter OTP</small>
        <h5 class="m-0 fw-normal" *ngIf="!otpFlag && !passwordFlag && forgetPasswordFlag && !isPasswordUpdated">Forgot Password</h5>
        <small *ngIf="!otpFlag && !passwordFlag && forgetPasswordFlag && !isPasswordUpdated">Please enter OTP.</small>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <!-- *ngIf="!otpFlag && !passwordFlag" -->
      <button type="button" class="btn-close c-pointer" style="margin-right: -15px;" data-bs-dismiss="modal" aria-label="Close" *ngIf="!(otpFlag || passwordFlag || forgetPasswordFlag)" (click)="closePopup()"></button>
      <!-- <fa-icon [icon]="closeIcon" class="c-pointer" *ngIf="!(otpFlag || passwordFlag || forgetPasswordFlag)" (click)="closePopup()"></fa-icon> -->
      <!-- <fa-icon [icon]="arrowLeft" class="c-pointer" *ngIf="otpFlag || passwordFlag || forgetPasswordFlag" (click)="back()"></fa-icon> -->
    </div>
  </div>
  <div>
    <ng-container *ngIf="!otpFlag && !passwordFlag && !forgetPasswordFlag">
      <div class="input-group mt-3">
        <div class="d-block">
          <label class="pointer" for="emailORmobile" (click)="mobileNum()" [ngClass]="{  'selected' : mobileEmail, 'unselected': !mobileEmail}">Mobile</label>
          <label class="pointer ml-2" for="emailORmobile" (click)="mobileNum()" [ngClass]="{  'selected' : !mobileEmail, 'unselected': mobileEmail}">Email</label>
        </div>
      </div>
      <div>
        <ngx-intl-tel-input *ngIf="mobileEmail" style="max-width:26rem;" [cssClass]="'custom'" [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="12" [separateDialCode]="true" [(ngModel)]="numberObj" name="emailORmobile" (ngModelChange)="onNumberChange($event)"></ngx-intl-tel-input>
        <input type="text" class="form-control input-email" *ngIf="!mobileEmail" (input)="check($event)" [(ngModel)]="emailORmobile" name="emailORmobile" id="emailORmobile" [attr.placeholder]="mobileEmail ? 'Enter Mobile' : 'Enter Email'">
      </div>
    </ng-container>
    <div *ngIf="forgetPasswordFlag && isPasswordUpdated" class="py-2">
      <span class="text-success">Passowrd Updated Successfully</span>
    </div>
    <div *ngIf="otpFlag || (forgetPasswordFlag && !isPasswordUpdated && !otpERRMessage)" class="py-2">
      <span class="text-success">{{otpMessage}}</span>
    </div>
    <div *ngIf="( forgetPasswordFlag && !isPasswordUpdated && otpERRMessage)">
      <span class="text-danger">{{otpERRMessage}}</span>
    </div>
    <!-- otpVerified -->
    <div class="input-group mb-2" *ngIf="otpFlag">
      <input type="text" class="form-control" (input)="check($event)" [(ngModel)]="otp" maxlength="4" max="4" placeholder="Enter OTP">
    </div>
    <ng-container *ngIf="forgetPasswordFlag && !isPasswordUpdated">
      <div class="input-group mb-2">
        <input type="text" class="form-control" [disabled]="otpVerified" (input)="check($event)" [(ngModel)]="otp" maxlength="4" max="4" placeholder="Enter OTP" (input)="onPress($event)">
      </div>
      <div class="input-group mb-2 mt-5">
        <input type="password" class="form-control" (input)="check($event)" [disabled]="!otpVerified" [(ngModel)]="rePassword" placeholder="Enter new password">
      </div>
      <div class="input-group mb-2 mt-5">

        <input type="password" class="form-control" (input)="check($event)" [disabled]="!otpVerified" [(ngModel)]="confirmPassword" placeholder="Confirm password">
      </div>
      <span class="text-danger" *ngIf="rePassword && confirmPassword && rePassword != confirmPassword">
        Password not matched !
      </span>
    </ng-container>

    <div class="input-group mb-2 mt-2" *ngIf="passwordFlag">
      <div class="d-block" style="display: block;width:100%">
        <div class="m-0" *ngIf="otpFlag || passwordFlag">UserID: {{emailORmobile}}
          <small (click)="back()" *ngIf="otpFlag || passwordFlag" style="color: #0c0c0c; cursor: pointer;font-size:12px;">Change</small>
        </div>
        <!-- <label class="pointer" style="padding: 0;margin-bottom: 3px;display: block;width:100%">Enter Password</label> -->
        <!-- <label class="pointer ml-2" for="emailORmobile" (click)="mobileNum()" [ngClass]="{  'selected' : !mobileEmail, 'unselected': mobileEmail}">Email</label> -->
      </div>
      <input type="password" class="form-control mt-4" (input)="check($event)" [(ngModel)]="password" placeholder="Enter password">
    </div>
    <p style="color: red;" class="font-size-12 my-0 mx-2">{{error}}</p>
    <div class="d-flex align-items-center justify-content-between" style="font-size: 12px;">
      <div *ngIf="passwordFlag">
        <a href="javascript:void(0)" class="c-pointer text-primary paragraph" (click)="requestForOTP();selectForgotPassword();">Forgot Password</a>
      </div>

      <div *ngIf="otpFlag && !passwordFlag">
        <a href="javascript:void(0)" class="c-pointer text-primary paragraph" (click)="selectPasswordOption(); ">Login by Password </a>
      </div>
    </div>
    <div class="py-2" *ngIf="otpFlag || (forgetPasswordFlag && !otpVerified)">
      <div class="d-flex justify-content-between" *ngIf="this.mobileNo">
        <a href="javascript:void(0)" class="c-pointer" (click)="resendOTP();">Resend OTP </a>
        <!-- <p class="m-0">02:59:09</p> -->
      </div>
    </div>
    <!-- <div class="form-check pb-2" *ngIf="!otpFlag && !passwordFlag">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Keep me signed in
      </label>
    </div> -->
    <button type="submit" class="btn-continue my-4" *ngIf="!otpFlag && !passwordFlag && !forgetPasswordFlag" (click)="continue()">
      <div class="d-flex align-items-center justify-content-center">Continue
        <div class="spinner-icon ms-2" *ngIf="continueFlag">
          <fa-icon [icon]="spinner"></fa-icon>
        </div>
      </div>
    </button>
    <button type="submit" class="submit-btn sign-btn my-2" *ngIf="passwordFlag" (click)="login()">
      <div class="d-flex align-items-center justify-content-center">Login
        <div class="spinner-icon ms-2" *ngIf="loginSpinnerFlag">
          <fa-icon [icon]="spinner"></fa-icon>
        </div>
      </div>
    </button>
    <div *ngIf="!otpFlag && passwordFlag">
      <a href="javascript:void(0)" class="c-pointer text-primary paragraph" (click)="requestForOTP();selectOTPOption(); ">Login by OTP </a>
    </div>
    <button type="button" class="light-btn sign-btn my-2" *ngIf="otpFlag" (click)="login()">
      <div class="d-flex align-items-center justify-content-center">
        Login by OTP
        <!-- <div class="spinner-icon ms-2" *ngIf="getOTPSpinnerFlag">
          <fa-icon [icon]="spinner"></fa-icon>
        </div> -->
      </div>
    </button>
    <span class="text-danger" *ngIf="updatePassowrdError && forgetPasswordFlag && !isPasswordUpdated">
      {{updatePassowrdError}}
    </span>
    <span class="text-success" *ngIf="updatePassowrdSuccess && forgetPasswordFlag && !isPasswordUpdated">
      {{updatePassowrdSuccess}}
    </span>
    <button type="button" class="light-btn sign-btn my-2" *ngIf="forgetPasswordFlag && !isPasswordUpdated" (click)="updatePassword()" [disabled]="!(otpVerified && rePassword && confirmPassword && rePassword == confirmPassword)" [ngClass]="{'light-btn':!(otpVerified && rePassword && confirmPassword && rePassword == confirmPassword), 'light-btn-s':otpVerified && rePassword && confirmPassword && rePassword == confirmPassword}">
      <div class="d-flex align-items-center justify-content-center">
        Update Password
        <!-- <div class="spinner-icon ms-2" *ngIf="getOTPSpinnerFlag">
          <fa-icon [icon]="spinner"></fa-icon>
        </div> -->
      </div>
    </button>
    <!-- <button type="button" class="light-btn sign-btn my-2" *ngIf="otpFlag" (click)="continue()">
      <div class="d-flex align-items-center justify-content-center">
        Login With password
        <div class="spinner-icon ms-2" *ngIf="continueFlag">
          <fa-icon [icon]="spinner"></fa-icon>
        </div>
      </div>
    </button> -->
    <div class="d-flex d-block align-items-center justify-content-center my-2">
      <!-- <asl-google-signin-button></asl-google-signin-button> -->

      <!-- <button class="btn btn-default gfg-sign p-2 mr-3" id="buttonDiv" (click)="loginWithGoogle()">
        <img style="width: 25px;" src="assets/icons/google-icon.png" alt="google">
      </button> -->
      <!-- id="g_id_onload" -->
      <div id="buttonDiv"></div>

      <!-- <span class="gfg-sign p-2 mr-3"  (click)="loginWithGoogle()">
        <img style="width: 25px;" src="assets/icons/google-icon.png" alt="google">
      </span> -->
      <span class="gfg-sign p-2 mr-3">
        <img style="width: 25px;" src="assets/icons/facebook-icon.png" alt="facebook">
      </span>
      <span class="gfg-sign p-2">
        <img style="width: 25px;" src="assets/icons/github.png" alt="github">
      </span>
    </div>
    <p class="paragraph text-center my-4">Don’t have an account?&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" class="c-pointer" style="font-weight:500;" (click)="signUpPopUp();closePopup()">SignUp</a></p>
    <p class="text-center font-size-11">Note: By continuing and signing in, you agree to technical99's <a href="javascript:void(0)" class="txt_warning">Term & Conditions</a> and <a href="javascript:void(0)" class="txt_warning">Privacy Policy</a></p>
  </div>
</div>

<div class="toast-container position-absolute p-3 top-0 end-0" id="toastPlacement">
  <div class="toast" [ngClass]="signinErrorFlag ? signinErrorMsg ? 'bg-danger show' : 'bg-primary show': ''">
    <div class="d-flex">
      <div class="toast-body text-white">
        {{signinErrorMsg || signinSuccessMsg}}
      </div>
      <button type="button" class="btn-close me-2 m-auto text-white" (click)="hideSignupMessage()" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  </div>
</div>