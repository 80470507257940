import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowAltCircleLeft,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faCamera, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/Api/common.service';
import { AuthService } from '../auth.service';
import { AgeValidator } from '../sign-up/validators';

declare var $: any;

@Component({
  selector: 'app-profile-education-form',
  templateUrl: './profile-education-form.component.html',
  styleUrls: ['./profile-education-form.component.scss'],
})
export class ProfileEducationFormComponent implements OnInit {
  @Input() name = '';
  @Input() userId!: number;
  @Output() openLoginPopup = new EventEmitter();
  userIcon = faUser;
  cameraIcon = faCamera;
  otpFlag: boolean = false;
  passwordFlag: boolean = false;
  educationFormGroup: FormGroup = this.fb.group({});
  spinner = faSpinner;
  spinnerFlag = false;
  arrowLeft = faArrowAltCircleLeft;
  successMessage: string = '';
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.commonService.setCategoryDropdown(true);
    let eighteenYears = new Date();
    eighteenYears.setTime(
      eighteenYears.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000
    );
    this.educationFormGroup = this.fb.group(
      {
        qualification: ['', Validators.required],
        passout: ['', Validators.required],
        designation: ['', Validators.required],
        experience: ['Experience'],
        dob: [
          formatDate(eighteenYears, 'yyyy-MM-dd', 'en'),
          Validators.required,
        ],
        linkedIn: [''],
        gender: ['Male', Validators.required],
      },
      {
        validators: AgeValidator,
      }
    );

    this.educationFormGroup?.controls?.experience.valueChanges.subscribe(
      (value: any) => {
        if (value == 'Fresher') {
          this.educationFormGroup?.get('designation')?.disable();
          this.educationFormGroup?.controls?.designation.removeValidators([
            Validators.required,
          ]);
          this.educationFormGroup.patchValue({ designation: '' });
        } else {
          this.educationFormGroup?.controls?.designation.setValidators([
            Validators.required,
          ]);
          this.educationFormGroup?.get('designation')?.enable();
        }
      }
    );
  }

  ngOnInit(): void {}

  signIn() {
    this.openLoginPopup.emit();
  }

  async onSubmitEducation() {
    if (!this.userId) return;
    this.spinnerFlag = true;
    this.educationFormGroup.value.userId = this.userId;
    this.educationFormGroup.value.passout = Number(
      this.educationFormGroup.value.passout
    );
    try {
      let res: any = await this.authService.updateUserInfo(
        this.educationFormGroup.value
      );
      if (res && res.status == 200) {
        this.authService.signupSuccessMsg = 'Account Created Successfully';
        this.successMessage = res.message;
        this.authService.signupErrorFlag = false;
        this.authService.signupErrorMsg = '';
        // $('#toastPlacement').modal('show');
        this.spinnerFlag = false;
        // setTimeout(() => {
        //   this.router.navigate(['/login']);
        // }, 1000);
      } else {
        this.spinnerFlag = false;
        this.authService.signupErrorMsg = 'Invalid Input';
        this.authService.signupSuccessMsg = '';
        this.authService.signupErrorFlag = true;
      }
    } catch (error: any) {
      this.spinnerFlag = false;
      this.authService.signupErrorMsg = 'Something went wrong';
      this.authService.signupSuccessMsg = '';
      this.authService.signupErrorFlag = true;
    }
  }

  get signupErrorFlag(): boolean {
    return this.authService.signupErrorFlag;
  }
  get signupErrorMsg(): string {
    return this.authService.signupErrorMsg;
  }
  get signupSuccessMsg(): string {
    return this.authService.signupSuccessMsg;
  }

  hideSignupMessage(): void {
    this.authService.changeSingupFlag();
  }
}
