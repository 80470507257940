export interface ITopic {
    topicId: number;
    techId: number;
    topicName: string;
    subTopic: string;
    isOpen: boolean;
    get subTopicArr(): any[];
}
export class Topic {
    topicId: number;
    techId: number;
    topicName: string;
    subTopic: string;
    isOpen: boolean = false;

    constructor({ techId, topicId, topicName, subTopic }: ITopic) {
        this.techId = techId;
        this.topicId = topicId;
        this.topicName = topicName;
        this.subTopic = subTopic;
        this.isOpen = false;
    }

    get subTopicArr(): any[] {
        if (!this.subTopic) return [];
        try {
          return JSON.parse(this.subTopic);
        } catch(e) {
          return [];
        }
    }
}
export interface ITechnology {
    techId: number;
    techName: string;
    topics: ITopic[];
    isOpen: boolean;
    isExpend: number;
    isFSEnabled: number;
}
export class Technology {
    techId: number;
    techName: string;
    topics: ITopic[];
    isOpen: boolean = false;
    isExpend: number;
    isFSEnabled: number;
    constructor({ techId, techName, topics, isExpend, isFSEnabled }: ITechnology) {
        this.techId = techId;
        this.techName = techName;
        this.topics = topics.map((t: ITopic) => new Topic(t))
        this.isOpen = true;
        this.isExpend = isExpend;
        this.isFSEnabled = isFSEnabled;
    }
}
export interface ICurriculum {
    courseId: number;
    isExpand: number;
    technologies: ITechnology[];
}
export class Curriculum {
    courseId: number;
    isExpand: number;
    technologies: ITechnology[];
    constructor({ courseId, isExpand, technologies }: ICurriculum) {
        this.courseId = courseId;
        this.isExpand = isExpand;
        this.technologies = technologies.map((t: ITechnology) => new Technology(t));
    }
}

export interface IDetails {
    courseName: string;
    statement: string;
    aboutCourse: string;
    duration: number;
    rating: number;
    ratingCount: number;
    learners: number;
    features: string;
    project: string;
    faq: string;
    metadata: string;
    icon: string;
    get aboutCourseArr(): any[];
    get featuresArr(): any[];
    get projectArr(): any[];
    get metadataArr(): any;
    get faqArr(): any[];
}

export class Details implements IDetails {
    courseName: string;
    statement: string;
    aboutCourse: string;
    duration: number;
    rating: number;
    ratingCount: number;
    learners: number;
    features: string;
    project: string;
    faq: string;
    metadata: string;
    icon: string;

    constructor(props: IDetails) {
        this.courseName = props.courseName;
        this.statement = props.statement;
        this.aboutCourse = props.aboutCourse;
        this.duration = props.duration;
        this.rating = props.rating;
        this.ratingCount = props.ratingCount;
        this.learners = props.learners;
        this.features = props.features;
        this.project = props.project;
        this.faq = props.faq;
        this.metadata = props.metadata;
        this.icon = props.icon;
    }
    get aboutCourseArr(): any[] {
      if (!this.aboutCourse) return [];
      return JSON.parse(this.aboutCourse);
  }

  get featuresArr(): any[] {
      if (!this.features) return [];
      return JSON.parse(this.features);
  }

  get projectArr(): any[] {
      if (!this.project) return [];
      return JSON.parse(this.project);
  }

  get faqArr(): any[] {
      if (!this.faq) return [];
      return JSON.parse(this.faq);
  }

  get metadataArr(): any {
      if (!this.metadata) return null;
      return JSON.parse(this.metadata);
  }
}
export interface ICRCourse {
    courseId: number;
    categoryId: number;
    title: string;
    icon: string;
    get route(): string;
}
export class CRCourse implements ICRCourse {
    courseId: number;
    categoryId: number;
    title: string;
    icon: string;

    constructor(props: ICRCourse) {
        this.categoryId = props.categoryId;
        this.courseId = props.courseId;
        this.title = props.title;
        this.icon = props.icon;
    }
    get route(): string {
      if (!this.title) return "";
      return this.title.replace(/-/g, " ");
    }
}
export interface IFSCourse {
    programId: number;
    categoryId: number;
    courseName: string;
    noc: number;
    duration: number;
    learners: number;
    icon: string;
    title: string;
}
export class FSCourse implements IFSCourse {
    programId: number;
    categoryId: number;
    courseName: string;
    noc: number;
    duration: number;
    learners: number;
    icon: string;
    title: string;

    constructor(props: IFSCourse) {
        this.programId = props.programId;
        this.categoryId = props.categoryId;
        this.courseName = props.courseName;
        this.noc = props.noc;
        this.duration = props.duration;
        this.learners = props.learners;
        this.icon = props.icon;
        this.title = props.title;
    }
}
export interface ISPCourse {
    courseId: number;
    categoryId: number;
    courseName: string;
    duration: number;
    videoCount: number;
    icon: string;
    image: string;
    title: string;
}
export class SPCourse implements ISPCourse {
    courseId: number;
    categoryId: number;
    courseName: string;
    duration: number;
    videoCount: number;
    icon: string;
    image: string;
    title: string;
    constructor(props: ISPCourse) {
        this.courseId = props.courseId;
        this.categoryId = props.categoryId;
        this.courseName = props.courseName
        this.duration = props.duration;
        this.icon = props.icon;
        this.image = props.image;
        this.videoCount = props.videoCount;
        this.title = props.title;
    }
}
export interface ICourseMenu {
    categoryId: number;
    name: string;
    crcourse: ICRCourse[];
    fscourse: IFSCourse[];
    spcourse: ISPCourse[];
}
export class CourseMenu implements ICourseMenu {
    categoryId: number;
    name: string;
    crcourse: ICRCourse[];
    fscourse: IFSCourse[];
    spcourse: ISPCourse[];

    constructor(props: ICourseMenu) {
        this.categoryId = props.categoryId;
        this.name = props.name;
        this.crcourse = props.crcourse.map((cr: ICRCourse) => new CRCourse(cr)) || [];
        this.fscourse = props.fscourse.map((cr: IFSCourse) => new FSCourse(cr)) || [];
        this.spcourse = props.spcourse.map((cr: ISPCourse) => new SPCourse(cr)) || [];
    }

}

export interface ICourseCostTable {
  coursePrice: number;
  payable: number;
  discount: number;
  endDate: string;
  offerName: string;
  discountType: string;
  creditPoints: number;
}
export class CourseCostTable implements ICourseCostTable{
  coursePrice: number;
  payable: number;
  discount: number;
  endDate: string;
  offerName: string;
  discountType: string;
  creditPoints: number;
  constructor(props: ICourseCostTable) {
    this.coursePrice = props.coursePrice;
    this.payable = props.payable;
    this.discount = props.discount;
    this.endDate = props.endDate;
    this.offerName = props.offerName;
    this.discountType = props.discountType;
    this.creditPoints = props.coursePrice;
  }
  get cutOff(): number {
    return (this.payable * this.discount)/ 100;
  }
}
